import { AccountBalances, SymbolsCurrency } from '@/presentation/protocols'

export const accountBalancesFrom = (
  symbolsCapitals: SymbolsCurrency,
  symbolsProfit: SymbolsCurrency
) =>
  Object.keys(symbolsCapitals).reduce(
    (balances, symbol) => ({
      capital: (balances?.capital ?? 0) + symbolsCapitals[symbol],
      profitP: (balances?.profitP ?? 0) + symbolsProfit[symbol],
      profitPP: ((balances?.profitP ?? 0) / (balances?.capital ?? 0)) * 100
    }),
    {} as AccountBalances
  )
