import { OrderSide, OrderType } from 'binance-api-node'
import * as React from 'react'
import { createContext, useContext, useState } from 'react'

import { error, log } from '@/presentation/helpers'
import { OrdersState } from '@/presentation/protocols'
import { ReactComponentProps } from '@/presentation/types'
import { requestNewOrder } from '@/presentation/useCases/newOrder'
import { requestAllNewOrder } from '@/presentation/useCases/requestAllNewOrder'

import { useAccountInfoContext } from './AccountInfo'
import { useOrdersContext } from './Orders'

export type OrderContextType = {
  orders: OrdersState
  selectedMode: string
  selectedQuantity: number
  setOrders: React.Dispatch<React.SetStateAction<OrdersState>>
  setSelectedMode: React.Dispatch<React.SetStateAction<string>>
  setSelectedQuantity: React.Dispatch<React.SetStateAction<number>>
  buy: (symbol: string | undefined) => void
  sell: (symbol: string | undefined) => void
  buyAll: () => void
  sellAll: () => void
}

const defaultValues = {
  orders: {} as OrdersState,
  selectedMode: ``,
  selectedQuantity: 0,
  setOrders: () => undefined,
  setSelectedMode: () => undefined,
  setSelectedQuantity: () => undefined,
  buy: () => undefined,
  sell: () => undefined,
  buyAll: () => undefined,
  sellAll: () => undefined
}

export const OrderContext = createContext<OrderContextType>(defaultValues)

export const OrderProvider = ({ children }: ReactComponentProps) => {
  const [selectedMode, setSelectedMode] = useState<string>(
    defaultValues.selectedMode
  )
  const [selectedQuantity, setSelectedQuantity] = useState<number>(
    defaultValues.selectedQuantity
  )
  const [orders, setOrders] = useState({} as OrdersState)
  const { symbolsCapitals } = useOrdersContext()
  const { exchangeInfo } = useAccountInfoContext()

  const buy = async (symbol: string | undefined) => {
    if (symbol) {
      requestNewOrder({
        symbol,
        side: `BUY` as OrderSide,
        type: `MARKET` as OrderType.MARKET,
        quoteOrderQty: orders[symbol].quoteOrderQty.toString()
      })
        .then(log)
        .catch(error)
    }
  }

  const sell = (symbol: string | undefined) => {
    if (symbol) {
      requestNewOrder({
        symbol,
        side: `SELL` as OrderSide,
        type: `MARKET` as OrderType.MARKET,
        quoteOrderQty: orders[symbol].quoteOrderQty.toString()
      })
        .then(log)
        .catch(error)
    }
  }

  const buyAll = () => {
    requestAllNewOrder(`BUY` as OrderSide, orders)
      .then(orders => log(`requestAllNewOrderTest`, orders))
      .catch(err => log(`error: `, err))
  }

  const sellAll = () => {
    requestAllNewOrder(`SELL` as OrderSide, orders).then(orders =>
      log(`requestAllNewOrderTest`, orders)
    )
  }

  return (
    <OrderContext.Provider
      value={{
        orders,
        selectedMode,
        selectedQuantity,
        setOrders,
        setSelectedMode,
        setSelectedQuantity,
        buy,
        sell,
        buyAll,
        sellAll
      }}
    >
      {children}
    </OrderContext.Provider>
  )
}

export const useOrderContext = () => useContext(OrderContext)
