import Image from 'next/image'
import { useRouter } from 'next/router'

import Header from '@/presentation/components/structure/Header'
import { ThemeProvider } from '@/presentation/contexts'

import { LayoutProps } from './protocols'

export const AuthLayout: React.FC<LayoutProps> = ({
  children
}: LayoutProps) => {
  const router = useRouter()

  return (
    <ThemeProvider>
      <Header />
      <div className="flex h-screen justify-center">
        <div className="flex flex-col items-center justify-center max-lg:w-full w-1/2 px-8">
          <div className="mx-auto w-full max-w-md border border-3xl border-neutral-800 rounded-3xl p-10 shadow-lg">
            <button
              className="flex w-full justify-left cursor-pointer"
              onClick={() => router.push('/')}
            >
              <Image src="/logo-brand.svg" alt="Logo" height={24} width={120} />
            </button>
            <div className="mt-8">
              <div>
                <div>{children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  )
}
