import { createContext, useCallback, useContext, useState } from 'react'

import { ReactComponentProps } from '@/presentation/types'

import { NumberFormatter, Privacy } from '../helpers'

export type PrivateContextType = {
  isPrivate: boolean
  handle: (value: number | string | null, numeric?: boolean) => string
  togglePrivate: () => void
}

export const PrivateContext = createContext<PrivateContextType>(
  {} as PrivateContextType
)

export const PrivateProvider = ({ children }: ReactComponentProps) => {
  const [isPrivate, setIsPrivate] = useState(false)

  const togglePrivate = useCallback(() => setIsPrivate(v => !v), [])

  const handle = useCallback(
    (value: number | string | null, numeric?: boolean) => {
      let valueF = String(value)

      if (typeof value === 'number') {
        valueF = NumberFormatter.toPrice(value)

        if (numeric) {
          valueF = value.toString()
        }
      }

      if (value === null) return '-'

      return Privacy.handle(valueF, isPrivate)
    },
    [isPrivate]
  )

  return (
    <PrivateContext.Provider
      value={{
        handle,
        isPrivate,
        togglePrivate
      }}
    >
      {children}
    </PrivateContext.Provider>
  )
}

export const usePrivateContext = () => useContext(PrivateContext)
