import { FiboPriceLevels } from './fibonacci'

export type PivotPriceLevels = {
  PP: number
  R1: number
  S1: number
  R2: number
  S2: number
  R3: number
  S3: number
}

export class PivotPoints {
  static getPivotPoints = (
    fiboPriceLevels: FiboPriceLevels,
    lastPrice: number
  ): PivotPriceLevels => {
    const H = fiboPriceLevels.fibo_1000
    const L = fiboPriceLevels.fibo_0000
    const C = lastPrice ?? 0

    const PP = (H + L + C) / 3
    const R3 = PP + (H - L) * 1.0
    const R2 = PP + (H - L) * 0.618
    const R1 = PP + (H - L) * 0.382
    const S1 = PP - (H - L) * 0.382
    const S2 = PP - (H - L) * 0.618
    const S3 = PP - (H - L) * 1.0

    return { PP, R1, S1, R2, S2, R3, S3 }
  }
}
