import { AxiosResponse } from 'axios'
import {
  createContext,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'

import { AuthPayload, CheckAuthResponse } from '@/presentation/protocols'
import { requestAuthenticateUser } from '@/presentation/useCases/authenticateUser'
import { requestCheckAuth } from '@/presentation/useCases/checkAuth'
import { requestDeauthenticateUser } from '@/presentation/useCases/deauthenticateUser'
import { requestRegisterUser } from '@/presentation/useCases/registerUser'

export type AuthContextType = {
  authenticated: boolean
  registering: boolean
  loading: boolean
  error: Error | boolean
  setAuthenticated: React.Dispatch<SetStateAction<boolean>>
  handleRegister: (payload: AuthPayload) => void
  handleLogin: (payload: AuthPayload) => void
  handleLogout: () => void
  toggleAuth: () => void
}

export const AuthContext = createContext<AuthContextType>({
  authenticated: false,
  registering: false,
  loading: false,
  error: false,
  setAuthenticated: () => undefined,
  handleRegister: () => undefined,
  handleLogin: () => undefined,
  handleLogout: () => undefined,
  toggleAuth: () => undefined
})

export type ReactComponentProps = {
  children?: React.ReactNode | React.ReactNode[]
}

export const AuthProvider = ({ children }: ReactComponentProps) => {
  const [authenticated, setAuthenticated] = useState(false)
  const [registering, setRegistering] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | false>(false)

  const checkAuth = () => {
    requestCheckAuth()
      .then((res: AxiosResponse<CheckAuthResponse>) =>
        setAuthenticated(res.data.authenticated)
      )
      .catch(err => setError(err))
  }

  useEffect(() => checkAuth())

  const toggleAuth = useCallback(() => setRegistering(v => !v), [])

  const handleRegister = ({ email, password }: AuthPayload) => {
    setLoading(true)
    requestRegisterUser({ email, password })
      .then(res => res.status === 201 && setRegistering(false))
      .catch(err => setError(err))
      .finally(() => setLoading(false))
  }

  const handleLogin = ({ email, password }: AuthPayload) => {
    setLoading(true)
    requestAuthenticateUser({ email, password })
      .then(res => res.status === 200 && setAuthenticated(true))
      .catch(err => setError(err))
      .finally(() => setLoading(false))
  }

  const handleLogout = () => {
    setLoading(true)
    requestDeauthenticateUser()
      .then((res: any) => setAuthenticated(res.data.authenticated))
      .catch((err: any) => setError(err))
      .finally(() => setLoading(false))
  }

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        registering,
        loading,
        error,
        setAuthenticated,
        handleRegister,
        handleLogin,
        handleLogout,
        toggleAuth
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => useContext(AuthContext)
