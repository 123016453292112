import {
  ExchangeFilter,
  ExchangeInfoRateLimit,
  OrderType_LT,
  SymbolFilter,
  TradingType_LT
} from 'binance-api-node'

type NONE = 'NONE'

export interface Symbol<T = OrderType_LT> {
  allowedSelfTradePreventionModes: ['NONE']
  baseAsset: string
  baseAssetPrecision: number
  // baseCommissionPrecision: number
  filters: SymbolFilter[]
  icebergAllowed: boolean
  isMarginTradingAllowed: boolean
  isSpotTradingAllowed: boolean
  ocoAllowed: boolean
  orderTypes: T[]
  permissionSets: TradingType_LT[][]
  defaultSelfTradePreventionMode: 'NONE'
  quoteAsset: string
  quoteAssetPrecision: number
  // quoteCommissionPrecision: number
  quoteOrderQtyMarketAllowed: boolean
  allowTrailingStop: boolean
  cancelReplaceAllowed: boolean
  quotePrecision: number
  status: string
  symbol: string
}

export interface BinanceExchangeInfo<T = OrderType_LT> {
  timezone: string
  serverTime: number
  rateLimits: ExchangeInfoRateLimit[]
  exchangeFilters: ExchangeFilter[]
  symbols: Symbol<T>[]
}

const mockExchangeInfo: BinanceExchangeInfo = {
  timezone: 'UTC',
  serverTime: 1565246363776,
  rateLimits: [],
  exchangeFilters: [
    //These are the defined filters in the `Filters` section.
    //All filters are optional.
  ],
  symbols: [
    {
      symbol: 'ETHBTC',
      status: 'TRADING',
      baseAsset: 'ETH',
      baseAssetPrecision: 8,
      quoteAsset: 'BTC',
      quotePrecision: 8,
      quoteAssetPrecision: 8,
      orderTypes: [
        'LIMIT',
        'LIMIT_MAKER',
        'MARKET',
        // 'STOP_LOSS',
        'STOP_LOSS_LIMIT',
        // 'TAKE_PROFIT',
        'TAKE_PROFIT_LIMIT'
      ],
      icebergAllowed: true,
      ocoAllowed: true,
      quoteOrderQtyMarketAllowed: true,
      allowTrailingStop: false,
      cancelReplaceAllowed: false,
      isSpotTradingAllowed: true,
      isMarginTradingAllowed: true,
      filters: [
        //These are defined in the Filters section.
        //All filters are optional
      ],
      permissionSets: [['SPOT', 'MARGIN']],
      defaultSelfTradePreventionMode: 'NONE',
      allowedSelfTradePreventionModes: ['NONE']
    }
  ]
}
