export const baseURL = process.env.NEXT_PUBLIC_BASE_URL
export const binanceBaseUrlV3 = `https://api.binance.com/api/v3`
export const binanceBaseUrlSAPIV1 = `https://api.binance.com/sapi/v1`

export const registerUser = `/register`
export const authenticateUser = `/authenticate`
export const deauthenticateUser = `/deauthenticate`
export const checkAuth = `/heartbeat`
export const saveCredentials = `/post/credentials`
export const loadCredentials = `/get/credentials`
export const cmcQuotes = `/coinmarketcap/quotes`
export const saveExchange = `/exchange`
export const accountInfo = `/account`
export const iconUrl = `/icon`
export const staking = {
  productList: `/staking/productList`,
  productPosition: `/staking/position`
}

export const loadOrders = `/post/load/orders`
export const loadFees = `/post/load/fees`
export const loadKlines = `/post/load/klines`

export const exchangeInfo = `get/exchange/info`
export const tradeFee = `/get/trade/fee`

export const testOrder = `/post/test/order`
export const testAllOrders = `/post/test/orders`

export const executeOrder = `/post/order`
export const executeAllOrders = `/post/orders`
