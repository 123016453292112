export const SYMBOL_ICON_URL_PREFIX_GH = `https://raw.githubusercontent.com/spothq/cryptocurrency-icons/d5c68edec1f5eaec59ac77ff2b48144679cebca1/svg/color`
export const SYMBOL_ICON_URL_PREFIX_TV = `https://s3-symbol-logo.tradingview.com/crypto/XTVC`
export const GENERIC_ICON_URL = `https://raw.githubusercontent.com/spothq/cryptocurrency-icons/d5c68edec1f5eaec59ac77ff2b48144679cebca1/svg/color/generic.svg`
export const TWT_ICON_URL = `https://trustwallet.com/assets/images/media/assets/TWT.svg`
export const FTX_ICON_URL = `https://cryptologos.cc/logos/ftx-token-ftt-logo.svg`
export const FOX_BIT_ICON_URL = `https://cdn.worldvectorlogo.com/logos/foxbit.svg`

export const EXCHANGES_ICONS = {
  // `https://cryptologos.cc/logos/binance-coin-bnb-logo.svg`
  // BINANCE: 'https://raw.githubusercontent.com/spothq/cryptocurrency-icons/d5c68edec1f5eaec59ac77ff2b48144679cebca1/svg/color/binance.svg',
  BINANCE: 'https://cryptologos.cc/logos/binance-coin-bnb-logo.svg',
  BITFINEX: 'https://cryptologos.cc/logos/bitfinex-logo.svg',
  BITSTAMP: 'https://cryptologos.cc/logos/bitstamp-logo.svg',
  BITTREX: 'https://cryptologos.cc/logos/bittrex-logo.svg',
  COINBASE: 'https://cryptologos.cc/logos/coinbase-logo.svg',
  KRAKEN: 'https://cryptologos.cc/logos/kraken-logo.svg',

  FOX_BIT: 'https://cdn.worldvectorlogo.com/logos/foxbit.svg',
  TRUST_WALLET: 'https://trustwallet.com/assets/images/media/assets/TWT.svg'
}

export const COUNTRY_ICON_URL_PREFIX_TV =
  'https://s3-symbol-logo.tradingview.com/country/'
