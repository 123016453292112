import { useMemo } from 'react'

import { BinanceKlinesInterval } from '@/domain/models'
import { Kline, KlineState } from '@/presentation/protocols'

type Props = {
  symbol: string
  interval: BinanceKlinesInterval
  klines: KlineState
  periods?: number
}

export const usePivotsHighLow = ({
  symbol,
  interval,
  klines,
  periods
}: Props) => {
  const priceLevelsKlines = useMemo(() => {
    const streamKlinesSymbol = klines[symbol] ?? {}
    const streams = streamKlinesSymbol[interval]

    if (periods) {
      return Object.values(streams ?? {}).slice(-periods)
    }

    return Object.values(streams ?? {})
  }, [klines])

  const lowestPriceCandle = useMemo(() => {
    return priceLevelsKlines.reduce((partial, kline) => {
      if (partial.low) {
        return Number(kline.low) < Number(partial.low) ? kline : partial
      }
      return kline
    }, {} as Kline)
  }, [priceLevelsKlines])

  const highestPriceCandle = useMemo(() => {
    return priceLevelsKlines.reduce((partial, candle) => {
      if (partial.high) {
        return Number(candle.high) > Number(partial.high) ? candle : partial
      }
      return candle
    }, {} as Kline)
  }, [priceLevelsKlines])

  const pivotsHighLow = useMemo(
    () => ({
      hh: highestPriceCandle.high?.toString(),
      ll: lowestPriceCandle.low?.toString()
    }),
    [lowestPriceCandle, highestPriceCandle]
  )

  return {
    pivotsHighLow,
    priceLevelsKlines,
    lowestPriceCandle,
    highestPriceCandle
  }
}
