import { AxiosResponse } from 'axios'
import { Order, OrderSide } from 'binance-api-node'

import { executeAllOrders } from '@/main/constants/endpoints'
import { OrdersState } from '@/presentation/protocols'
import api from '@/services/api'

export const requestAllNewOrder = async (
  side: OrderSide,
  orders: OrdersState
): Promise<AxiosResponse<Order[]>> =>
  api({ method: `POST`, url: executeAllOrders, data: { side, orders } })
