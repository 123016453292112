import { useCallback, useEffect, useMemo, useState } from 'react'

import { BinanceKlinesInterval } from '@/domain/models'
import { Kline, SymbolsKlinesParams } from '@/presentation/protocols'
import { requestKlines } from '@/presentation/useCases/klines'

import { KlineSerializer } from '../components/contexts/chart/adapters'

type Props = {
  symbol: string
  year: number
}

export const useYearKlines = ({ symbol, year }: Props) => {
  const interval = BinanceKlinesInterval.ONE_DAY
  const limit = 365
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [klines, setKlines] = useState<Kline[]>([])

  const startTime = useMemo(
    () => new Date(year, 0, 1, 0, 0, 0, 0).getTime(),
    []
  )

  const endTime = useMemo(
    () => new Date(year + 1, 1, 0, 0, 0, 0, 0).getTime(),
    []
  )

  const getYearKlines = useCallback(
    (params: SymbolsKlinesParams) => {
      if (loading) return
      setLoading(true)
      requestKlines(params)
        .then(({ data }) => setKlines(KlineSerializer.serialize(data)))
        .catch(error => setError(error))
        .finally(() => setLoading(false))
    },
    [loading]
  )

  const fetchKlines = useCallback(async () => {
    getYearKlines({
      symbols: [symbol],
      interval,
      limit,
      startTime,
      endTime
    })
  }, [symbol, startTime, endTime])

  useEffect(() => {
    let timer: NodeJS.Timeout
    timer = setTimeout(() => fetchKlines(), 300)
    return () => clearTimeout(timer)
  }, [startTime, endTime])

  return {
    loading,
    error,
    klines,
    fetchKlines
  }
}
