import { SymbolTicker, TickerState } from '../ticker/types'

const inUSDT = (asset: SymbolTicker) => asset.symbol.includes(`USDT`)

const isETHBRL = (asset: SymbolTicker) => asset.symbol.includes(`ETHBRL`)

const filterables = (asset: SymbolTicker) => {
  if (isETHBRL(asset)) {
    return true
  }

  return inUSDT(asset)
}

export const getSymbolsFromTicker = (ticker: TickerState) => {
  const arrTicker = Object.values(ticker)
  return arrTicker.length
    ? arrTicker
        .filter(filterables)
        .filter(asset => asset.quoteVolume > 1000000)
        .sort((a, b) =>
          a.priceChangePercent < b.priceChangePercent
            ? 1
            : a.priceChangePercent > b.priceChangePercent
            ? -1
            : 0
        )
        .reduce(
          (symbols: string[], elm: SymbolTicker) => [...symbols, elm.symbol],
          []
        )
    : []
}
