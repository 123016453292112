import { useEffect, useState } from 'react'

export const useLoading = (
  { interval }: { interval: number } = { interval: 2000 }
) => {
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => setLoading(false), [])

  // const startLoading = () => {
  //   setLoading(true)

  //   setTimeout(() => stopLoading(), interval)
  // }

  // const stopLoading = () => setLoading(false)

  return {
    loading
    // startLoading,
    // stopLoading
  }
}
