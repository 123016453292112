import { useCallback, useMemo } from 'react'

import { useWebsocketContext } from '@/presentation/contexts'

type Props = {
  symbol: string
}

export const useLastPrice = ({ symbol }: Props) => {
  const { ticker } = useWebsocketContext()

  const getLastPrice = useCallback(
    (symbol: string) => {
      if (ticker?.[symbol]) {
        return ticker[symbol].lastPrice
      }
      return 0
    },
    [ticker]
  )

  const lastPrice = useMemo(() => getLastPrice(symbol), [getLastPrice])

  return {
    lastPrice,
    getLastPrice
  }
}
