import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { NODE_ENV_PROD } from '@/main/constants/env'
import { gtag } from '@/presentation/helpers'

export const usePageView = () => {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      if (NODE_ENV_PROD) gtag.pageview(url)
    }
    router.events.on(`routeChangeComplete`, handleRouteChange)
    return () => {
      router.events.off(`routeChangeComplete`, handleRouteChange)
    }
  }, [router.events])

  return gtag
}
