export class NumberFormatter {
  static toPercent(initialValue?: number, newValue?: number) {
    if (newValue && initialValue) {
      return 1 - newValue / initialValue
    }

    return 0
  }

  static toPercentFormatted(percent: any, precision: number = 2) {
    if (isNaN(percent)) return '-'
    if (percent === 0) return '-'

    return `${parseFloat(percent ?? 0).toFixed(precision)}%`
  }

  static toPercentF(initialValue: number, newValue: number) {
    if (newValue) {
      const percent = 1 - newValue / initialValue

      if (percent) {
        return this.toPercentFormatted(percent * 100)
      }
    }

    return this.toPercentFormatted(0)
  }

  static toPercentFormat(percent: number | null, precision: number = 2) {
    if (percent) {
      return percent
        .toLocaleString('pt-BR', {
          style: 'percent',
          minimumFractionDigits: precision
        })
        .replace(',', '.')
    }

    return '-'
  }

  static toPrice(_price: any, decimal?: number): string {
    const price = _price ?? 0

    if (price === 0) return '-'

    if (price > 1) return price.toFixed(decimal ?? 2)
    if (price < 1) return price.toFixed(decimal ?? 8)

    return '-'
  }

  static toPriceWithPercent(
    price: number | null,
    percent: number | null,
    decimal?: number
  ) {
    let percentDecimal = 0
    const lowNegativePercent = percent && percent < 0 && percent > -0.001
    const lowPositivePercent = percent && percent > 0 && percent < 0.001

    if (lowNegativePercent || lowPositivePercent) {
      percentDecimal = 1
    }

    return `${this.toPrice(price ?? 0, decimal ?? 2)} (${this.toPercentFormat(
      percent ?? 0,
      percentDecimal ?? 0
    )})`
  }

  static toNonZeroFormat(value?: number | null) {
    if (value === 0) return '-'

    return value ?? '-'
  }
}
