import { NumberFormatter } from '@/presentation/helpers'
import { WalletProps } from '@/presentation/protocols'

import { toPrice } from '../price'

export class Wallet {
  static sortByProfit(orders: WalletProps[]): WalletProps[] {
    return orders.sort((a, b) => {
      if (Number(a.profit) < Number(b.profit)) return 1
      if (Number(a.profit) > Number(b.profit)) return -1
      return 0
    })
  }

  static toViewTotalCapital(orders: WalletProps[]): number {
    const totalCapital = orders.reduce((total: number, wallet: WalletProps) => {
      return total + (wallet.capital ? Number(wallet.capital) : 0)
    }, 0)

    return Number(toPrice(totalCapital))
  }

  static toViewTotalProfit(wallet: WalletProps[]): string {
    const totalProfit = wallet.reduce((total: number, balance: WalletProps) => {
      return total + (balance.profit ? Number(balance.profit) : 0)
    }, 0)

    return toPrice(totalProfit)
  }

  static toViewTotalProfitPercent(wallet: WalletProps[]): number {
    const totalProfit = wallet.reduce((total: number, balance: WalletProps) => {
      return total + (balance.profit ? Number(balance.profit) : 0)
    }, 0)

    const totalCapital = wallet.reduce(
      (total: number, balance: WalletProps) => {
        return total + (balance.capital ? Number(balance.capital) : 0)
      },
      0
    )

    return totalCapital ? totalProfit / totalCapital : 0
  }

  static toViewTotalProfitPercentF(orders: WalletProps[]): string {
    return NumberFormatter.toPercentFormatted(
      this.toViewTotalProfitPercent(orders) * 100,
      4
    )
  }
}
