import {
  AssetBalance,
  NewOrderMarketQuote,
  OrderSide,
  OrderType,
  QueryOrderResult
} from 'binance-api-node'

import { OrdersState, SymbolsCurrency } from '@/presentation/protocols'
import { Order } from '@/presentation/types'
import { TickerState } from '@/services/broker/ticker/types'

export class Orders {
  constructor(private readonly orders: QueryOrderResult[]) {
    this.orders = orders
  }

  static byFilledOrders(order: QueryOrderResult) {
    return order.status === 'FILLED'
  }

  static bySymbol(symbol: string, orders: QueryOrderResult[]) {
    return orders.filter(o => o.symbol === symbol)
  }

  getValidOrders = () => this.orders.filter(Orders.byFilledOrders)

  getOrdersBySymbol(symbol: string) {
    return this.getValidOrders().filter(
      (order: QueryOrderResult) => order.symbol === symbol
    )
  }

  getOrdersSymbols() {
    return Array.from(new Set(this.getValidOrders().map(o => o.symbol)))
  }

  // static byExecutedOrders(order: QueryOrderResult) {
  //   return orders.filter((o) => Number(o.) > 0);
  // }

  getSellSideOrders() {
    return this.getValidOrders().filter(o => o.side === 'SELL')
  }

  getBuySideOrders() {
    return this.getValidOrders().filter(o => o.side === 'BUY')
  }

  getExecutedBuyOrders() {
    return this.getBuySideOrders().filter(o => o.executedQty === o.origQty)
  }

  getExecutedSellOrders() {
    return this.getSellSideOrders().filter(o => o.executedQty === o.origQty)
  }

  // getSummedOrders(side: string, balances: AssetBalance[]) {
  //   const getExecutedOrders =
  //     side === 'BUY' ? this.getExecutedSellOrders : this.getExecutedBuyOrders;

  //   return getExecutedOrders().reduce((a, o: QueryOrderResult) => {
  //     return {
  //       ...a,
  //       [o.symbol]: {
  //         qty: (a[o.symbol]?.qty ?? 0) + Number(o.executedQty),
  //         capital: (a[o.symbol]?.capital ?? 0) + Number(o.cummulativeQuoteQty),
  //         midPrice: this.getSymbolMidPrice(o.symbol, balances),
  //       },
  //     };
  //   }, {});
  // }

  getSymbolCapital(symbol: string) {
    return this.getOrdersBySymbol(symbol).reduce(
      (total, o: QueryOrderResult) => {
        const cummulativeQuoteQty = Number(o.cummulativeQuoteQty)

        if (o.side === `BUY`) {
          return total + cummulativeQuoteQty
        }

        return total >= cummulativeQuoteQty
          ? total - 0 // cummulativeQuoteQty
          : total
      },
      0
    )
  }

  static getSymbolBalances(symbol: string, balances: AssetBalance[]) {
    const balance = (balances ?? []).find(
      balance => symbol.slice(0, balance.asset.length) === balance.asset
    )

    return Number(balance?.free ?? 0) + Number(balance?.locked ?? 0)
  }

  getSymbolMidPrice(symbol: string, balances: AssetBalance[]) {
    const symbolCapital = this.getSymbolCapital(symbol)
    const symbolBalance = Orders.getSymbolBalances(symbol, balances)

    return symbolCapital && symbolBalance ? symbolCapital / symbolBalance : 0
  }

  getSummedOrders(side: 'BUY' | 'SELL', balances: AssetBalance[]) {
    type SummedBalances = {
      [key: string]: {
        qty: number
        capital: number
        midPrice: number
      }
    }

    return (
      side === 'BUY'
        ? this.getExecutedSellOrders()
        : this.getExecutedBuyOrders()
    ).reduce((a: SummedBalances, o: QueryOrderResult) => {
      return {
        ...a,
        [o.symbol]: {
          qty: (a[o.symbol]?.qty ?? 0) + Number(o.executedQty),
          capital: (a[o.symbol]?.capital ?? 0) + Number(o.cummulativeQuoteQty),
          midPrice: this.getSymbolMidPrice(o.symbol, balances)
          // profit: this.getSymbolProfit(o.symbol)
        }
      }
    }, {})
  }

  // sumOrdersBalances() {
  //   return orders.reduce(
  //     (a, o) => {
  //       return {
  //         ...a,
  //         [o.symbol]:
  //           a[o.symbol] + Number(o.cummulativeQuoteQty) / Number(o.executedQty),
  //       };
  //     },
  //     [{}],
  //   );
  // }

  static filterByStatus(
    data: QueryOrderResult[],
    status: 'NEW' | 'FILLED' | 'ALL'
  ) {
    if (status === 'ALL') return data

    return data.filter(o => o.status === status)
  }

  static filterByNewSell(data: QueryOrderResult[]) {
    return data.filter(o => o.side === 'SELL').filter(o => o.status === 'NEW')
  }

  static filterByNewBuy(data: QueryOrderResult[]) {
    return data.filter(o => o.side === 'BUY').filter(o => o.status === 'NEW')
  }

  static filterByBuySide(data: QueryOrderResult[]) {
    return data.filter(Orders.byFilledOrders).filter(o => o.side === 'BUY')
  }

  static filterBySellSide(data: QueryOrderResult[]) {
    return data.filter(Orders.byFilledOrders).filter(o => o.side === 'SELL')
  }

  static filterBySide(data: QueryOrderResult[], side: string) {
    if (side === 'SELL') {
      return this.filterBySellSide(data)
    }
    if (side === 'BUY') {
      return this.filterByBuySide(data)
    }
    return data
  }

  static filterBySymbolBalances(
    data: QueryOrderResult[],
    symbolsProfit: SymbolsCurrency,
    symbolsBalances: SymbolsCurrency,
    symbolsCapitals: SymbolsCurrency
  ) {
    const _orders: QueryOrderResult[] = []
    const orders = new Orders(data)

    Object.entries(symbolsBalances).forEach(([symbol, balance]) => {
      const symbolOrders = orders
        .getValidOrders()
        .filter(o => o.symbol === symbol)

      const sellOrders = orders.getExecutedSellOrders()
      const buyOrders = orders.getExecutedBuyOrders()

      _orders.push(...buyOrders, ...sellOrders)
    })

    return _orders
  }

  static byBalances(orders: QueryOrderResult[], ticker: TickerState) {
    return Object.values(ticker).map(ticker => {
      return orders.filter(o => o.symbol === ticker.symbol)
    })
  }

  static sortByProfit(
    orders: QueryOrderResult[],
    symbolsProfit: SymbolsCurrency
  ) {
    return orders.sort((a, b) =>
      Number(symbolsProfit[a.symbol]) < Number(symbolsProfit[b.symbol])
        ? 1
        : Number(symbolsProfit[a.symbol]) > Number(symbolsProfit[b.symbol])
        ? -1
        : 0
    )
  }

  static sortByDate(orders: QueryOrderResult[]) {
    return orders.sort((a, b) =>
      a.time < b.time ? 1 : a.time > b.time ? -1 : 0
    )
  }

  static sortByPriceChangePercent(orders: Order[], ticker: TickerState) {
    return orders.sort((a: Order, b: Order) => {
      const aDelta = ticker[a.symbol].priceChangePercent
      const bDelta = ticker[b.symbol].priceChangePercent

      return bDelta < bDelta ? 1 : aDelta > bDelta ? -1 : 0
    })
  }

  static toNewOrdersSpot(
    side: OrderSide,
    orders: OrdersState
  ): NewOrderMarketQuote[] {
    return Object.values(orders).map(({ symbol, quoteOrderQty }) => ({
      symbol,
      side,
      type: `MARKET` as OrderType.MARKET,
      quoteOrderQty: quoteOrderQty.toString()
    }))
  }
}
