'use client'

import dynamic from 'next/dynamic'

const GridLayoutProvider = dynamic(
  () => import('@/presentation/contexts/GridLayout'),
  {
    ssr: false
  }
)

type Props = {
  children: React.ReactNode
}

export const ClientProviders: React.FC<Props> = ({ children }) => {
  return <GridLayoutProvider>{children}</GridLayoutProvider>
}
