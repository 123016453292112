import { GA_TRACKING_ID, NODE_ENV_DEV } from '@/main/constants/env'

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: URL) => {
  if (NODE_ENV_DEV) return
  window.gtag(`config`, GA_TRACKING_ID ?? ``, {
    page_path: url
  })
}

type GTagEvent = {
  action: string
  category: string
  label: string
  value: number
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: GTagEvent) => {
  if (NODE_ENV_DEV) return
  window.gtag(`event`, action, {
    event_category: category,
    event_label: label,
    value
  })
}

export const gtag = { pageview, event }
