// theme definitions

const themes = {
  gold: {
    primary: '#F0B90B',
    secondary: '#a88107',
    light: '#f1c023',
    contrast: '#1a1a1a'
  },
  diamond: {
    primary: '#00b4c9',
    secondary: '#007d8c',
    light: '#19bbce',
    contrast: '#c93900'
  },
  emerald: {
    primary: '#5741D9',
    secondary: '#3c2d97',
    light: '#6754dc',
    contrast: '#d99741'
  }
}

const selected = themes.gold

export const theme = {
  // default screen sizes
  layout: {
    width: 1366,
    height: 768
  },

  // theme colors
  colors: {
    main: {
      primaryColor: selected.primary,
      secondaryColor: selected.secondary,
      contrastColor: selected.contrast,
      disabledColor: '#ffffff26',
      platformBackground: '#101010',
      black: '#000000',
      white: '#ffffff',
      red: '#EA1336',
      grey: '#7C7C7C70',
      lightGrey: '#90909070'
    },
    button: {
      primary: selected.light,
      disabled: selected.secondary,
      active: selected.primary,
      hover: selected.primary,
      text: '#1a1a1a'
    },
    navbar: {
      itemHover: '#ffffff20',
      itemActive: selected
    },
    modes: {
      waterSea: {
        primaryColor: '#00bcd4',
        secondaryColor: '#00a9be',
        disabledColor: '#ffffff26',
        platformBackground: '#101010',
        black: '#000000',
        white: '#ffffff'
      }
    },
    order: {
      idle: '#84e6e8',
      buy: '#00bd84',
      sell: 'tomato',
      filled: '#e39237',
      sold: selected.primary,
      stopped: 'tomato',
      new: '#6754dc',
      default: '#646464',
      quantity: '#353535',
      quantityHover: '#868686',
      quantitySelected: '#646464'
    },
    market: {
      lossColor: 'tomato',
      gainColor: '#00bd84',
      idle: '#646464',
      selected: selected.light,
      selectedBG: '#303030'
    },
    menu: {
      optionActiveColor: '#c4c4c4'
    },
    card: {
      background: '#101010',
      optionDisabled: '#434343'
    },
    option: {
      label: '#646464',
      value: '#c4c4c4',
      selected: '#131313'
    },
    input: {
      iconColor: '#646464',
      text: '#646464',
      background: '#101010',
      focus: '#434343',
      checkboxTextColor: '#646464',
      infoLabel: '#434343',
      placeholderText: '#646464'
    },
    exchange: {
      binance: '#F3BA2F',
      foxbit: '#ff7400',
      ftx: '#00b4c9',
      kraken: '#5741d9'
    },
    badge: {
      success: '#013A1F',
      textSuccess: '#03894A',
      primary: selected.primary,
      textPrimary: selected.light,
      text: '#000'
    },
    block: {
      border: '#222',
      background: '#101010',
      action: selected.primary
    },
    mode: {
      light: {
        // white: '#ffffff';
      }
    }
  },
  font: {
    family: {
      primary: 'Rajdhani',
      secondary: 'Noto Sans Mono'
    },
    size: {
      sm: '12px',
      md: '14px',
      lg: '16px',
      xl: '28px'
    },
    weight: {
      thin: 100,
      light: 300,
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700
    }
  },

  // theme font-family
  fontFamily: {
    primary: 'Rajdhani',
    secondary: 'Noto Sans Mono'
  },

  // theme font-sizes
  fontSize: {
    small: '12px',
    medium: '14px',
    large: '16px',
    extraLarge: '28px'
  },

  // theme font-weights
  fontWeight: {
    Thin: 100,
    Light: 300,
    Regular: 400,
    Medium: 500,
    Bold: 700,
    ExtraBold: 800,
    Black: 900
  },

  // theme shadow effects
  shadows: {
    navbarShadow: '0px -4px 5px 0px #FFFFFF10',
    optionShadow: '0px 4px 4px 0px #FFFFFF10',
    cardShadow: '0px 4px 4px 0px #FFFFFF10',
    buttonActiveShadow: '0px 0px 12px 0px #F0B90B',
    buyButtonActiveShadow: '0px 0px 4px 0px #02C076',
    sellButtonActiveShadow: '0px 0px 4px 0px #EA1336'
  }
}

export type AppTheme = typeof theme
