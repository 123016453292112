import Image from 'next/image'
import { isMobile } from 'react-device-detect'

import Header from '@/presentation/components/structure/Header'
import {
  AccountInfoProvider,
  KlinesQueueProvider,
  KlineProvider,
  OrdersProvider,
  SymbolProvider
} from '@/presentation/contexts'
import { ApiProvider } from '@/presentation/contexts/Api'
import { BacktestsProvider } from '@/presentation/contexts/Backtests'
import { SignalsProvider } from '@/presentation/contexts/signals'
import ThemeProvider from '@/presentation/contexts/Theme'
import { WebsocketProvider } from '@/presentation/contexts/Websocket'

import { ClientProviders } from './client'
import { LayoutProps } from './protocols'

export const AppLayout: React.FC<LayoutProps> = ({ children }: LayoutProps) => {
  if (isMobile) {
    return (
      <div className="flex flex-col gap-2 justify-center items-center h-screen w-screen">
        <Image src="/logo-brand.svg" alt="Logo" height={24} width={120} />
        <div className="flex gap-2">
          <span className="text-sm font-primary text-secondary font-semibold">
            Mobile
          </span>
          <div className="flex items-center bg-secondary px-2 rounded-full h-4">
            <span className="font-primary text-neutral-800 text-[11px] leading-4">
              soon
            </span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <ThemeProvider>
      <Header />
      <ClientProviders>
        <ApiProvider>
          <SignalsProvider>
            <SymbolProvider>
              <WebsocketProvider>
                <AccountInfoProvider>
                  <KlinesQueueProvider>
                    <KlineProvider>
                      <BacktestsProvider>
                        <OrdersProvider>{children}</OrdersProvider>
                      </BacktestsProvider>
                    </KlineProvider>
                  </KlinesQueueProvider>
                </AccountInfoProvider>
              </WebsocketProvider>
            </SymbolProvider>
          </SignalsProvider>
        </ApiProvider>
      </ClientProviders>
    </ThemeProvider>
  )
}
