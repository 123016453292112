import { AxiosResponse } from 'axios'

import { registerUser } from '@/main/constants/endpoints'
import { AuthPayload } from '@/presentation/protocols'
import api from '@/services/api'

export const requestRegisterUser = async (
  data: AuthPayload
): Promise<AxiosResponse> =>
  api({
    method: `POST`,
    url: registerUser,
    data
  })
