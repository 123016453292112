import { AxiosResponse } from 'axios'

import { loadCredentials } from '@/main/constants/endpoints'
import api from '@/services/api'

export const requestLoadCredentials = async (): Promise<AxiosResponse> =>
  api({
    method: `GET`,
    url: loadCredentials
  })
