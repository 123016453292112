import { AxiosResponse } from 'axios'

import { ApiCredentials } from '@/domain/models'
import { saveCredentials } from '@/main/constants/endpoints'
import api from '@/services/api'

export const requestSaveCredentials = async (
  data: ApiCredentials
): Promise<AxiosResponse> =>
  api({
    method: `POST`,
    url: saveCredentials,
    data
  })
