import { useMemo } from 'react'

import { BinanceKlinesInterval } from '@/domain/models'

import { useKlinesContext } from '../contexts'
import { Indicators } from '../helpers'
import { KlineState } from '../protocols'

import { usePivotsHighLow } from './use-pivots-high-low'
import { useSymbol } from './use-symbol'

type Props = {
  symbol: string
  interval: BinanceKlinesInterval
  klines: KlineState
}

export const useIndicators = ({ symbol, interval, klines }: Props) => {
  const { period } = useKlinesContext()
  const { lastPrice, symbolKlines } = useSymbol({ symbol, interval, klines })
  const { pivotsHighLow } = usePivotsHighLow({ symbol, interval, klines })

  const indicators = useMemo(
    () => Indicators.get(lastPrice, symbolKlines, period, pivotsHighLow),
    [symbol, interval, klines, lastPrice, symbolKlines, period, pivotsHighLow]
  )

  return { indicators }
}
