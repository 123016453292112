import { NODE_ENV_PROD } from '@/main/constants/env'

export const log = (...data: any) => {
  if (NODE_ENV_PROD) return

  console.log(...data)
}

export const time = (...data: any) => {
  if (NODE_ENV_PROD) return

  console.time(...data)
}

export const timeEnd = (...data: any) => {
  if (NODE_ENV_PROD) return

  console.timeEnd(...data)
}

export const error = (...data: any) => {
  if (NODE_ENV_PROD) return

  console.error(...data)
}

export const table = (...data: any) => {
  if (NODE_ENV_PROD) return

  console.table(...data)
}
