import { useEffect, useState } from 'react'

const useDimensions = () => {
  const [width, setWidth] = useState<number>(1920)
  const [height, setHeight] = useState<number>(1080)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768

  return {
    isMobile,
    width,
    height
  }
}

export default useDimensions
