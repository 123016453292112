import { KlineSerializer } from '@/presentation/components/contexts/chart/adapters'
import { KlineState } from '@/presentation/protocols'

import {
  KlineStreamsType,
  StreamKline,
  StreamKlineState
} from '../../protocols/streams'

export class StreamsSerializer {
  static streamToKline(data: KlineStreamsType): StreamKline {
    return {
      openTime: Number(data.t),
      closeTime: Number(data.T),
      symbol: data.s,
      interval: data.i,
      firstTradeID: Number(data.f),
      lastTradeID: Number(data.L),
      open: data.o,
      close: data.c,
      high: data.h,
      low: data.l,
      baseAssetVolume: data.v,
      trades: Number(data.n),
      isClosed: data.x,
      quoteAssetVolume: data.q,
      takerQuoteAssetVolume: data.V,
      takerBaseAssetVolume: data.Q,
      ignore: data.B
    }
  }

  static prevKline(
    state: StreamKlineState | null,
    candlestick: StreamKline
  ): StreamKline {
    const symbols = state ? state[candlestick.symbol] : {}
    const intervals = Object.keys(symbols ?? {}).length
      ? symbols[candlestick.interval]
      : {}
    const _candlestick = Object.keys(symbols ?? {}).length
      ? intervals[candlestick.openTime - 1000]
      : ({} as StreamKline)

    return _candlestick
  }

  static latestKline(
    state: StreamKlineState | null,
    candlestick: StreamKline
  ): StreamKline {
    const { symbol, interval, openTime } = candlestick

    if (state) {
      const symbols = state[symbol] ?? {}
      const intervals = symbols[interval] ?? {}
      const _candlestick = intervals[openTime] ?? {}

      if (_candlestick.openTime > candlestick.openTime) {
        return _candlestick
      }
    }
    return candlestick
  }

  static serialize(state: KlineState): StreamKlineState {
    const serializedState: StreamKlineState = {}

    for (const symbol in state) {
      if (state.hasOwnProperty(symbol)) {
        const intervalsState = state[symbol]
        serializedState[symbol] = {}

        for (const interval in intervalsState) {
          if (intervalsState.hasOwnProperty(interval)) {
            const candlesticks = intervalsState[interval]
            serializedState[symbol][interval] = {}

            for (const openTime in candlesticks) {
              if (candlesticks.hasOwnProperty(openTime)) {
                const candlestick = candlesticks[openTime]
                serializedState[symbol][interval][openTime] =
                  KlineSerializer.toStreamKline(candlestick)
              }
            }
          }
        }
      }
    }

    return serializedState
  }

  static merge(historical: StreamKlineState, candlesticks: StreamKlineState) {
    const mergedState: StreamKlineState = { ...historical }

    for (const symbol in candlesticks) {
      if (candlesticks.hasOwnProperty(symbol)) {
        const intervalsState = candlesticks[symbol]
        if (!mergedState.hasOwnProperty(symbol)) {
          mergedState[symbol] = {}
        }
        for (const interval in intervalsState) {
          if (intervalsState.hasOwnProperty(interval)) {
            const candlesticksData = intervalsState[interval]
            if (!mergedState[symbol].hasOwnProperty(interval)) {
              mergedState[symbol][interval] = {}
            }
            for (const openTime in candlesticksData) {
              if (candlesticksData.hasOwnProperty(openTime)) {
                const candlestick = candlesticksData[openTime]
                mergedState[symbol][interval][openTime] = candlestick
              }
            }
          }
        }
      }
    }

    return mergedState
  }

  static toState(stream: StreamKline) {
    return function (prev: StreamKlineState) {
      const prevSymbol = prev[stream.symbol] ?? {}
      const prevInterval = prevSymbol[stream.interval] ?? {}

      return {
        ...prev,
        [stream.symbol]: {
          ...prevSymbol,
          [stream.interval]: {
            ...prevInterval,
            [stream.openTime]: stream
          }
        }
      } as StreamKlineState
    }
  }
}
