import { createContext, useContext, useMemo } from 'react'

import { NumberFormatter } from '@/presentation/helpers'
import { WalletState } from '@/presentation/protocols'

import { useAccountInfoContext } from './AccountInfo'
import { useOrdersContext } from './Orders'
import { useWebsocketContext } from './Websocket'

export type WalletContextType = {
  wallet: WalletState
}

export const WalletContext = createContext<WalletContextType>({
  wallet: {} as WalletState
})

type Props = {
  children?: React.ReactNode | React.ReactNode[]
}

export const WalletProvider = ({ children }: Props) => {
  const {
    symbolsMidPrices,
    symbolsCapitals,
    symbolsProfit,
    symbolsProfitPercent
  } = useOrdersContext()
  const { symbolsBalances, balancesSymbols } = useAccountInfoContext()
  const { interval, periods, ticker, USDTBRLPrice } = useWebsocketContext()
  // const { candles, getSymbolsCandles } = useKlinesContext()

  // TODO: make annual balances conditional
  // const fetchWalletBalances = useCallback(async () => {
  //   getSymbolsCandles({
  //     symbols: balancesSymbols,
  //     interval,
  //     limit: periods
  //   })
  // }, [balancesSymbols, interval, periods])

  // useEffect(() => {
  //   fetchWalletBalances()
  // }, [balancesSymbols])

  // const getAnnualBalanceUSDT = useCallback(
  //   (symbol: string, openTime: number = 1701129600000) => {
  //     const _symbols = candles[symbol] ?? {}
  //     const _intervals = _symbols[interval] ?? {}
  //     const lastYearCandle = _intervals[openTime] ?? {}

  //     return lastYearCandle
  //       ? (symbolsBalances[symbol] * lastYearCandle?.close ?? 0).toString()
  //       : '0'
  //   },
  //   [candles]
  // )

  const getBalanceUSDT = (symbol: string) => {
    return ticker
      ? (symbolsBalances[symbol] * ticker[symbol].lastPrice).toString()
      : ''
  }

  const wallet: WalletState = useMemo(() => {
    const keys = balancesSymbols

    return keys.reduce((wallet, symbol) => {
      return {
        ...wallet,
        [symbol]: {
          symbol,
          balance:
            NumberFormatter.toPrice(symbolsBalances[symbol])?.toString() ?? '',
          balance$:
            NumberFormatter.toPrice(
              Number(getBalanceUSDT(symbol))
            )?.toString() ?? '',
          // balanceYear: Number(getAnnualBalanceUSDT(symbol)),
          // balanceYearF:
          //   toPrice(Number(getAnnualBalanceUSDT(symbol)))?.toString() ?? '',
          capital:
            NumberFormatter.toPrice(symbolsCapitals[symbol])?.toString() ?? '',
          profit:
            NumberFormatter.toPrice(symbolsProfit[symbol])?.toString() ?? '',
          MidPrice:
            NumberFormatter.toPrice(symbolsMidPrices[symbol])?.toString() ?? '',
          LastPrice: ticker
            ? NumberFormatter.toPrice(ticker[symbol]?.lastPrice)?.toString()
            : '',
          DayChangeP: ticker
            ? NumberFormatter.toPercentFormatted(
                ticker[symbol].priceChangePercent
              ) ?? ''
            : '',
          DayChange$: ticker
            ? NumberFormatter.toPrice(ticker[symbol].priceChange, 2).toString()
            : '',
          TotalProfitP:
            NumberFormatter.toPercentFormatted(symbolsProfitPercent[symbol]) ??
            ''
        }
      }
    }, {} as WalletState)
  }, [balancesSymbols, symbolsBalances])

  // useEffect(() => {
  //   const balanceYear = Object.values(wallet).reduce((balanceYear, balance) => {
  //     if (balance.balanceYear && balance.symbol !== 'BTC') {
  //       log(
  //         `DEBUG balanceYear`,
  //         balance.balanceYear,
  //         USDTBRLPrice,
  //         balance.symbol,
  //         balance.balanceYear
  //       )

  //       return balanceYear + balance.balanceYear * USDTBRLPrice
  //     }

  //     return balanceYear
  //   }, 0)

  //   log(
  //     `DEBUG balanceYear`,
  //     balanceYear,
  //     Object.values(wallet).map(b => ({ s: b.symbol, b: b.balanceYear }))
  //   )
  // }, [wallet])

  return (
    <WalletContext.Provider
      value={{
        wallet
      }}
    >
      {children}
    </WalletContext.Provider>
  )
}

export const useWalletContext = () => useContext(WalletContext)
