import { useMemo } from 'react'

import { ObjectKeys } from '../helpers'
import {
  PartialInterval,
  TotalsData,
  TotalsDataKeys,
  TotalsKeys
} from '../protocols'

export const useBalancesTotals = (balances: Record<string, TotalsData>) => {
  const dateOrder = ['M', 'w', 'd']

  const orderByDate = (a: string, b: string) => {
    if (a.includes('h')) return 1

    return dateOrder.indexOf(a.slice(1, 2)) - dateOrder.indexOf(b.slice(1, 2))
  }

  const orderByLookback = (a: string, b: string) => {
    if (a.includes('24')) return 1

    return Number(b.slice(0, 1)) - Number(a.slice(0, 1))
  }

  const totals = useMemo(() => {
    const total = {} as TotalsData

    Object.keys(balances).forEach(symbol => {
      ObjectKeys.get<TotalsData>(balances[symbol] ?? {})
        .sort(orderByLookback)
        .sort(orderByDate)
        .forEach((key: TotalsKeys) => {
          const balance = balances[symbol][key]
          if (balance) {
            total[key] = (total[key] ?? 0) + balance
          }
        })
    })

    return total
  }, [balances])

  const getTotalsLookbackInterval = (
    interval: PartialInterval,
    append: string,
    lookback?: number
  ) => {
    if (interval === '24h') {
      return `${interval}${append}` as TotalsDataKeys
    }

    return `${lookback ?? 1}${interval.slice(1)}${append}` as TotalsDataKeys
  }

  return { totals, getTotalsLookbackInterval }
}
