import { createContext, useContext, useState } from 'react'

import { ReactComponentProps } from '@/presentation/types'

import { useBalancesTotals } from '../hooks'
import { PartialInterval, SymbolsTotals, TotalsData } from '../protocols'

type BalanceParams = {
  symbol: string
  interval: PartialInterval
  balance: number
  lookback?: number
}

type SymbolContextType = {
  balances: SymbolsTotals
  totals: TotalsData
  addBalance: (params: BalanceParams) => void
}

export const ExchangeBalanceContext = createContext<SymbolContextType>(
  {} as SymbolContextType
)

export const ExchangeBalanceProvider = ({ children }: ReactComponentProps) => {
  const [balances, setBalances] = useState<SymbolsTotals>({})
  const { totals, getTotalsLookbackInterval } = useBalancesTotals(balances)

  const addBalance = ({
    symbol,
    interval,
    balance,
    lookback
  }: BalanceParams) => {
    setBalances(prev => {
      const newBalances = { ...prev }
      const _interval = getTotalsLookbackInterval(interval, 'Totals', lookback)

      newBalances[symbol] = {
        ...newBalances[symbol],
        [_interval]: balance
      }

      return newBalances
    })
  }

  return (
    <ExchangeBalanceContext.Provider value={{ totals, balances, addBalance }}>
      {children}
    </ExchangeBalanceContext.Provider>
  )
}

export const useExchangeBalancesContext = () =>
  useContext(ExchangeBalanceContext)
