import { AxiosResponse } from 'axios'

import { authenticateUser } from '@/main/constants/endpoints'
import { AuthPayload } from '@/presentation/protocols'
import api from '@/services/api'

export const requestAuthenticateUser = async (
  data: AuthPayload
): Promise<AxiosResponse> =>
  api({
    method: `POST`,
    url: authenticateUser,
    data
  })
