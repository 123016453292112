import { createContext, useContext, useState } from 'react'
import { ThemeProvider as Theme } from 'styled-components'

import { theme as baseTheme } from '@/presentation/styles/theme'
import { ReactComponentProps } from '@/presentation/types'

export type ThemeContextType = {
  theme: typeof baseTheme
  selectTheme: (theme: typeof baseTheme) => void
}

export const ThemeContext = createContext<ThemeContextType>(
  {} as ThemeContextType
)

export const ThemeProvider = ({ children }: ReactComponentProps) => {
  const [theme, setTheme] = useState<typeof baseTheme>(baseTheme)

  const selectTheme = (theme: typeof baseTheme) => setTheme(theme)

  return (
    <ThemeContext.Provider
      value={{
        theme,
        selectTheme
      }}
    >
      <Theme theme={theme}>{children}</Theme>
    </ThemeContext.Provider>
  )
}

export const useThemeContext = () => useContext(ThemeContext)

export default ThemeProvider
