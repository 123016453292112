import { AxiosResponse } from 'axios'

import { BinanceKline } from '@/domain/models'
import { loadKlines } from '@/main/constants/endpoints'
import { SymbolsKlinesParams } from '@/presentation/protocols'
import api from '@/services/api'

export const requestKlines = async (
  data: SymbolsKlinesParams
): Promise<AxiosResponse<BinanceKline[]>> =>
  api({ method: `POST`, url: loadKlines, data })
