import { AxiosResponse } from 'axios'
import { QueryOrderResult } from 'binance-api-node'

import { loadOrders } from '@/main/constants/endpoints'
import api from '@/services/api'

type OrdersPayload = {
  balancesSymbols: string[]
}

export const requestOrders = async (
  data: OrdersPayload
): Promise<AxiosResponse<QueryOrderResult[]>> =>
  api({ method: `POST`, url: loadOrders, data })
