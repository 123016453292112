import Head from 'next/head'
import * as React from 'react'

const Header: React.FC = () => (
  <Head>
    <link rel="icon" href="/favicon.ico" />
  </Head>
)

export default Header
