import { AxiosError, AxiosResponse } from 'axios'
import { AssetBalance, QueryOrderResult } from 'binance-api-node'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

import {
  BinanceAccountInfo,
  BinanceExchangeInfo,
  FeeResult
} from '@/domain/models'
import { DELAY_BALANCES_UPDATE } from '@/main/constants/account'
import {
  AccountIndicators,
  AccountIndicatorsData
} from '@/presentation/helpers'
import {
  FeesState,
  SymbolsCurrency,
  SymbolsIcons
} from '@/presentation/protocols'
import { ReactComponentProps } from '@/presentation/types'
import { requestAccountInfo } from '@/presentation/useCases/accountInfo'
import { requestExchangeInfo } from '@/presentation/useCases/exchangeInfo'
import { requestAllFees } from '@/presentation/useCases/loadFees'
import {
  getBalanceTotals,
  getSymbolUSDTBalances,
  getTotalFromBalance,
  getTotalFromBalances
} from '@/services/broker/balances/balance'
import { feeFromResult } from '@/services/fee'
import { Timer } from '@/services/timer'

import { Icon } from '../helpers'

import { useWebsocketContext } from './Websocket'

export type AccountContextType = {
  loading: boolean
  fees: FeesState
  accountInfo: BinanceAccountInfo
  exchangeInfo: BinanceExchangeInfo
  symbolsIcons: SymbolsIcons
  balancesSymbols: string[]
  symbolsBalances: SymbolsCurrency
  hasBalances: boolean
  accountTotals: number
  accountIndicators: AccountIndicatorsData
  marketSymbols: string[]
  getSymbolsFees: () => void
  getAccountInfo: () => void
  getBalancesSymbols: () => void
  symbolHasBalances: (order: QueryOrderResult) => boolean
}

export const AccountInfoContext = createContext<AccountContextType>({
  loading: false,
  fees: {} as FeesState,
  exchangeInfo: {} as BinanceExchangeInfo,
  accountInfo: {} as BinanceAccountInfo,
  symbolsIcons: {} as SymbolsIcons,
  balancesSymbols: [],
  symbolsBalances: {} as SymbolsCurrency,
  hasBalances: false,
  accountTotals: 0,
  accountIndicators: {} as AccountIndicatorsData,
  marketSymbols: [] as string[],
  getSymbolsFees: () => undefined,
  getAccountInfo: () => undefined,
  getBalancesSymbols: () => [],
  symbolHasBalances: () => false
})

export type AssetBalanceTreated = {
  asset: string
  free: string
  locked: string
  total: number
}

const timer = new Timer()

export const AccountInfoProvider = ({ children }: ReactComponentProps) => {
  const [loading, setLoading] = useState(false)
  const [loadingExchange, setLoadingExchange] = useState(false)
  const [error, setError] = useState<AxiosResponse | false | undefined>(false)
  const [accountInfo, setAccountInfo] = useState({} as BinanceAccountInfo)
  const [exchangeInfo, setExchangeInfo] = useState({} as BinanceExchangeInfo)
  const [balancesSymbols, setBalancesSymbols] = useState([] as string[])
  const [symbolsBalances, setSymbolsBalances] = useState({} as SymbolsCurrency)
  const [hasBalances, setHasBalances] = useState(false)
  const [symbolsIcons, setSymbolsIcons] = useState({} as SymbolsIcons)
  const [fees, setFees] = useState({} as FeesState)
  const [lastBalanceUpdate, setLastBalanceUpdate] = useState<number>(Date.now())

  const { symbols, ticker, getLastPrice, USDTBRLPrice } = useWebsocketContext()

  const accountTotals = useMemo(() => {
    if (ticker && accountInfo?.balances?.length) {
      const balanceTotals = getBalanceTotals(accountInfo.balances, ticker)
      const USDTBRLPrice = ticker.USDTBRL?.lastPrice ?? 0
      const balanceUSDT = getTotalFromBalances(accountInfo.balances, `USDT`)
      const balanceBRL = getTotalFromBalances(accountInfo.balances, `BRL`)

      if (USDTBRLPrice) {
        return balanceTotals + balanceUSDT + balanceBRL / USDTBRLPrice
      }
      return 0
    }
    return 0
  }, [ticker, accountInfo])

  const marketSymbols = useMemo(
    () => (exchangeInfo.symbols ?? []).map(s => s.symbol),
    [exchangeInfo.symbols]
  )

  const accountIndicators = useMemo(() => {
    return AccountIndicators.get(accountTotals, USDTBRLPrice)
  }, [accountTotals, USDTBRLPrice])

  const getAccountInfo = async () => {
    setLoading(true)
    requestAccountInfo()
      .then(res => {
        setAccountInfo({
          ...res.data,
          balances: res.data.balances.filter((balance: AssetBalance) => {
            return (
              Number(balance.free) > 0 ||
              Number(balance.locked) + Number(balance.free) > 0
            )
          })
        })
      })
      .catch((err: AxiosError) => setError(err.response))
    // .finally(() => setLoading(false))
  }

  const getExchangeInfo = useCallback(() => {
    if (loadingExchange) return
    setLoadingExchange(true)
    if (!Object.values(exchangeInfo).length && !error) {
      requestExchangeInfo()
        .then(({ data: exchangeInfo }) => {
          const newExchangeInfo = {
            ...exchangeInfo,
            symbols: exchangeInfo.symbols
              .filter(s => s.permissionSets.flat().includes('SPOT'))
              .filter(s => s.status === 'TRADING')
              .filter(s => s.quoteAsset === 'USDT')
              .filter(
                s => !s.symbol.startsWith('USDT') || s.symbol === 'USDTBRL'
              )
            // .filter(symbol => balancesSymbols.includes(symbol.symbol))
          }

          if (newExchangeInfo.symbols.length) {
            setExchangeInfo(newExchangeInfo)
          }
        })
        .catch((err: AxiosError) => setError(err.response))
        .finally(() => setLoadingExchange(false))
    }
  }, [error, balancesSymbols, loadingExchange])

  const getSymbol = (asset: string) => {
    if (asset === `USDT`) return `${asset}BRL`
    if (asset === `ETH`) return `${asset}BRL`
    if (asset === `BRL`) return `USDT${asset}`

    return `${asset}USDT`
  }

  const getBalancesSymbols = () => {
    if (accountInfo.balances?.length) {
      const newBalancesSymbols = accountInfo.balances.reduce(
        (symbols: string[], balance: AssetBalance) => {
          let pair = `USDT`

          if (balance.asset === `ETH` || balance.asset === `BUSD`) {
            pair = `BRL`
          }

          let total = getLastPrice(`${balance?.asset ?? ``}${pair}`)

          if (!total) {
            pair = 'BUSD'
            total = getLastPrice(`${balance?.asset ?? ``}${pair}`)
          }

          if (
            balance.asset !== pair &&
            balance.asset !== `BRL` &&
            getTotalFromBalance(balance) * total > 1
          ) {
            return [...symbols, `${balance.asset}${pair}`]
          }

          return symbols
        },
        []
      )

      setBalancesSymbols(newBalancesSymbols)
    }
  }

  const getSymbolsBalances = useCallback(() => {
    const now = Date.now()
    if (now - lastBalanceUpdate > DELAY_BALANCES_UPDATE) {
      const sym = balancesSymbols.length ? balancesSymbols : symbols

      let symbolsBalances = {} as SymbolsCurrency

      for (const symbol of sym) {
        const symbolBalance = (accountInfo?.balances ?? []).find(
          balance => symbol.slice(0, balance.asset.length) === balance.asset
        )

        if (symbolBalance) {
          symbolsBalances = Object.assign(symbolsBalances, {
            [symbol]: getTotalFromBalance(symbolBalance)
          })
        }
      }

      setSymbolsBalances(symbolsBalances)
      setLastBalanceUpdate(Date.now())
      setLoading(false)
    }
  }, [balancesSymbols, accountInfo.balances, symbols])

  const symbolHasBalances = (order: QueryOrderResult) => {
    const lastPrice = getLastPrice(order.symbol)
    let symbol = order.symbol.replace(`USDT`, ``)

    if (order.symbol.slice(-3).includes(`BRL`)) {
      symbol = order.symbol.replace(`BRL`, ``)
    }

    if (Number(lastPrice)) {
      return getSymbolUSDTBalances(symbol, accountInfo) * lastPrice > 1
    }

    return false
  }

  const getSymbolsIcons = () => {
    balancesSymbols.forEach((symbol: string) => {
      if (!symbolsIcons[symbol]) {
        Icon.getSymbolIcon(symbol)
          .then(iconUrl => {
            setSymbolsIcons((oldIcons: Record<string, string>) => ({
              ...oldIcons,
              [symbol]: iconUrl
            }))
          })
          .catch()
      }
    })
  }

  const getSymbolsFees = useCallback(() => {
    // setLoading(true)
    if (balancesSymbols.length && !Object.values(fees).length) {
      requestAllFees({ balancesSymbols })
        .then(({ data: results }) =>
          setFees(
            results.reduce(
              (newFees: FeesState, result: FeeResult) => ({
                ...newFees,
                [result.symbol]: feeFromResult(result)
              }),
              {} as FeesState
            )
          )
        )
        .catch(err => setError(err))
      // .finally(() => setLoading(false))
    }
  }, [])

  const checkHasBalances = () => {
    Object.values(symbolsBalances).some(
      balance => balance > 0 && setHasBalances(true)
    )
  }

  // useEffect(() => filterBalances(), [accountInfo])
  useEffect(() => getBalancesSymbols(), [ticker])
  useEffect(() => getExchangeInfo(), [balancesSymbols])
  useEffect(() => getSymbolsBalances(), [balancesSymbols])
  useEffect(() => getSymbolsIcons(), [balancesSymbols])
  // useEffect(() => getSymbolsFees(), [balancesSymbols]);
  useEffect(() => checkHasBalances(), [symbolsBalances])

  useEffect(() => {
    const hasPassed = timer.hasPassed(10)
    if (hasPassed) {
      if (!Object.keys(accountInfo).length) {
        getAccountInfo()
      }
    }
  })

  return (
    <AccountInfoContext.Provider
      value={{
        loading,
        fees,
        exchangeInfo,
        accountInfo,
        symbolsIcons,
        symbolsBalances,
        balancesSymbols,
        hasBalances,
        accountTotals,
        getSymbolsFees,
        getAccountInfo,
        marketSymbols,
        accountIndicators,
        getBalancesSymbols,
        symbolHasBalances
      }}
    >
      {children}
    </AccountInfoContext.Provider>
  )
}

export const useAccountInfoContext = () => useContext(AccountInfoContext)
