import { useMemo } from 'react'

import { BinanceKlinesInterval } from '@/domain/models'
import { KlineState } from '@/presentation/protocols'

import { Fibonacci } from '../helpers'

import { usePivotsHighLow } from './use-pivots-high-low'

type Props = {
  symbol: string
  interval: BinanceKlinesInterval
  klines: KlineState
  lookback?: number
}

export const usePriceLevels = ({
  symbol,
  interval,
  klines,
  lookback = 0
}: Props) => {
  const {
    pivotsHighLow,
    priceLevelsKlines,
    lowestPriceCandle,
    highestPriceCandle
  } = usePivotsHighLow({
    symbol,
    interval,
    klines
  })

  const priceLevels = useMemo(
    () => Fibonacci.getFiboPriceLevels(pivotsHighLow),
    [pivotsHighLow]
  )

  const ohlc = useMemo(
    () => ({
      open: Number(priceLevelsKlines.at(-1)?.open),
      high: Number(highestPriceCandle.high),
      low: Number(lowestPriceCandle.low),
      close: Number(priceLevelsKlines.at(-1)?.close)
    }),
    [priceLevelsKlines, highestPriceCandle, lowestPriceCandle]
  )

  return {
    priceLevels,
    ohlc
  }
}
