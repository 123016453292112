import { useMemo } from 'react'

import { useWebsocketContext } from '../contexts'
import { KlineState } from '../protocols'

type Props = {
  symbol: string
  interval: string
  klines: KlineState
}

export const useSymbol = ({ symbol, interval, klines }: Props) => {
  const { getLastPrice } = useWebsocketContext()

  const symbolKlines = useMemo(() => {
    const _kline = klines ? klines[symbol] : {}
    return _kline && interval
      ? Object.values(_kline[interval] ?? {}).sort((a, b) =>
          a.openTime > b.openTime ? 1 : a.openTime < b.openTime ? -1 : 0
        )
      : []
  }, [symbol, klines])

  const lastPrice = getLastPrice(symbol)

  return { lastPrice, symbolKlines }
}
