import { useEffect } from 'react'

import { showLogInfo } from '@/services/logs'

export const useUserLogs = () => {
  const initializeProtocol = () => {
    showLogInfo()
  }

  useEffect(() => {
    initializeProtocol()
  })

  return { showLogInfo }
}
