import { TickerState, TickerStreamType } from '@/services/broker/ticker/types'

export class TickerSerializer {
  static format(stream: TickerStreamType) {
    return {
      symbol: stream.s,
      lastPrice: Number(stream.c),
      priceChange: Number(stream.p),
      priceChangePercent: Number(stream.P),
      highPrice: Number(stream.h),
      lowPrice: Number(stream.l),
      quoteVolume: Number(stream.q)
    }
  }

  static from(ticker: TickerState, stream: TickerStreamType) {
    return {
      ...ticker,
      [stream.s]: this.format(stream)
    }
  }

  static fromStreams(prev: TickerState, data: TickerStreamType[]): TickerState {
    const updatedTicker = { ...prev }
    data.forEach(stream => {
      updatedTicker[stream.s] = this.format(stream)
    })
    return updatedTicker
  }

  static fromStream(prev: TickerState, stream: TickerStreamType): TickerState {
    return TickerSerializer.from(prev, stream)
  }
}
