import { AxiosResponse } from 'axios'

import { FeeResponseData } from '@/domain/models'
import { loadFees } from '@/main/constants/endpoints'
import api from '@/services/api'

import { BalanceSymbolsPayload } from '../protocols'

export const requestAllFees = async (
  data: BalanceSymbolsPayload
): Promise<AxiosResponse<FeeResponseData>> =>
  api({ method: `POST`, url: loadFees, data })
