import { NumberFormatter } from '@/presentation/helpers'

import Order from './order'

export type BacktestsIndicatorsData = {
  dailyProfit: number
  dailyProfitF: string
  dailyProfitGain?: boolean
  totalPositionsCapital: number
  totalPositionsCapitalF: string
  dailyTradingProfitP: number
  dailyTradingProfitPF: string
  dailyTradingProfitGain?: boolean
  totalProfit: number
  totalProfitF: string
  totalProfitGain?: boolean
  totalProfitP: number
  totalProfitPF: string
  dailyProfitM: number
  dailyProfitMGain?: boolean
  dailyProfitMF: string
  accountTotals: number
  accountTotalsF: string
}

export class BacktestsIndicators {
  static get(orders: Order[], accountTotals: number): BacktestsIndicatorsData {
    if (orders.length) {
      const totalPositionsCapital = this.getTotalCapital(orders)

      const dailyProfit = this.getDailyProfit(orders)

      const dailyProfitM = this.getDailyProfitM(orders)

      const dailyTradingProfitP = accountTotals
        ? dailyProfit / accountTotals
        : 0

      const totalProfit = this.getTotalProfit(orders)
      const totalProfitP =
        totalProfit && accountTotals
          ? NumberFormatter.toPercent(totalPositionsCapital, totalProfit)
          : 0

      return {
        dailyProfit,
        dailyProfitF: NumberFormatter.toPrice(dailyProfit, 2),
        dailyProfitGain: dailyProfit === 0 ? undefined : dailyProfit > 0,
        dailyProfitM,
        dailyProfitMF: NumberFormatter.toPercentFormat(dailyProfitM),
        dailyProfitMGain: dailyProfitM === 0 ? undefined : dailyProfitM > 0,
        totalPositionsCapital,
        totalPositionsCapitalF: NumberFormatter.toPrice(totalPositionsCapital),
        dailyTradingProfitP,
        dailyTradingProfitPF:
          NumberFormatter.toPercentFormat(dailyTradingProfitP),
        dailyTradingProfitGain: dailyTradingProfitP > 0,
        totalProfit,
        totalProfitF: NumberFormatter.toPrice(totalProfit),
        totalProfitGain: totalProfit > 0,
        totalProfitP,
        totalProfitPF: NumberFormatter.toPercentFormat(totalProfitP),
        accountTotals,
        accountTotalsF: NumberFormatter.toPrice(accountTotals)
      }
    }

    return {
      dailyProfit: 0,
      dailyProfitF: '',
      dailyProfitM: 0,
      dailyProfitMF: '',
      dailyTradingProfitP: 0,
      dailyTradingProfitPF: '',
      totalPositionsCapital: 0,
      totalPositionsCapitalF: '',
      totalProfitP: 0,
      totalProfitPF: '',
      totalProfit: 0,
      totalProfitF: '',
      accountTotals: 0,
      accountTotalsF: ''
    }
  }

  static getDailyProfit(orders: Order[]) {
    return orders
      .filter(o => o.isProfitableOrder())
      .filter(o => o.isTodayOrder())
      .reduce((profit, order) => {
        return profit + order.getProfit()
      }, 0)
  }

  static getTotalProfit(orders: Order[]) {
    return orders
      .filter(o => o.isProfitableOrder())
      .reduce((profit, order) => {
        return profit + order.getProfit()
      }, 0)
  }

  static getTotalCapital(orders: Order[]) {
    return orders
      .filter(o => o.isProfitableOrder())
      .reduce((profit, order) => {
        return profit + order.getCapital()
      }, 0)
  }

  static getDailyProfitM(orders: Order[]) {
    const days = Array.from(
      new Set(
        orders
          .filter(o => o.isProfitableOrder())
          .map(o => new Date(o.time).toLocaleDateString())
      )
    ).length

    return this.getTotalProfit(orders) / days / this.getTotalProfit(orders)
  }

  static getOldestOrderTime(orders: Order[]) {
    return orders
      .filter(o => o.isClosedOrder())
      .sort((a, b) => {
        if (a.time > b.time) return 1
        if (a.time < b.time) return -1
        return 0
      })
      .reduce(
        (oldest, order) => {
          if (order.time < oldest.time) return order
          return oldest
        },
        { time: Number.POSITIVE_INFINITY } as Order
      )
  }
}
