import { CandleChartInterval, OrderType_LT } from 'binance-api-node'

import { OrderModel } from '@/domain/models'
import { LOGS } from '@/main/config/logs'
import { Signal, Signal_LT } from '@/presentation/contexts/signals'
import { Currency, Time, log } from '@/presentation/helpers'
import { StreamKline } from '@/presentation/protocols'

export type OrderParams = {
  id: string
  time: number
  label: string
  symbol: string
  interval: string
  side: string
  quantity: number
  leverage: number
  status: string
  type: OrderType_LT
  price: number
  level: string
  initialProfit: number
  initialStopLoss: number
  buyPrice: number | null
  sellPrice: number
  stopLossSellPrice: number
  stopGain: number | null
  buyPriceTime: number | null
  sellPriceTime: number | null
  closeOrderTime: number | null
  strategy: string
  strategyId: string
  userId: string
}

export type OrderSignals = {
  signal: Signal_LT
  shouldSell: boolean
  shouldBuy: boolean
  shouldStopLoss: boolean
  shouldStopGain: boolean
  shouldTrailing: boolean
}

export class Order {
  public isClosed: boolean
  public isOpen: boolean

  // public closeOrderTime: number | null = null
  public lastPrice: number | null = null

  public profit: number | null = null
  public profitPercent: number | null = null

  // public buyPriceTime: number | null = null
  // public sellPriceTime: number | null = null

  // public stopGain: number | null = null
  public stopGainPercent: number | null = null

  public timeUpdate: number | null = null

  public trailingThreshold: number = 0.001
  public useTrailing: boolean = true

  constructor(
    public readonly id: string,
    public readonly time: number,
    public readonly label: string,
    public readonly symbol: string,
    public readonly interval: string,
    public readonly side: string,
    public readonly quantity: number,
    public readonly leverage: number,
    public status: string,
    public readonly type: string,
    public price: number,
    public level: string,
    public initialProfit: number,
    public initialStopLoss: number,
    public readonly strategy: string,
    public readonly strategyId: string,
    public readonly userId: string,
    public buyPrice: number | null = null,
    public sellPrice: number | null = null,
    public stopLossSellPrice: number | null = null,
    public stopGain: number | null = null,
    public buyPriceTime: number | null = null,
    public sellPriceTime: number | null = null,
    public closeOrderTime: number | null = null
  ) {
    this.id = id
    this.time = time
    this.quantity = quantity
    this.leverage = leverage
    this.status = status
    this.label = label
    this.symbol = symbol
    this.interval = interval
    this.side = side
    this.type = type
    this.price = price
    this.level = level
    this.initialProfit = initialProfit
    this.initialStopLoss = initialStopLoss
    this.isClosed = Order.isClosed(status)
    this.isOpen = Order.isOpen(status)
    this.buyPrice = buyPrice
    this.sellPrice = sellPrice
    this.stopLossSellPrice = stopLossSellPrice
    this.stopGain = stopGain
    this.buyPriceTime = buyPriceTime
    this.sellPriceTime = sellPriceTime
    this.closeOrderTime = closeOrderTime
    this.strategy = strategy
    this.strategyId = strategyId
    this.userId = userId
  }

  static from(params: OrderParams) {
    return new Order(
      params.id,
      params.time,
      params.label,
      params.symbol,
      params.interval,
      params.side,
      params.quantity,
      params.leverage,
      params.status,
      params.type,
      params.price,
      params.level,
      params.initialProfit,
      params.initialStopLoss,
      params.strategy,
      params.strategyId,
      params.userId,
      params.buyPrice,
      params.sellPrice,
      params.stopLossSellPrice,
      params.stopGain,
      params.buyPriceTime,
      params.sellPriceTime,
      params.closeOrderTime
    )
  }

  static isClosed(status: string) {
    return status === 'SOLD' || status === 'STOPPED' || status === 'EXPIRED'
  }

  static isOpen(status: string) {
    return status === 'FILLED'
  }

  isNotExpiredOrder() {
    return !this.isExpiredOrder()
  }

  isExpiredOrder() {
    return this.status === 'EXPIRED'
  }

  isOpenOrder() {
    return this.isNewOrder() || this.isFilledOrder()
  }

  isIdleOrder() {
    return this.status === 'IDLE'
  }

  isNewOrder() {
    return this.status === 'NEW'
  }

  isFilledOrder() {
    return this.status === 'FILLED'
  }

  isStoppedOrder() {
    return this.status === 'STOPPED'
  }

  isSoldOrder() {
    return this.status === 'SOLD'
  }

  isProfitableOrder() {
    return this.isFilledOrder() || this.isSoldOrder() || this.isStoppedOrder()
  }

  isClosedOrder() {
    return this.isSoldOrder() || this.isStoppedOrder() || this.isExpiredOrder()
  }

  isLastHourOrder() {
    const lastHour = Date.now() - 3600000
    return this.time > lastHour
  }

  isLastHoursOrder(hours: number = 1) {
    const lastHour = Date.now() - 3600000 * hours
    return this.time > lastHour
  }

  isWeekOrder() {
    const d = new Date()
    const day = d.getDay()
    const diff = d.getDate() - day + (day == 0 ? -7 : 0)
    const sunday = new Date(d.setDate(diff)).setUTCHours(0, 0, 0, 0)
    return this.time > sunday
  }

  isLast24HoursOrder() {
    const last24Hours = Date.now() - 100000 * 36 * 24
    return this.time > last24Hours
  }

  isYesterdayOrder() {
    const startOfToday = new Date().setUTCHours(0, 0, 0, 0)
    const startOfYesterday = startOfToday - 24 * 3600 * 1000

    return this.time >= startOfYesterday && this.time < startOfToday
  }

  isTodayOrder() {
    const startOfDay = new Date().setUTCHours(0, 0, 0, 0)
    return this.time > startOfDay
  }

  isAboveProfitThreshold(price: number, logging = false) {
    const priceAboveThreshold = this.getTrailingTargetAboveThreshold()
    const isAboveProfitThreshold = price > priceAboveThreshold
    if (isAboveProfitThreshold) {
      logging &&
        log('DEBUG isAboveProfitThreshold: ', {
          p: price,
          pAT: priceAboveThreshold,
          iAPT: isAboveProfitThreshold
        })
    }

    return isAboveProfitThreshold
  }

  isAboveStopGainThreshold(price: number, logging = false) {
    const priceAboveThreshold = this.getStopGainTargetAboveThreshold()
    const isAboveStopGainThreshold =
      this.stopGain && price > priceAboveThreshold

    if (isAboveStopGainThreshold) {
      logging &&
        log('DEBUG isAboveStopGainThreshold: ', {
          p: price,
          pAT: priceAboveThreshold,
          iASGT: isAboveStopGainThreshold
        })
    }

    return isAboveStopGainThreshold
  }

  isSameSymbol(symbol: string) {
    return this.symbol === symbol
  }

  hasNewSellPrice() {
    const { minutes, seconds } = Time.timeFrom(this.sellPriceTime!, Date.now())
    // log('hasNewSellPrice', minutes, seconds, !minutes && seconds <= 60)
    return !minutes && seconds <= 60
  }

  hasNewLastPrice(lastPrice: number) {
    return this.lastPrice !== lastPrice
  }

  isRecentUpdatedOrder() {
    const { minutes, seconds } = Time.timeFrom(this.timeUpdate!, Date.now())
    // log('isRecentUpdatedOrder', minutes, seconds, !minutes && seconds <= 60)
    return !minutes && seconds <= 60
  }

  isRecentClosedOrder() {
    const { minutes, seconds } = Time.timeFrom(this.closeOrderTime!, Date.now())
    // log('isRecentClosedOrder', minutes, seconds, !minutes && seconds <= 60)
    return !minutes && seconds <= 60
  }

  isUpgradeableOrder(lastPrice: number) {
    const isNotNewOrder = !this.isNewOrder()
    const hasNewLastPrice = this.hasNewLastPrice(lastPrice)
    const isRecentUpdatedOrder = this.isRecentUpdatedOrder()

    log({ hasNewLastPrice, isRecentUpdatedOrder, isNotNewOrder })

    return (hasNewLastPrice || isRecentUpdatedOrder) && isNotNewOrder
  }

  getId() {
    return this.id
  }

  getQuantity() {
    return this.quantity
  }

  getOrderPrice() {
    return this.buyPrice ?? this.price
  }

  getLiquidationPrice() {
    return this.getOrderPrice() * this.leverage
  }

  getCapital() {
    return this.getOrderPrice() * this.quantity
  }

  getSellPrice(
    price = this.getOrderPrice(),
    initialProfit = this.initialProfit
  ) {
    return Currency.logRound(price * (1 + initialProfit))
  }

  getStopLossPrice(
    price = this.getOrderPrice(),
    initialStopLoss = this.initialStopLoss
  ) {
    return Currency.logRound(price * (1 - initialStopLoss))
  }

  getTrailingTargetAboveThreshold() {
    return Currency.logRound(this.getSellPrice() * (1 + this.trailingThreshold))
  }

  getStopGainTargetAboveThreshold() {
    return Currency.logRound(this.stopGain! * (1 + this.trailingThreshold))
  }

  getStopGainPrice(close: number) {
    const lastStopGainPrice = this.stopGain
      ? Currency.logRound(this.stopGain)
      : null
    if (lastStopGainPrice) {
      const newStopGainPrice = Currency.logRound(
        close * (1 - this.initialStopLoss)
      )

      if (newStopGainPrice > lastStopGainPrice) {
        return newStopGainPrice
      }
      return lastStopGainPrice
    }

    if (this.buyPrice) {
      return Currency.logRound(this.buyPrice * (1 + this.initialProfit))
    }

    return Currency.logRound(this.price * (1 + this.initialProfit))
  }

  activateTrailing() {
    this.useTrailing = true
  }

  deactivateTrailing() {
    this.useTrailing = false
  }

  setCloseOrderTime(timestamp?: number) {
    this.closeOrderTime = timestamp ?? Date.now()
    this.isClosed = true
    this.isOpen = false
  }

  setLastPrice(price: number) {
    this.lastPrice = price
  }

  setProfitPercent(price = this.lastPrice) {
    if (price) {
      this.profitPercent = price
        ? Currency.logRound((price / this.getOrderPrice() - 1) * this.leverage)
        : 0
    }
  }

  getProfitPercent() {
    return this.profitPercent ?? 0
  }

  setProfit() {
    this.profit = Currency.logRound(
      (this.getProfitPercent() ?? 0) * this.getCapital()
    )
  }

  getProfit() {
    return this.profit ?? 0
  }

  setStopGainPercent(price = this.stopGain) {
    if (price) {
      this.stopGainPercent = Currency.logRound(
        (price / this.getOrderPrice() - 1) * this.leverage
      )
    }
  }

  setBuyPrice(buyPrice: number) {
    this.buyPrice = buyPrice
    this.buyPriceTime = Date.now()
    this.setStatus('FILLED')
    this.setSellPrice()
    this.setStopLossPrice()
  }

  setSellPrice(
    price = this.getSellPrice(),
    sellPriceTime = Date.now(),
    logging = false
  ) {
    const reachStopGain = price <= this.stopGain!
    if (reachStopGain) {
      logging &&
        log(
          'LOGS: reached StopGain... setting new sellPrice',
          price,
          '<=',
          this.stopGain
        )
      this.lastPrice = this.stopGain
      this.sellPrice = this.stopGain
      this.sellPriceTime = sellPriceTime

      return
    }

    const firstSellPrice = !this.sellPrice
    const newSellPriceAbove = this.sellPrice && price > this.sellPrice
    const reachedStopLoss =
      this.stopLossSellPrice && price <= this.stopLossSellPrice

    if (firstSellPrice || newSellPriceAbove || reachedStopLoss) {
      logging &&
        log('LOGS: setting new sellPrice', {
          nSPA: newSellPriceAbove,
          rSL: reachedStopLoss,
          fSP: firstSellPrice,
          sP: price
        })
      this.sellPrice = price
      this.sellPriceTime = sellPriceTime
    }
  }

  setStopGain(price = this.getSellPrice()) {
    log('LOGS: setting new stopGain', {
      oSGP: this.stopGain,
      sGP: price
    })

    this.stopGain = price
    this.setStopGainPercent(price)
  }

  setStopLossPrice(price = this.getStopLossPrice(), logging = false) {
    const before = {
      sLP: this.stopLossSellPrice,
      p: price,
      pGSLP: this.stopLossSellPrice ? price > this.stopLossSellPrice : false
    }

    const firstStopLossPrice = !this.stopLossSellPrice
    const isAboveStopLoss =
      !!this.stopLossSellPrice && price > this.stopLossSellPrice
    const priceAboveBuy = this.buyPrice && this.buyPrice < this.price

    if (firstStopLossPrice || isAboveStopLoss || priceAboveBuy) {
      logging &&
        log('LOGS: setting new stopLossSellPrice', {
          before,
          fSLP: firstStopLossPrice,
          iASL: isAboveStopLoss,
          pAB: priceAboveBuy,
          nSL: price
        })
      this.stopLossSellPrice = price
    }
  }

  setStatus(status: string) {
    this.status = status
  }

  setNew() {
    this.status = 'NEW'
    return this
  }

  setExpired() {
    this.status = 'EXPIRED'
    this.setCloseOrderTime()
    return this
  }

  // setInitialStopLoss(percent: number) {
  //   this.initialStopLoss = percent
  // }

  // checkProfitReach(price: number) {
  //   return 1 - price / this.getOrderPrice() >= this.initialProfit
  // }

  // setStopGain(price: number) {
  //   this.stopGain = price
  // }

  // setStopGainPercent(percent: number) {
  //   this.stopGainPercent = percent
  // }

  // trailingStop(price: number) {
  //   this.stopGain = this.stopGainPercent ? price * this.stopGainPercent : 0
  // }

  setTimeUpdate(timestamp: number) {
    this.timeUpdate = timestamp
  }

  executeOrder(close: number) {
    const timestamp = Date.now()
    this.setLastPrice(close)
    this.setBuyPrice(close)
    this.setProfitPercent()
    this.setProfit()
    this.setStatus('FILLED')
    this.setSellPrice(this.getSellPrice(), timestamp)
    this.setStopLossPrice()
    this.setTimeUpdate(timestamp)
    log('Executed Order', this)
    return this
  }

  realizePNL(close: number) {
    const timestamp = Date.now()
    this.setLastPrice(close)
    this.setSellPrice(close, timestamp)
    this.setProfitPercent()
    this.setProfit()
    this.setStatus('SOLD')
    this.setCloseOrderTime(timestamp)
    this.setTimeUpdate(timestamp)
    return this
  }

  realizeStopGain(lastPrice = this.stopGain!) {
    const timestamp = Date.now()
    this.setLastPrice(lastPrice)
    this.setSellPrice(lastPrice, timestamp)
    this.setProfitPercent()
    this.setProfit()
    this.setStatus('SOLD')
    this.setCloseOrderTime(timestamp)
    this.setTimeUpdate(timestamp)
    log('DEBUG realizeStopGain', this)
    return this
  }

  realizeStopLoss(lastPrice = this.stopLossSellPrice!) {
    const timestamp = Date.now()
    this.setLastPrice(lastPrice)
    this.setSellPrice(lastPrice, timestamp)
    this.setProfitPercent(lastPrice)
    this.setProfit()
    this.setStatus('STOPPED')
    this.setCloseOrderTime(timestamp)
    this.setTimeUpdate(timestamp)
    return this
  }

  updateTrailingTargets(close: number, logging = false) {
    const timestamp = Date.now()
    const firstSellPrice = this.getSellPrice()
    const oldSellPrice = this.getSellPrice(this.lastPrice!)
    const newSellPrice = this.getSellPrice(close)
    const newStopLossPrice = this.getStopLossPrice(close)
    const stopGainPrice = this.getStopGainPrice(close)

    this.setLastPrice(close)

    const firstTrailing = !this.stopGain
    const isTrailing = !!this.stopGain
    const newStopGain = stopGainPrice > this.stopGain!
    const hasNewValidStopGain = isTrailing && newStopGain
    const lastValidStopGain = hasNewValidStopGain
      ? stopGainPrice
      : this.stopGain

    const isAboveStopGainThreshold = this.isAboveStopGainThreshold(
      close,
      logging
    )

    const willUpdateStopGain = firstTrailing || hasNewValidStopGain // || isAboveStopGainThreshold

    logging &&
      log('DEBUG willUpdateStopGain', {
        fT: firstTrailing,
        oldSG: this.stopGain,
        nSGP: stopGainPrice,
        hNVSG: hasNewValidStopGain,
        lVSG: lastValidStopGain,
        iASGT: isAboveStopGainThreshold,
        w: willUpdateStopGain
      })

    if (willUpdateStopGain) {
      logging &&
        log('LOGS willUpdateStopGain (yes)', {
          fT: firstTrailing,
          oldSG: this.stopGain,
          nSGP: stopGainPrice,
          hNVSG: hasNewValidStopGain,
          lVSG: lastValidStopGain,
          iASGT: isAboveStopGainThreshold
        })
      this.setStopGain(stopGainPrice)
    } else {
      logging &&
        log('LOGS willUpdateStopGain (not)', {
          c: close,
          sGP: this.stopGain,
          nSGP: stopGainPrice
        })
    }

    const willUpdateSellPrice = this.sellPrice
      ? newSellPrice > this.sellPrice
      : newSellPrice

    if (willUpdateSellPrice) {
      logging &&
        log(LOGS.shouldActivateTrailing, {
          fSP: firstSellPrice,
          oSP: oldSellPrice,
          nSP: newSellPrice,
          sGP: stopGainPrice
        })

      this.setSellPrice(newSellPrice, timestamp)
    }

    const belowLastSellPrice = newStopLossPrice < oldSellPrice
    const sameStopLossPrice = newStopLossPrice === this.stopLossSellPrice
    const sameAsStopGain = newStopLossPrice === stopGainPrice
    const willUpdateStopLoss = !sameStopLossPrice && !sameAsStopGain
    const newStopLoss = belowLastSellPrice ? oldSellPrice : newStopLossPrice

    // if (willUpdateStopLoss) {
    //   logging &&
    //     log('LOGS willUpdateStopLoss', {
    //       lVSG: lastValidStopGain,
    //       wUSL: willUpdateStopLoss,
    //       lSP: oldSellPrice,
    //       sl: this.stopLossSellPrice,
    //       nSL: newStopLoss
    //     })

    //   this.setStopLossPrice(newStopLoss)
    // }

    logging &&
      log('DEBUG updateTrailingTargets', {
        lp: this.lastPrice,
        fSP: firstSellPrice,
        oSP: oldSellPrice,
        nSP: this.sellPrice,
        sL: this.stopLossSellPrice,
        // nSLP: newStopLossPrice,
        sGP: this.stopGain
      })

    this.setProfitPercent()
    this.setProfit()
    this.setTimeUpdate(timestamp)

    return this
  }

  updateOrder(close: number) {
    const timestamp = Date.now()
    this.setLastPrice(close)
    // this.setSellPrice(this.getSellPrice(), timestamp)
    this.setProfit()
    this.setProfitPercent()
    this.setTimeUpdate(timestamp)
    return this
  }

  reload(order: OrderModel) {
    this.closeOrderTime = order.closeOrderTime
    this.lastPrice = order.lastPrice
    this.buyPrice = order.buyPrice
    // this.sellPrice = order.sellPrice
    this.profit = order.profit
    this.profitPercent = order.profitPercent
    this.buyPriceTime = order.buyPriceTime
    this.sellPriceTime = order.sellPriceTime

    this.stopGain = order.stopGain
    this.stopGainPercent = order.stopGainPercent
    this.timeUpdate = order.timeUpdate

    this.isClosed = order.isClosed
    this.isOpen = order.isOpen

    if (order.stopLossSellPrice) {
      this.stopLossSellPrice = order.stopLossSellPrice
    }
  }

  shouldBuy(close: number, logging = false) {
    const shouldBuy = this.isNewOrder() && close <= this.price
    if (shouldBuy) {
      logging &&
        log('DEBUG shouldBuy', {
          iNO: this.isNewOrder(),
          c: close,
          p: this.price,
          sB: shouldBuy
        })
    }

    return shouldBuy
  }

  shouldSell(close: number, logging = false) {
    const isFilledOrder = this.isFilledOrder()
    const shouldNotTrailing = !this.shouldTrailing(close, logging)
    const reachedSellPrice = close >= this.sellPrice!
    const shouldSell = isFilledOrder && reachedSellPrice && shouldNotTrailing

    if (shouldSell) {
      logging &&
        log('DEBUG shouldSell', {
          iFO: isFilledOrder,
          c: close,
          sP: this.sellPrice,
          sS: shouldSell
        })
    }

    return shouldSell
  }

  reachedStopLoss(close: number, logging = false) {
    const reachedStopLoss = close <= this.stopLossSellPrice!

    if (reachedStopLoss) {
      logging &&
        log('DEBUG reachedStopLoss', {
          rSL: reachedStopLoss,
          c: close,
          sLP: this.stopLossSellPrice
        })
    }

    return reachedStopLoss
  }

  reachedTrailingTarget(close: number, logging = false) {
    const shouldNotTrailing = !this.shouldTrailing(close, logging)
    const reachedTrailingTarget = close >= this.sellPrice!

    if (reachedTrailingTarget && shouldNotTrailing) {
      log('DEBUG reachedTrailingTarget', {
        rTT: reachedTrailingTarget,
        sNT: shouldNotTrailing,
        c: close,
        sP: this.sellPrice
      })
    }

    return reachedTrailingTarget
  }

  reachedStopGain(close: number) {
    const reachedStopGain = !!this.stopGain && close <= this.stopGain

    if (reachedStopGain) {
      log('DEBUG reachedStopGain', {
        rSG: reachedStopGain,
        c: close,
        sG: this.stopGain
      })
    }

    return reachedStopGain
  }

  shouldStopLoss(close: number, logging = false) {
    return this.reachedStopLoss(close, logging)
  }

  shouldStopGain(close: number, logging = false) {
    const shouldStopGain = this.reachedStopGain(close)
    // const shouldStopGain = reachedStopGain // close > this.buyPrice!

    if (shouldStopGain) {
      logging &&
        log('DEBUG shouldStopGain', {
          sSG: shouldStopGain,
          // rSG: reachedStopGain,
          c: close,
          bP: this.buyPrice!
        })
    }

    return shouldStopGain
  }

  shouldTrailing(price: number, logging = false) {
    const isFilled = this.isFilledOrder()
    const isAboveProfitThreshold = this.isAboveProfitThreshold(price, logging)

    const shouldTrailing =
      isFilled && isAboveProfitThreshold && this.useTrailing
    // !this.stopGain &&

    if (shouldTrailing) {
      logging &&
        log('DEBUG shouldTrailing', {
          sP: this.sellPrice,
          isFilled,
          iAPT: isAboveProfitThreshold,
          sT: shouldTrailing
        })
    }

    return shouldTrailing
  }

  getSignal(signals: OrderSignals): Signal_LT {
    if (signals.shouldBuy) {
      return 'BUY'
    }

    if (signals.shouldStopGain) {
      return 'STOP_GAIN'
    }

    if (signals.shouldTrailing) {
      return 'TRAILING'
    }

    if (signals.shouldSell) {
      return 'SELL'
    }

    if (signals.shouldStopLoss) {
      return 'STOP_LOSS'
    }

    return 'WAIT'
  }

  getOrderSignals = (
    price: number,
    setSignal?: (signal: Signal) => void,
    logging = false
  ): OrderSignals => {
    const shouldSell = this.shouldSell(price, logging)
    const shouldBuy = this.shouldBuy(price, logging)
    const shouldStopLoss = this.shouldStopLoss(price, logging)
    const shouldStopGain = this.shouldStopGain(price, logging)
    const shouldTrailing = this.shouldTrailing(price, logging)

    const signals: OrderSignals = {
      shouldSell,
      shouldBuy,
      shouldStopLoss,
      shouldStopGain,
      shouldTrailing
    } as OrderSignals

    signals.signal = this.getSignal(signals)

    logging &&
      log('LOGS signals', {
        sS: shouldSell,
        sB: shouldBuy,
        sSL: shouldStopLoss,
        sSG: shouldStopGain,
        sT: shouldTrailing
      })

    if (setSignal) {
      setSignal({
        interval: this.interval as CandleChartInterval,
        signal: signals.signal,
        symbol: this.symbol
      })
    }

    return signals
  }

  isTimeExpiredOrder(minutes = 0.5) {
    const { minutes: passedMinutes } = Time.timeFrom(this.time)

    return passedMinutes > minutes
  }

  checkIsTimeExpiredOrder() {
    if (this.isNewOrder() && this.isTimeExpiredOrder()) {
      log(LOGS.isTimeExpiredOrder)
      return this.setExpired()
    }
    return this
  }

  checkExecuteOrder({ shouldBuy }: OrderSignals, kline: StreamKline) {
    const { symbol } = this
    const close = Number(kline.close)
    const sameSymbol = kline.symbol === symbol

    if (sameSymbol && this.isNewOrder() && shouldBuy) {
      return this.executeOrder(close)
    }
    return this
  }

  checkCloseOrder(signals: OrderSignals, lastPrice: number, logging = false) {
    if (signals.shouldStopGain) {
      logging && log(LOGS.shouldStopGain, this)
      const price = lastPrice > this.stopGain! ? lastPrice : this.stopGain!
      return this.realizeStopGain(price)
    }

    if (signals.shouldStopLoss) {
      logging && log(LOGS.shouldStopLoss, this)
      return this.realizeStopLoss()
    }

    if (signals.shouldTrailing) {
      return this.updateTrailingTargets(lastPrice, logging)
    }

    if (signals.shouldSell) {
      logging && log(LOGS.shouldSell, this)
      return this.realizePNL(lastPrice)
    }

    if (this.hasNewLastPrice(lastPrice)) {
      return this.updateOrder(lastPrice)
    }

    return this
  }

  checkOrder(
    kline: StreamKline,
    setSignal: (signal: Signal) => void,
    canCheckExpired: boolean,
    logging = true
  ) {
    const close = Number(kline.close)

    if (this.isClosedOrder()) {
      return this
    }

    const signals = this.getOrderSignals(close, setSignal, logging)

    if (canCheckExpired && this.isNewOrder() && this.isTimeExpiredOrder()) {
      return this.setExpired()
    }

    if (this.isSameSymbol(kline.symbol)) {
      if (this.isFilledOrder()) {
        return this.checkCloseOrder(signals, close, logging)
      }

      if (this.isNewOrder()) {
        return this.checkExecuteOrder(signals, kline)
      }
    }

    log('LOGS: checkOrder', 'lastPrice ->', close, this)

    return this
  }
}

export default Order
