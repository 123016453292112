import '../../node_modules/react-grid-layout/css/styles.css'
import '../../node_modules/react-resizable/css/styles.css'

import '@/presentation/styles/global.css'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { NextPage } from 'next'
import { SessionProvider } from 'next-auth/react'
// eslint-disable-next-line import/order
import { AppProps } from 'next/app'
import { ReactElement, ReactNode } from 'react'
import { Toaster } from 'react-hot-toast'

import { NODE_ENV_PROD } from '@/main/constants/env'
import { AppLayout } from '@/presentation/components/layouts'
import { usePageView, useUserLogs } from '@/presentation/hooks'
import { trpc } from '@/utils/trpc'

if (NODE_ENV_PROD) {
  Sentry.init({
    dsn: 'https://3ed66d459efd4902a03533d40aeb54b3@o318442.ingest.sentry.io/4504882546540544',
    integrations: [new BrowserTracing()],
    environment: process.env.NODE_ENV,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0
  })
}

export type LayoutHandler = (page: ReactElement) => ReactNode

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: LayoutHandler
}

export type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const App = ({
  Component,
  pageProps: { session, ...pageProps }
}: AppPropsWithLayout) => {
  usePageView()
  useUserLogs()

  const getLayout: LayoutHandler =
    Component.getLayout ?? (page => <AppLayout>{page}</AppLayout>)

  return (
    <SessionProvider session={session}>
      <Toaster />
      {getLayout(<Component {...pageProps} />)}
    </SessionProvider>
  )
}

export default trpc.withTRPC(App)
