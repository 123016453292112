export const streams = [
  // 'btcusdt@bookTicker',
  `!ticker@arr`
  // `!miniTicker@arr`
  // `btcusdt@kline_1m`,
  // `btcusdt@kline_1d`,
  // `btcusdt@kline_3d`,
  // 'btcusdt@kline_1w',
  // 'btcusdt@depth',
  // 'btcusdt@bookTicker',
  // 'btcusdt@depth@100ms',
  // 'btcusdt@depth@1000ms',
  // 'btcusdt@depth@1000ms',
  // `btcusdt@kline_5m`,
  // `btcusdt@kline_15m`,
  // `btcusdt@kline_1h`,
  // `btcusdt@kline_4h`,
  // `btcusdt@kline_1D`,
  // `btcusdt@kline_1W`,
  // `dogeusdt@kline_4h`,
  // 'btcusdt@kline_1s',
  // 'btcusdt@kline_1m',
  // 'btcusdt@kline_5m',
  // 'btcusdt@kline_15m',
  // 'btcusdt@kline_1h',
  // 'btcusdt@kline_4h',
  // 'btcusdt@kline_1D',
  // 'btcusdt@kline_1S',
  // 'btcusdt@aggTrade'
  // '!bookTicker'
]
