import { KlineSerializer } from '@/presentation/components/contexts/chart/adapters'
import { StreamsSerializer } from '@/presentation/helpers'
import { KlineState, StreamKline } from '@/presentation/protocols'

export class Kline {
  static streamToKlines(stream: StreamKline) {
    return function (state: KlineState) {
      const streamState = StreamsSerializer.serialize(state)

      const latest = StreamsSerializer.latestKline(streamState, stream)
      const { symbol, interval, openTime } = latest

      // Convert the latest StreamKline to the Kline type
      const latestKline = KlineSerializer.toKline(latest)

      // Ensure that the necessary nested structures exist
      if (!state[symbol]) {
        state[symbol] = {}
      }

      if (!state[symbol][interval]) {
        state[symbol][interval] = {}
      }

      state[symbol][interval][openTime] = latestKline

      return state as KlineState
    }
  }
}
