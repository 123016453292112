import { useSession } from 'next-auth/react'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

import { BacktestOrder } from '@/data/adapters/serializers'
import { OrderModel } from '@/domain/models'
import {
  BacktestsIndicators,
  BacktestsIndicatorsData,
  log,
  Order,
  Strategy,
  StrategyStatus_LT
} from '@/presentation/helpers'
import { StreamKline } from '@/presentation/protocols'
import { ReactComponentProps } from '@/presentation/types'
import { trpc } from '@/utils/trpc'

import { Fibonacci } from '../helpers/math'

import { useAccountInfoContext } from './AccountInfo'
import { useKlinesContext } from './klines'
import { useSignalsContext } from './signals'
import { useSymbolContext } from './Symbol'
import { useWebsocketContext } from './Websocket'

export type StrategyState = {
  [symbol: string]: {
    [interval: string]: Strategy
  }
}

export type BacktestsState = {
  [symbol: string]: Order[]
}

export type HistoricalOrdersState = {
  [id: string]: Order
}

export type BacktestsContextType = {
  loading: boolean
  symbols: string[]
  running: boolean
  leverage: number
  strategy: Strategy
  updateStrategies: (strategy: Strategy) => void
  toggleBacktests: () => void
  hasOpenOrders: (symbol?: string) => boolean
  ordersNotSynced: boolean
  backtestsOrders: BacktestsState
  historicalOrders: HistoricalOrdersState | null
  backtestsIndicators: BacktestsIndicatorsData
  getValidBacktestOrders: (symbol: string) => Order[]
  handleCheckOrders: (kline: StreamKline) => void
  addNewHistoricalOrders: (orders: OrderModel[]) => void
}

export const BacktestsContext = createContext<BacktestsContextType>(
  {} as BacktestsContextType
)

export const BacktestsProvider = ({ children }: ReactComponentProps) => {
  const [loading, setLoading] = useState(false)
  const [running, setRunning] = useState<boolean>(false)
  const [leverage, setLeverage] = useState(100)
  const [strategies, setStrategies] = useState<StrategyState | null>(null)
  const [backtestsOrders, setBacktestsOrders] = useState<BacktestsState>(
    {} as BacktestsState
  )
  const [historicalOrders, setHistoricalOrders] =
    useState<HistoricalOrdersState | null>(null)

  const {
    symbol,
    selectedSymbols,
    selectSymbol,
    setSelectedSymbols,
    manualSymbol
  } = useSymbolContext()
  const { interval, lastPrice, streamKline } = useWebsocketContext()
  const { accountTotals } = useAccountInfoContext()
  const { pivotsHighLow } = useKlinesContext()
  const { setSignal } = useSignalsContext()
  const { data: session } = useSession()

  const userId = useMemo(() => session?.user.id ?? '', [session?.user])

  useEffect(() => log({ userId }), [userId])

  useEffect(() => setRunning(false), [symbol, interval])

  useEffect(() => {
    if (loading) return
    handleCheckOrders(streamKline)
  }, [streamKline, loading])

  const backtestAccountTotals = useMemo(() => {
    return Object.values(historicalOrders ?? {})
      .filter(o => o.isProfitableOrder())
      .reduce((total, o) => total + o.getCapital() + o.getProfit(), 0)
  }, [historicalOrders])

  const backtestsIndicators = useMemo(() => {
    return BacktestsIndicators.get(
      Object.values(backtestsOrders ?? {})
        .flat()
        .filter(o => o.isProfitableOrder()),
      backtestAccountTotals
    )
  }, [backtestsOrders, accountTotals])

  const priceLevels = useMemo(
    () =>
      Fibonacci.getSupportFiboLevels(
        [
          'fibo_1000',
          'fibo_0786',
          'fibo_0618',
          'fibo_0500',
          'fibo_0382',
          'fibo_0236',
          'fibo_0000'
          // 'fibo_1272'
          // 'fibo_1414',
          // 'fibo_1618'
        ],
        pivotsHighLow
      ),
    [pivotsHighLow]
  )

  const orders = useMemo(
    () =>
      Strategy.mountPositions({
        symbol,
        interval,
        strategy: `fibo_lvl`,
        side: 'BUY',
        type: 'LIMIT',
        quantityUSDT: 1000,
        leverage: 100,
        initialProfit: 0.01,
        lowStopLoss: 0.01,
        priceLevels,
        userId
      }),
    [symbol, interval, priceLevels, userId]
  )

  const createStrategy = useCallback(
    () =>
      Strategy.create({
        strategy: `fibo_lvl`,
        status: 'IDLE' as StrategyStatus_LT,
        symbol,
        interval,
        leverage,
        side: 'BUY',
        initialProfit: 0.01,
        lowStopLoss: 0.01,
        priceLevels,
        quantityUSDT: 1000,
        orders,
        userId
      }),
    [symbol, loading, interval, leverage, priceLevels, orders, userId]
  )

  const strategy = useMemo(() => {
    if (
      interval &&
      symbol &&
      strategies &&
      strategies[symbol] &&
      strategies[symbol][interval]
    ) {
      return strategies[symbol][interval]
    }

    return createStrategy()
  }, [loading, interval, symbol, strategies, createStrategy])

  useEffect(() => {
    if (loading) return

    updateStrategies(strategy)
  }, [strategy, loading])

  useEffect(() => {
    if (loading) return

    if (strategy instanceof Strategy && strategy?.isIdle()) {
      const updatedStrategy = strategy.setOrders(orders)

      updateStrategies(updatedStrategy)
    }
  }, [loading, orders])

  const updateStrategies = (strategy: Strategy) => {
    setStrategies(prev => ({
      ...prev,
      [symbol]: {
        ...(prev ? prev[symbol] : {}),
        [interval]: strategy
      }
    }))
  }

  const dbOrders = trpc.order.list.useMutation()

  const toggleBacktests = () => setRunning(prev => !prev)

  const openOrders = useMemo(() => {
    return [
      ...Object.values(backtestsOrders).flat(),
      ...Object.values(historicalOrders ?? {})
    ].filter(o => o.status === 'FILLED')
  }, [symbol, backtestsOrders, historicalOrders])

  useEffect(() => {
    if (!manualSymbol && openOrders.length) {
      return selectSymbol(openOrders[0].symbol)
    }
  }, [openOrders])

  const hasOpenOrders = useCallback(
    (_symbol?: string) => {
      return [
        ...(backtestsOrders[_symbol ?? symbol] ?? []),
        ...Object.values(historicalOrders ?? {}).filter(
          o => o.symbol === _symbol ?? symbol
        )
      ].some(o => o.isOpenOrder())
    },
    [symbol, backtestsOrders, historicalOrders]
  )

  const ordersNotSynced = useMemo(() => {
    const historicalCount = Object.values(historicalOrders ?? {}).filter(
      o => o.symbol === symbol
    ).length
    const backtestCount = (backtestsOrders[symbol] ?? []).length

    return (
      (historicalCount === 0 && backtestCount !== 0) ||
      backtestCount !== historicalCount
    )
  }, [symbol, historicalOrders, backtestsOrders])

  const addNewHistoricalOrders = (orders: OrderModel[]) => {
    const newOrders = BacktestOrder.toOrders(orders)
    const newState = BacktestOrder.toState(newOrders)

    setHistoricalOrders(newState)
  }

  const updateHistoricalOrders = (orders: OrderModel[]) => {
    setHistoricalOrders(prev => {
      const updatedOrders = BacktestOrder.toOrders(orders)
      const updatedState = updatedOrders.reduce((updatedOrders, order) => {
        return {
          ...updatedOrders,
          [order.id]: order
        }
      }, prev)

      return updatedState
    })
  }

  const loadHistoricalOrders = () => {
    log({ userId })
    if (userId) {
      dbOrders
        .mutateAsync({
          userId
        })
        .then(orders => {
          const loadedOrders = BacktestOrder.toOrders(orders)
          const loadedState = BacktestOrder.toState(loadedOrders)
          setHistoricalOrders(loadedState)
        })
    }
  }

  useEffect(() => {
    if (!Object.values(historicalOrders ?? {}).length && userId) {
      loadHistoricalOrders()
    }
  }, [userId])

  useEffect(() => {
    if (
      (backtestsOrders[symbol] ?? []).length !==
      Object.values(historicalOrders ?? {})?.length
    ) {
      setBacktestsOrders(prev =>
        Object.values(historicalOrders ?? {})
          // .filter(o => o.isOpen)
          .reduce((state, order) => {
            const symbolState =
              state && state[order.symbol] ? state[order.symbol] : []

            const newState = {
              ...state,
              [order.symbol]: BacktestOrder.toUniqueOrders([
                ...symbolState,
                order
              ])
            }

            return newState
          }, prev)
      )
    }
  }, [historicalOrders])

  useEffect(() => {
    const backtestSymbols = Array.from(
      new Set(
        Object.values(historicalOrders ?? {})
          .filter(o => o.isOpen)
          .map(o => o.symbol)
      )
    )

    if (selectedSymbols.length !== backtestSymbols.length) {
      setSelectedSymbols(prev =>
        Array.from(new Set([...(prev ?? []), ...backtestSymbols]))
      )
    }
  }, [historicalOrders])

  const updateOrders = trpc.order.update.useMutation()

  const publishUpdateOrders = trpc.order.publishUpdate.useMutation()

  // const maxCapitalInPositions = useMemo(() => {
  //   let maxCapitalInPositions = -999999

  //   const allOrders = Object.values(backtestsOrders)
  //     .flat()
  //     .filter(o => o.isClosedOrder())
  //     .sort((a, b) => {
  //       if (a.time > b.time) return 1
  //       if (a.time < b.time) return -1
  //       return 0
  //     })

  //   const oldestOrder = allOrders.reduce(
  //     (oldest, order) => {
  //       if (order.time < oldest.time) return order
  //       return oldest
  //     },
  //     { time: Number.POSITIVE_INFINITY } as Order
  //   )

  //   const newestOrder = allOrders.reduce(
  //     (oldest, order) => {
  //       if (order.time > oldest.time) return order
  //       return oldest
  //     },
  //     { time: Number.NEGATIVE_INFINITY } as Order
  //   )

  //   for (
  //     let i = oldestOrder.time;
  //     i < newestOrder.closeOrderTime!;
  //     i = i + 30000
  //   ) {
  //     const includedOrders = allOrders.filter(
  //       o => i > o.time && i < o.closeOrderTime!
  //     )

  //     const capital = includedOrders.reduce(
  //       (capital, order) => capital + order.getCapital(),
  //       0
  //     )

  //     if (capital > maxCapitalInPositions) {
  //       maxCapitalInPositions = capital
  //     }
  //   }

  //   return maxCapitalInPositions
  // }, [backtestsOrders])

  // useEffect(() => {
  //   log({ maxCapitalInPositions })
  // }, [maxCapitalInPositions])

  const getValidBacktestOrders = useCallback(
    (symbol: string) => {
      const orders = backtestsOrders[symbol] ?? []

      return orders.filter(o => !o.isExpiredOrder())
      // .filter(o => o.isOpenOrder())
      // .filter(o => o.isTodayOrder())
      // .filter(o => o.isWeekOrder())
      // .filter(o => o.isLastHoursOrder(4))
    },
    [backtestsOrders]
  )

  const handleCheckOrders = useCallback(
    (kline: StreamKline) => {
      const validOrders = getValidBacktestOrders(kline.symbol)
      // const hasNoOpenOrders = !validOrders.some(o => o.isOpenOrder())

      // if (hasNoOpenOrders) {
      //   log('hasNoOpenOrders - will not check orders', kline.symbol)
      //   return
      // }

      const canCheckExpired = !validOrders.some(o => o.isFilledOrder())
      const updatedOrders = validOrders.map(o =>
        o.checkOrder(kline, setSignal, canCheckExpired)
      )
      // .filter(o => !o.isNewOrder())

      const highPriorityUpdate = updatedOrders.some(o =>
        o.isUpgradeableOrder(Number(kline.close))
      )
      // log({ highPriorityUpdate })
      if (highPriorityUpdate) setLoading(false)

      if (loading) {
        log('handleCheckOrders (not)', { loading }, kline.symbol)
        return
      }

      // log('STREAM', kline.symbol, kline)

      if (updatedOrders.length) {
        setLoading(true)
        // const highPriorityUpdate = updatedOrders.some(o =>
        //   o.isUpgradeableOrder(Number(kline.close))
        // )
        // log({ highPriorityUpdate })
        // if (highPriorityUpdate) setLoading(true)

        log(updatedOrders)

        // publishUpdateOrders.mutateAsync(updatedOrders)

        // TO ORDER MODEL
        // updateHistoricalOrders(updatedOrders.map(o => o.toOrderModel()))

        console.time('updateOrders')

        updateOrders.mutateAsync(updatedOrders).then(updated => {
          console.timeEnd('updateOrders')
          updateHistoricalOrders(updated)
          setLoading(false)
        })
        setBacktestsOrders(prev => ({
          ...prev,
          [kline.symbol]: updatedOrders
        }))
      }
    },
    [getValidBacktestOrders, setBacktestsOrders]
  )

  return (
    <BacktestsContext.Provider
      value={{
        loading,
        symbols: selectedSymbols,
        running,
        leverage,
        strategy,
        updateStrategies,
        toggleBacktests,
        hasOpenOrders,
        ordersNotSynced,
        backtestsOrders,
        backtestsIndicators,
        historicalOrders,
        getValidBacktestOrders,
        handleCheckOrders,
        addNewHistoricalOrders
      }}
    >
      {children}
    </BacktestsContext.Provider>
  )
}

export const useBacktestsContext = () => useContext(BacktestsContext)
