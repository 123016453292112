import { AggTradeStreamsType, SymbolAggTrade } from '@/presentation/protocols'

export const streamToAggTrade = (
  data: AggTradeStreamsType
): SymbolAggTrade => ({
  eventType: data.e,
  eventTime: Number(data.E),
  symbol: data.s,
  aggregateTradeID: data.a,
  price: Number(data.p),
  quantity: Number(data.q),
  firstTradeID: data.f,
  lastTradeID: data.l,
  tradeTime: Number(data.T),
  isMarketMaker: data.m,
  ignore: data.M,
  total: 0
})
