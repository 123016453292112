import { useState } from 'react'

export const useCheckbox = () => {
  const [checked, setChecked] = useState(false)

  const toggleCheckbox = () => {
    setChecked(!checked)
  }

  return { checked, toggleCheckbox }
}
