import { createContext, useContext, useMemo } from 'react'

import { ReactComponentProps } from '@/presentation/types'

import { useWindowSize } from '../hooks'

export type GridLayoutContextType = {
  cols: number
  rowHeight: number
  width: number
}

export const GridLayoutContext = createContext<GridLayoutContextType>(
  {} as GridLayoutContextType
)

export const GridLayoutProvider = ({ children }: ReactComponentProps) => {
  const { width, height } = useWindowSize()

  const cols = Math.floor(width / 77) // Math.floor(height / 38)
  const rowHeight = Math.floor(height / 60) // 15

  const value = useMemo(() => {
    return {
      cols,
      rowHeight,
      width
    }
  }, [cols, rowHeight, width])

  return (
    <GridLayoutContext.Provider value={value}>
      {children}
    </GridLayoutContext.Provider>
  )
}

export const useGridLayoutContext = () => useContext(GridLayoutContext)

export default GridLayoutProvider
