import { Number } from '@/presentation/helpers'

import { time, timeEnd } from '../log'

export const MS_DAYS = 8.64e7,
  MS_HOURS = 3.6e6,
  MS_MINUTES = 6e4,
  MS_SECONDS = 1e3

export class Time {
  static readonly hasPassedTime = (
    seconds: number,
    lastUpdate: number | null
  ) => {
    if (!lastUpdate) return true

    const now = Date.now()
    const passedTime = Math.abs(now - lastUpdate) / 1000

    return passedTime > seconds
  }

  static readonly withCountTime = (
    logTime: boolean,
    label: string,
    callback: () => void
  ) => {
    logTime ?? time(label)
    callback()
    logTime ?? timeEnd(label)
  }

  static readonly getFractionalTimes = (ms: number) => {
    const [years, yearsMs] = Number.divMod(ms, MS_DAYS * 365)
    const [months, monthsMs] = Number.divMod(yearsMs, MS_DAYS * 30)
    const [days, daysMs] = Number.divMod(monthsMs, MS_DAYS)
    const [hours, hoursMs] = Number.divMod(daysMs, MS_HOURS)
    const [minutes, minutesMs] = Number.divMod(hoursMs, MS_MINUTES)
    const [seconds, milliseconds] = Number.divMod(minutesMs, MS_SECONDS)

    return {
      years,
      months,
      days,
      hours,
      minutes,
      seconds,
      milliseconds
    }
  }

  static readonly timeFrom = (past: number, future: number = Date.now()) => {
    const diff = Math.floor(future - past)

    return this.getFractionalTimes(diff)
  }

  static readonly timeFromString = (
    past: number | null,
    future: number = Date.now()
  ) => {
    if (past) {
      const { years, months, days, hours, minutes, seconds } = Time.timeFrom(
        past,
        future
      )

      if (years) {
        return `${years}y${months}m${days}d`
      }

      if (months) {
        if (days) return `${months}m${days}d`

        return `${months}`
      }

      if (days) {
        if (days > 265) return `${days} days`
      }

      if (hours) {
        return `${hours}h${minutes}m${seconds}s`
      }

      if (minutes) {
        return `${minutes}m${seconds}s`
      }

      return `${seconds}s`
    }

    return '-'
  }
}
