import { ChangeEvent, useState } from 'react'

export const useForm = () => {
  const [values, setValues] = useState({
    email: `firstuser@email.com`,
    password: `123456`,
    confirmation: ``
    // referral: ``,
  })
  const [agree, setAgree] = useState(false)

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }

  return { values, agree, onChange, setAgree: () => setAgree(!agree) }
}
