import { Order } from '@/presentation/types'

export const isNew = (order: Order) => order.status === `NEW`

export const isNotCanceled = (order: Order) => order.status !== `CANCELED`

export const isNotExpired = (order: Order) => order.status !== `EXPIRED`

export const isSold = (order: Order) =>
  order.side === `SELL` && order.status === `FILLED`

export const isFilled = (order: Order) => order.status === `FILLED`

export const isFinished = (symbol: string, internalOrders = []) =>
  !internalOrders
    .filter((order: Order) => order.symbol === symbol)
    .find((order: Order) => isNew(order) || isFilled(order))

export const isProfitable = (order: Order, lastPrice: number) =>
  lastPrice && order.status === `FILLED` && order.side === `BUY`

export const isNewLimitBuy = (order: Order) =>
  order.status === `NEW` && order.side === `BUY`

export const isNotLastXRPWithdraw = (order: Order) =>
  order.orderId !== 700138641

export const isNotSold = (order: Order, allOrders: Order[]) => {
  if (allOrders?.length) {
    const sold = allOrders.find(o => {
      const isSold = o.side === `SELL`
      const isFilled = o.status === `FILLED`
      const isEqualQuantity = o.executedQty === order.executedQty
      // this logic need to be fixed
      if (
        order.side === `BUY` &&
        order.symbol === `OMGUSDT` &&
        order.type === `MARKET`
      )
        return false
      return isSold && isFilled && isEqualQuantity
    })
    return !sold
  }
  return true
}
