export type TradingType_LT = 'MARGIN' | 'SPOT'

export interface AssetBalance {
  asset: string
  free: string
  locked: string
}

export const enum TradingType {
  MARGIN = 'MARGIN',
  SPOT = 'SPOT'
}

export interface BinanceAccountInfo {
  accountType: TradingType.MARGIN | TradingType.SPOT
  balances: AssetBalance[]
  buyerCommission: number
  canDeposit: boolean
  canTrade: boolean
  canWithdraw: boolean
  makerCommission: number
  permissions: TradingType_LT[]
  sellerCommission: number
  takerCommission: number
  updateTime: number
}
