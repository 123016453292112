import { faker } from '@faker-js/faker'

import { BinanceKline } from '@/domain/models'
import {
  Kline,
  KlineState,
  StreamKline,
  StreamKlineState
} from '@/presentation/protocols'
import { theme } from '@/presentation/styles/theme'

import { toTimeSplitted } from './helpers'
import { KlineChartState } from './protocols'

export class KlineSerializer {
  static fromEntries<T>(state: Array<[string, T]>) {
    return Object.fromEntries(state)
  }

  static candlestickData({
    symbol,
    interval,
    openTime,
    open,
    high,
    low,
    close,
    volume,
    closeTime,
    quoteVolume,
    trades,
    baseAssetVolume,
    quoteAssetVolume
  }: BinanceKline) {
    return {
      symbol,
      interval,
      openTime: Number(openTime),
      open: Number(open),
      high: Number(high),
      low: Number(low),
      close: Number(close),
      volume: Number(volume),
      closeTime: Number(closeTime),
      quoteVolume: Number(quoteVolume),
      trades: Number(trades),
      baseAssetVolume: Number(baseAssetVolume),
      quoteAssetVolume: Number(quoteAssetVolume)
    }
  }

  static orderedKlinesByOpenTime(klines: Kline[]) {
    return klines.sort((a, b) =>
      a.openTime < b.openTime ? 1 : a.openTime > b.openTime ? -1 : 0
    )
  }

  static stateFromData(data: BinanceKline[]) {
    return (prev: KlineState) => {
      const newState = data.reduce(
        (state: KlineState, candle) => {
          const { openTime, symbol, interval } = candle

          // Ensure that the necessary nested structures exist
          if (!state[symbol]) {
            state[symbol] = {}
          }
          if (!state[symbol][interval]) {
            state[symbol][interval] = {}
          }

          // Update or add the candlestick data
          state[symbol][interval][openTime] = {
            ...(state[symbol][interval][openTime] || {}),
            ...this.candlestickData(candle) // Perform the necessary data processing
          }

          return state
        },
        { ...prev }
      )

      return newState
    }
  }

  static toKline(candlestick: StreamKline) {
    return {
      openTime: candlestick.openTime,
      closeTime: candlestick.closeTime,
      symbol: candlestick.symbol,
      interval: candlestick.interval,
      open: Number(candlestick.open),
      close: Number(candlestick.close),
      high: Number(candlestick.high),
      low: Number(candlestick.low),
      trades: candlestick.trades,
      baseAssetVolume: Number(candlestick.baseAssetVolume),
      quoteAssetVolume: Number(candlestick.quoteAssetVolume),
      volume: 0,
      quoteVolume: 0
    } as Kline
  }

  static toStreamKline(candlestick: Kline): StreamKline {
    return {
      openTime: candlestick.openTime,
      closeTime: candlestick.closeTime,
      symbol: candlestick.symbol,
      interval: candlestick.interval,
      firstTradeID: faker.datatype.number(),
      lastTradeID: faker.datatype.number(),
      open: candlestick.open.toString(),
      close: candlestick.close.toString(),
      high: candlestick.high.toString(),
      low: candlestick.low.toString(),
      baseAssetVolume: candlestick.baseAssetVolume.toString(),
      trades: candlestick.trades,
      isClosed: !!candlestick.closeTime,
      takerQuoteAssetVolume: '',
      takerBaseAssetVolume: '',
      quoteAssetVolume: candlestick.quoteAssetVolume.toString(),
      ignore: 'true'
    } as StreamKline
  }

  // SERIALIZATION
  static serialize(data: BinanceKline[]): Kline[] {
    return data.map(candle => this.candlestickData(candle))
  }

  // CHARTS
  static toLineChart(
    source: string,
    klines: KlineChartState,
    { symbol, interval }: StreamKline
  ) {
    const intervals = klines[symbol]
    const _candlesticks = intervals
      ? Object.values(intervals[interval] ?? {})
          .sort((a, b) =>
            a.openTime < b.openTime ? 1 : a.openTime > b.openTime ? -1 : 0
          )
          .reverse()
          .slice(0, 100)
      : []

    return {
      labels: Object.values(_candlesticks).map(({ openTime }) => {
        const date = new Date(openTime)
        const [hour, min, sec, mil] = toTimeSplitted(date)

        const dateFormatted = date.toLocaleTimeString()

        return mil === 0 ? dateFormatted : ''
      }),
      datasets: [
        {
          label: `${symbol} - ${interval}`,
          data: _candlesticks.map(candle =>
            Number(candle[source as keyof typeof candle])
          ),
          borderColor: theme.colors.main.primaryColor,
          backgroundColor: theme.colors.main.contrastColor
        }
      ]
    }
  }

  static toKlineState(
    candlesticks: KlineState | StreamKlineState | null,
    { symbol, interval }: StreamKline
  ): KlineChartState {
    if (!candlesticks?.[symbol]?.[interval]) {
      return {}
    }

    const candlestickState = {
      ...candlesticks,
      [symbol]: {
        ...candlesticks[symbol],
        [interval]: Object.values(candlesticks[symbol][interval])
          .slice(-200)
          .reduce((state: KlineChartState, c: Kline | StreamKline) => {
            return {
              ...state,
              [c.openTime]: {
                openTime: c.openTime,
                open: c.open,
                high: c.high,
                low: c.low,
                close: c.close
              }
            }
          }, {} as KlineChartState)
      }
    }

    return candlestickState as KlineChartState
  }

  static toKlineChart(
    klines: KlineChartState,
    { symbol, interval }: StreamKline
  ) {
    const intervals = klines ? klines[symbol] : {}
    const _candlesticks = intervals
      ? Object.values(intervals[interval] ?? {})
      : // .sort((a, b) =>
        //   a.openTime < b.openTime ? 1 : a.openTime > b.openTime ? -1 : 0
        // )
        // .reverse()
        // .slice(0, 100)
        []

    if (!symbol || !interval) return null

    return {
      labels: _candlesticks.map(({ openTime }) => Number(openTime)),
      datasets: [
        {
          label: `${symbol} - ${interval}`,
          data: _candlesticks.map(({ openTime, open, high, low, close }) => {
            return {
              x: Number(openTime),
              o: Number(open),
              h: Number(high),
              l: Number(low),
              c: Number(close)
            }
          }),
          color: {
            up: theme.colors.main.primaryColor,
            down: theme.colors.main.contrastColor,
            unchanged: theme.colors.main.primaryColor
          },
          fillColor: theme.colors.main.primaryColor,
          borderColor: theme.colors.main.primaryColor,
          backgroundColor: theme.colors.main.primaryColor
        }
      ]
    }
  }
}
