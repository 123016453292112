export class Streams {
  static join(streams: string[], separator = '/') {
    return this.toUnique(streams).join(separator)
  }

  static toUnique(symbols: string[]) {
    return Array.from(new Set(symbols))
  }

  static mergeState(newStreams: string[]) {
    return (prev: string[]) => {
      return this.toUnique([...prev, ...newStreams])
    }
  }

  static sort(streams: string[]) {
    return streams.sort((a, b) => (a < b ? 1 : -1))
  }

  static fromSymbols(symbols: string[], interval: string) {
    return symbols
      .map(symbol => {
        return [
          `${symbol.toLowerCase()}@kline_${interval}`
          // `${symbol.toLowerCase()}@depth`
          // `${symbol.toLowerCase()}@ticker`
        ]
      })
      .flat()
  }
}
