export type PartialBinanceKline = Pick<BinanceKline, 'symbol' | 'interval'>

export type BinanceRawKline = [
  string,
  BinanceKlinesInterval,
  number,
  string,
  string,
  string,
  string,
  string,
  number,
  string,
  number,
  string,
  string
]

export interface BinanceKline {
  symbol: string
  interval: BinanceKlinesInterval
  openTime: number
  open: number
  high: number
  low: number
  close: number
  volume: number
  closeTime: number
  quoteVolume: number
  trades: number
  baseAssetVolume: number
  quoteAssetVolume: number
}

export const enum BinanceKlinesInterval {
  ONE_SECOND = '1s',
  ONE_MINUTE = '1m',
  THREE_MINUTES = '3m',
  FIVE_MINUTES = '5m',
  FIFTEEN_MINUTES = '15m',
  THIRTY_MINUTES = '30m',
  ONE_HOUR = '1h',
  TWO_HOURS = '2h',
  FOUR_HOURS = '4h',
  SIX_HOURS = '6h',
  EIGHT_HOURS = '8h',
  TWELVE_HOURS = '12h',
  ONE_DAY = '1d',
  THREE_DAYS = '3d',
  ONE_WEEK = '1w',
  ONE_MONTH = '1M'
}

const mockKLine: BinanceKline = {
  symbol: 'BTCUSDT',
  interval: BinanceKlinesInterval.ONE_DAY,
  openTime: 1612137600000,
  open: 37915.1,
  high: 38150,
  low: 37000,
  close: 37500,
  volume: 100,
  closeTime: 1612223999999,
  quoteVolume: 100,
  trades: 100,
  baseAssetVolume: 100,
  quoteAssetVolume: 100
}
