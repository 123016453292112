import { faker } from '@faker-js/faker'
import {
  OrderSide_LT,
  OrderStatus_LT,
  OrderType_LT,
  TimeInForce_LT
} from 'binance-api-node'

import { OrderModel } from '@/domain/models'
import { BacktestsState } from '@/presentation/contexts/Backtests'
import {
  Order,
  OrderParams,
  NumberFormatter,
  Time
} from '@/presentation/helpers'
import {
  ViewBacktestOrder,
  ViewOrder,
  ViewOrdersData
} from '@/presentation/protocols'

export class BacktestOrder {
  static toState(data: Order[]) {
    return data.reduce((state, order) => {
      return {
        ...state,
        [order.id]: order
      }
    }, {} as Record<string, Order>)
  }

  static toUniqueOrders = (data: Order[]): Order[] => {
    const state = data.reduce((state, order) => {
      return {
        ...state,
        [order.id]: order
      }
    }, {} as Record<string, Order>)

    return Object.values(state)
  }

  static toOrder = (order: OrderModel) => {
    const newOrder = Order.from(order as OrderParams)

    newOrder.reload(order)

    return newOrder
  }

  static serialize = (data: OrderModel[]) => {}

  static toOrders = (data: OrderModel[]) => {
    return data.map(order => this.toOrder(order))
  }

  static toOrdersState = (data: OrderModel[]) => {
    return data.reduce((state, order) => {
      return {
        ...(state ?? {}),
        [order.id]: order
      }
    }, {} as Record<string, OrderModel>)
  }

  static toBacktestOrders = (data: Order[]) => {
    return data.map(o => {
      return {
        clientOrderId: faker.datatype.uuid(),
        cummulativeQuoteQty: o.getCapital().toString(),
        executedQty: o.getCapital().toString(),
        icebergQty: '',
        isWorking: true,
        orderId: faker.datatype.number(100000),
        orderListId: faker.datatype.number(100000),
        origQty: o.getQuantity().toString(),
        origQuoteOrderQty: o.getQuantity().toString(),
        price: o.buyPrice?.toString() ?? null,
        side: o.side as OrderSide_LT,
        status: o.status as OrderStatus_LT,
        stopPrice: o.stopLossSellPrice!.toString(),
        symbol: o.symbol,
        time: o.time,
        timeInForce: '' as TimeInForce_LT,
        type: o.type as OrderType_LT,
        timeUpdate: o.timeUpdate
      }
    })
  }

  static filterOrdersByField = (orders: Order[], field: keyof Order) => {
    return orders.sort((a, b) =>
      Number(a[field]) < Number(b[field])
        ? 1
        : Number(a[field]) > Number(b[field])
        ? -1
        : 0
    )
  }

  static sortOrdersByField = (orders: Order[], field: keyof Order) => {
    return orders.sort((a: Order, b: Order) => {
      if (Number(a[field]) < Number(b[field])) return -1
      if (Number(a[field]) > Number(b[field])) return 1
      return 0
    })
  }

  static sortOrdersByPriceLevel = (orders: Order[]) => {
    return this.sortOrdersByField(orders, 'price')
  }

  static toAllBacktestOrdersBySide = (
    state: BacktestsState,
    side: OrderSide_LT | 'SOLD' | 'STOPPED'
  ) => {
    const orders = Object.values(state)
      .flat()
      .filter(o => o.side === side)

    return BacktestOrder.filterOrdersByField(orders, 'symbol')
  }

  static toAllBacktestOrdersByStatus = (
    state: BacktestsState,
    status: OrderStatus_LT | 'SOLD' | 'STOPPED'
  ) => {
    const orders = Object.values(state)
      .flat()
      .filter(o => o.status === status)

    return BacktestOrder.filterOrdersByField(orders, 'symbol')
  }

  static toViewAllBacktestOrdersBySide = (
    state: BacktestsState,
    side: OrderSide_LT | 'SOLD' | 'STOPPED'
  ) => {
    const orders = Object.values(state)
      .flat()
      .filter(o => o.side === side)

    return this.toViewBacktestOrders(orders, 'symbol')
  }

  static toViewAllBacktestOrdersByStatus = (
    state: BacktestsState,
    status: OrderStatus_LT | 'SOLD' | 'STOPPED'
  ) => {
    const orders = Object.values(state)
      .flat()
      .filter(o => o.status === status)

    return this.toViewBacktestOrders(orders, 'symbol')
  }

  static toViewBacktestOrders = (
    data: Order[],
    field?: keyof Order
  ): ViewBacktestOrder[] => {
    return BacktestOrder.filterOrdersByField(data, field ?? 'price').map(
      order => {
        return {
          symbol: order.symbol,
          id: faker.datatype.uuid(),
          label: order.label,
          capital: order.getCapital(),
          quantity: order.getQuantity(),
          price: order.price,
          level: order.level ?? '-',
          buyPrice: order.buyPrice,
          sellPrice: order.sellPrice,
          stopPrice: order.stopLossSellPrice!,
          stopGain: order.stopGain!,
          lastPrice: order.lastPrice ?? 0,

          profit: order.profit ?? 0,
          profitPercent: order.getProfitPercent() ?? 0 * 100,

          priceF: NumberFormatter.toPrice(order.price, 5),
          capitalF: NumberFormatter.toPrice(order.getCapital(), 5),
          quantityF: NumberFormatter.toPrice(order.getQuantity(), 5),
          buyPriceF: NumberFormatter.toPrice(order.buyPrice ?? 0, 5),
          sellPriceF: NumberFormatter.toPrice(order.sellPrice, 5),
          stopPriceF: NumberFormatter.toPrice(order.stopLossSellPrice, 5),
          stopGainF: NumberFormatter.toPrice(order.stopGain),
          lastPriceF: NumberFormatter.toPrice(order.lastPrice ?? 0, 5),

          profitF: NumberFormatter.toPrice(order.profit ?? 0, 5),
          profitPercentF: NumberFormatter.toPercentFormatted(
            order.getProfitPercent() ?? 0 * 100,
            4
          ),

          time: order.time,
          updateTime: order.timeUpdate ?? 0,
          closeOrderTime: order.closeOrderTime ?? null,

          timeF: new Date(order.time).toLocaleTimeString(),
          updateTimeF: new Date(order.timeUpdate ?? 0).toLocaleDateString(),

          side: order.side as OrderSide_LT,
          status: order.status as OrderStatus_LT,
          type: order.type as OrderType_LT
        }
      }
    )
  }

  static getTimeFrom = (order: Order) => {
    if (order.closeOrderTime) {
      const { hours, minutes, seconds } = Time.timeFrom(
        order.time,
        order.closeOrderTime
      )

      if (hours) {
        return `${hours}h${minutes}m${seconds}s`
      }

      if (minutes) {
        return `${minutes}m${seconds}s`
      }

      return `${seconds}s`
    }

    const { hours, minutes, seconds } = Time.timeFrom(order.time ?? 0)

    return `${hours}h${minutes}m${seconds}s`
  }

  static toViewOrder = (data: Order[], field?: keyof Order): ViewOrder[] => {
    return BacktestOrder.filterOrdersByField(data, field ?? 'price').map(
      order => {
        return {
          date: `${new Date(order.time).toLocaleDateString()} ${new Date(
            order.time
          ).toLocaleTimeString()}`,
          symbol: order.symbol,
          price: NumberFormatter.toPrice(order.price ?? order.buyPrice, 5),
          level: order.level ?? '-',
          side: order.side as OrderSide_LT,
          qty: NumberFormatter.toPrice(order.getQuantity(), 5),
          capital: NumberFormatter.toPrice(order.getCapital(), 5),
          'buy price': NumberFormatter.toPrice(order.buyPrice ?? 0, 5),
          'sell price': NumberFormatter.toPrice(order.sellPrice, 5),
          'stop price': NumberFormatter.toPrice(order.stopLossSellPrice, 5),
          'stop gain': NumberFormatter.toPrice(order.stopGain),
          'last price': NumberFormatter.toPrice(order.lastPrice ?? 0, 5),
          status: order.status as OrderStatus_LT,
          'profit ($)': NumberFormatter.toPrice(order.profit ?? 0, 5),
          'profit (%)': NumberFormatter.toPercentFormatted(
            order.getProfitPercent() ?? 0 * 100,
            4
          )
        }
      }
    )
  }

  static toViewOrders = (
    data: Order[],
    field?: keyof Order
  ): ViewOrdersData => {
    return {
      view: this.toViewOrder(data, field),
      data: this.toViewBacktestOrders(data, field)
    }
  }

  static toViewTotalProfit(orders: Order[]): string {
    const totalProfit = orders.reduce((total: number, order: Order) => {
      return total + (order.profit ?? 0)
    }, 0)

    return NumberFormatter.toPrice(totalProfit)
  }

  static toViewTotalProfitPercent(orders: Order[]): number {
    const totalProfit = orders.reduce((total: number, order: Order) => {
      return total + (order.profit ?? 0)
    }, 0)

    const totalCapital = orders
      .filter(o => o.isProfitableOrder())
      .reduce((total: number, order: Order) => {
        return total + (order.getCapital() ?? 0)
      }, 0)

    return totalCapital ? totalProfit / totalCapital : 0
  }

  static toViewTotalProfitPercentF(orders: Order[]): string {
    return NumberFormatter.toPercentFormatted(
      this.toViewTotalProfitPercent(orders) * 100,
      4
    )
  }

  static toViewTotalCapital(orders: Order[]): number {
    const totalCapital = (orders ?? [])
      .filter(o => o.isProfitableOrder())
      .reduce((total: number, order: Order) => {
        return total + (order.getCapital() ?? 0)
      }, 0)

    return totalCapital
  }

  static toViewBacktestData(orders: Order[]) {
    const _orders = orders.filter(o => o.isTodayOrder())

    return {
      buyOrders: _orders,
      buyOrdersView: Object.values(
        this.toViewBacktestOrders(
          _orders
          // .filter(o => o.isOpenOrder() || o.isIdleOrder())
        )
      ),
      totalCapital: this.toViewTotalCapital(_orders),
      profit: this.toViewTotalProfit(_orders),
      profitP: this.toViewTotalProfitPercentF(_orders)
    }
  }
}
