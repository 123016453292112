import { theme } from '@/presentation/styles/theme'

export class Color {
  static fromSide<T extends {}>(order: T) {
    return order['side' as keyof T] === `SELL`
      ? theme.colors.order.sell
      : theme.colors.order.buy
  }

  static fromStatus<T extends {}>(order: T) {
    const STATUS_COLOR = {
      FILLED: theme.colors.order.filled,
      SOLD: theme.colors.order.sold,
      STOPPED: theme.colors.order.stopped,
      IDLE: theme.colors.order.idle,
      NEW: theme.colors.order.new,
      CANCELED: theme.colors.order.default,
      EXPIRED: theme.colors.order.default,
      PARTIALLY_FILLED: theme.colors.order.default,
      PENDING_CANCEL: theme.colors.order.default,
      REJECTED: theme.colors.order.default
    }

    return STATUS_COLOR[order['status' as keyof T] as keyof typeof STATUS_COLOR]
  }

  static fromProfit<T extends {}>(
    order: T,
    profitField: keyof T = 'profit' as keyof T
  ) {
    const profit = Number(order[profitField])

    if (isNaN(profit)) {
      return theme.colors.market.idle
    }

    return profit > 0
      ? theme.colors.market.gainColor
      : theme.colors.market.lossColor
  }

  static fromPercentChange(price: number | string | null, buyPrice: number) {
    return Number(price) > buyPrice
      ? theme.colors.market.gainColor
      : theme.colors.market.lossColor
  }

  static fromValue(value: number) {
    if (value === 0) return theme.colors.market.idle

    return value > 0
      ? theme.colors.market.gainColor
      : theme.colors.market.lossColor
  }
}
