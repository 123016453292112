import { PivotsHighLow } from '@/presentation/protocols'

import { Currency } from './currency'

export type FiboPriceLevels = {
  // mkt_price: number | null
  fibo_0000: number
  fibo_0236: number
  fibo_0382: number
  fibo_0500: number
  fibo_0618: number
  fibo_0786: number
  fibo_1000: number
  fibo_1272: number
  fibo_1414: number
  fibo_1618: number
}

export enum HiddenPriceLevels {
  hidden_1059 = 1.059,
  hidden_1000 = 1.0,
  hidden_0970 = 0.97,
  hidden_0941 = 0.941,
  hidden_0911 = 0.911,
  hidden_0882 = 0.882,
  hidden_0841 = 0.841,
  hidden_0800 = 0.8,
  hidden_075 = 0.75,
  hidden_0702 = 0.702,
  hidden_0618 = 0.618,
  hidden_0559 = 0.559,
  hidden_0500 = 0.5,
  hidden_0441 = 0.441,
  hidden_0382 = 0.382,
  hidden_0309 = 0.309,
  hidden_0236 = 0.236,
  hidden_0177 = 0.177,
  hidden_0118 = 0.118,
  hidden_0059 = 0.059,
  hidden_0000 = 0.0,
  hidden__1059_ = -1.0591
}

export type PartialFiboLevels = Partial<FiboPriceLevels>

export const FIBO_PRICE_LEVELS: Record<string, string> = {
  fibo_0000: '0.000',
  fibo_0236: '0.236',
  fibo_0382: '0.382',
  fibo_0500: '0.500',
  fibo_0618: '0.618',
  fibo_0786: '0.786',
  fibo_1000: '1.000',
  fibo_1272: '1.272',
  fibo_1414: '1.414',
  fibo_1618: '1.618'
}

export class Fibonacci {
  static getFiboLevelByLabel(label: string) {
    const [digit, ...decimals] = Array.from((label ?? '').replace('fibo_', ''))
    const strDecimals = decimals.toString().replaceAll(',', '')
    return Number(`${digit}.${strDecimals}`)
  }

  static getFiboPriceLevels(pivotsHighLow: PivotsHighLow): FiboPriceLevels {
    const HH = Number(pivotsHighLow.hh)
    const LL = Number(pivotsHighLow.ll)

    const fibo_1000 = Currency.logRound(+HH)
    const fibo_0000 = Currency.logRound(+LL)
    const fibo_1618 = Currency.logRound(+LL - (HH - LL) * 0.618)
    const fibo_1414 = Currency.logRound(+LL - (HH - LL) * 0.414)
    const fibo_1272 = Currency.logRound(+LL - (HH - LL) * 0.272)
    const fibo_0236 = Currency.logRound(+HH - (HH - LL) * (1 - 0.236))
    const fibo_0382 = Currency.logRound(+HH - (HH - LL) * (1 - 0.382))
    const fibo_0500 = Currency.logRound(+HH - (HH - LL) * (1 - 0.5))
    const fibo_0618 = Currency.logRound(+HH - (HH - LL) * (1 - 0.618))
    const fibo_0786 = Currency.logRound(+HH - (HH - LL) * (1 - 0.786))
    const levels = {
      fibo_1000,
      fibo_0786,
      fibo_0618,
      fibo_0500,
      fibo_0382,
      fibo_0236,
      fibo_0000,
      fibo_1272,
      fibo_1414,
      fibo_1618
    }

    return levels
  }

  static getSupportFiboLevels(
    levels: (keyof PartialFiboLevels)[],
    pivotsHighLow: PivotsHighLow
  ): PartialFiboLevels {
    const priceLevels = this.getFiboPriceLevels(pivotsHighLow)

    return Object.fromEntries(
      levels.map(level => {
        return [level, priceLevels[level]]
      })
    )
  }

  static getPriceLevel(price: number, levels: FiboPriceLevels) {
    const arrLevels = Object.keys(levels)
    const arrLevelPrices = Object.values(levels)
    const lastPriceIndex = arrLevelPrices.findIndex(
      (currentLevelPrice, index) => {
        const lastIndex = 0
        const nextIndex = index - 1
        const nextLevelPrice = arrLevelPrices[nextIndex]
        const lastLevelPrice = arrLevelPrices.at(0) ?? arrLevelPrices[index]
        const insideLevels = nextIndex >= lastIndex

        if (price) {
          if (insideLevels) {
            return price > currentLevelPrice && price < nextLevelPrice
          }
          return price >= lastLevelPrice
        }
        return false
      }
    )

    const priceFiboLevelLabel = arrLevels[lastPriceIndex]
    const fiboLevelByLabel = this.getFiboLevelByLabel(priceFiboLevelLabel)

    return {
      priceLevel: fiboLevelByLabel ?? 0,
      priceLevelF: FIBO_PRICE_LEVELS[priceFiboLevelLabel] ?? '0.000'
    }
  }
}
