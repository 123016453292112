import { QueryOrderResult } from 'binance-api-node'
import _ from 'lodash'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

import { ExchangeOrder } from '@/data/adapters/serializers'
import { Order, ReactComponentProps } from '@/presentation/types'
import { requestOrders } from '@/presentation/useCases/loadOrders'
import { accountBalancesFrom } from '@/services/broker/account'
import {
  isNotCanceled,
  isNotExpired,
  isNotLastXRPWithdraw
} from '@/services/broker/order/order'

import { log } from '../helpers'
import { AccountBalances, SymbolsCurrency, SymbolsProfit } from '../protocols'

import { useAccountInfoContext } from './AccountInfo'
import { useWebsocketContext } from './Websocket'

export type OrdersContextType = {
  loading: boolean
  allOrders: QueryOrderResult[]
  filledOrders: QueryOrderResult[]
  filteredOrders: QueryOrderResult[]
  symbolsCapitals: SymbolsCurrency
  symbolsMidPrices: SymbolsCurrency
  symbolsProfit: SymbolsCurrency
  symbolsProfitPercent: SymbolsProfit
  ordersBalances: SymbolsCurrency
  isNotSold: (order: QueryOrderResult) => boolean
  isProfitable: (order: QueryOrderResult) => boolean
  ordersBuyPrice: SymbolsCurrency
  getSymbolNewOrders: (symbol: string) => QueryOrderResult[] | undefined
}

export const OrdersContext = createContext<OrdersContextType>({
  loading: false,
  allOrders: [] as QueryOrderResult[],
  filledOrders: [] as QueryOrderResult[],
  filteredOrders: [] as QueryOrderResult[],
  symbolsCapitals: {} as SymbolsCurrency,
  symbolsMidPrices: {} as SymbolsCurrency,
  symbolsProfit: {} as SymbolsCurrency,
  symbolsProfitPercent: {} as SymbolsProfit,
  ordersBalances: {} as SymbolsCurrency,
  isNotSold: () => false,
  isProfitable: () => false,
  ordersBuyPrice: {} as SymbolsCurrency,
  getSymbolNewOrders: () => undefined
})

export const OrdersProvider = ({ children }: ReactComponentProps) => {
  const [loading, setLoading] = useState(false)
  const [symbolsCapitals, setSymbolsCapitals] = useState({} as SymbolsCurrency)
  const [symbolsMidPrices, setSymbolsMidPrices] = useState(
    {} as SymbolsCurrency
  )
  const [symbolsProfitPercent, setSymbolsProfitPercent] = useState(
    {} as SymbolsProfit
  )
  const [symbolsProfit, setSymbolsProfit] = useState({} as SymbolsCurrency)
  const [lastOrdersUpdate, setLastOrdersUpdate] = useState<number>(Date.now())
  const [allOrders, setAllOrders] = useState([] as Order[])
  const [filteredOrders, setFilteredOrders] = useState([] as Order[])
  const [error, setError] = useState<Error>()
  const [ordersBalances, setAccountBalances] = useState({} as AccountBalances)
  const { hasBalances, balancesSymbols, symbolsBalances, symbolHasBalances } =
    useAccountInfoContext()
  const { ticker, getLastPrice } = useWebsocketContext()

  const filledOrders = useMemo(() => {
    return allOrders.filter(order => order.status === 'FILLED') ?? []
  }, [allOrders])

  const shouldUpdateOrdersBySeconds = useCallback(
    (minutes: number) => {
      const now = Date.now()
      const shouldUpdate =
        Math.abs(now - lastOrdersUpdate) / 1000 > minutes &&
        balancesSymbols.length &&
        !loading
      if (shouldUpdate) setLastOrdersUpdate(Date.now())
      log(`should update orders...`, shouldUpdate)
      return shouldUpdate
    },
    [balancesSymbols, lastOrdersUpdate, loading]
  )

  const isProfitable = (order: Order) =>
    !!(
      getLastPrice(order.symbol) &&
      order.status === `FILLED` &&
      order.side === `BUY`
    )

  const isNotSold = (order: Order) => {
    if (allOrders && allOrders.length) {
      const sold = allOrders.find(o => {
        const isSell = o.side === `SELL`
        const isFilled = o.status === `FILLED`
        const isEqualQuantity = o.executedQty === order.executedQty
        // TODO this logic need to be fixed
        if (
          order.side === `BUY` &&
          order.symbol === `OMGUSDT` &&
          order.type === `MARKET`
        )
          return false
        return isSell && isFilled && isEqualQuantity
      })
      return !sold
    }
    return true
  }

  const isSold = (order: Order) =>
    order.side === `SELL` && order.status === `FILLED`

  const getCapitalUSDT = (order: Order) => {
    const { symbol, executedQty } = order

    const lastPrice = getLastPrice(symbol)
    const buyPrice = ExchangeOrder.getBuyPrice(order)

    const capital =
      lastPrice && buyPrice
        ? (+executedQty * lastPrice) / (lastPrice / buyPrice)
        : null

    return capital
  }

  const getAllOrders = () => {
    setLoading(true)
    requestOrders({ balancesSymbols: [...balancesSymbols, 'USDTBRL'] })
      .then(({ data: allOrders }) =>
        setAllOrders(
          allOrders.map(o => {
            if (Number(o.price)) return o

            return {
              ...o,
              price: (
                Number(o.cummulativeQuoteQty) / Number(o.executedQty)
              ).toString()
            }
          })
        )
      )
      .catch(err => setError(err))
      .finally(() => setLoading(false))
  }

  const getFilteredOrders = () => {
    const newFilteredOrders = allOrders
      .filter(isProfitable)
      .filter(isNotSold)
      .filter(isNotLastXRPWithdraw)
      .filter(isNotCanceled)
      .filter(isNotExpired)
      .filter(symbolHasBalances)

    if (!_.isEqual(filteredOrders, newFilteredOrders)) {
      setFilteredOrders(newFilteredOrders)
    }
  }

  const getSymbolNewOrders = useCallback(
    (symbol: string) => {
      if (allOrders.length) {
        return allOrders
          .filter(o => o.status === 'NEW')
          .filter(o => o.symbol === symbol)
      }

      return undefined
    },
    [allOrders]
  )

  const ordersBuyPrice = useMemo(() => {
    return allOrders.reduce(
      (ordersBuyPrices: SymbolsCurrency, order: Order) => {
        return {
          ...ordersBuyPrices,
          [order.clientOrderId]: ExchangeOrder.getBuyPrice(order)
        }
      },
      {} as SymbolsCurrency
    )
  }, [allOrders])

  const getSymbolsCapitals = () => {
    const newSymbolsCapital = balancesSymbols.reduce((capitals, symbol) => {
      const symbolOrders = filteredOrders.filter(
        order => order.symbol === symbol
      )

      if (!symbolOrders.length && symbol === `TWTUSDT`) {
        return {
          ...capitals,
          [symbol]: symbolsBalances[symbol] * 0.09825
        }
      }

      if (!symbolOrders.length && symbol === `BTTCUSDT`) {
        return {
          ...capitals,
          [symbol]: symbolsBalances[symbol] * 0.00000195
        }
      }

      const symbolCapital = symbolOrders.reduce((capital, order: Order) => {
        const capitalUSDT = getCapitalUSDT(order)

        if (capitalUSDT) {
          return capital + capitalUSDT
        }
        return capital
      }, 0)

      return {
        ...capitals,
        [symbol]: symbolCapital ?? 0
      }
    }, {})

    setSymbolsCapitals(newSymbolsCapital)
  }

  const getSymbolsMidPrices = () => {
    const newSymbolsMidPrices = balancesSymbols.reduce(
      (midPrices: SymbolsCurrency, symbol: string) => {
        const symbolMidPrice =
          symbolsCapitals[symbol] && symbolsBalances[symbol]
            ? symbolsCapitals[symbol] / symbolsBalances[symbol]
            : 0

        return {
          ...midPrices,
          [symbol]: symbolMidPrice
        }
      },
      {}
    )

    if (!_.isEqual(newSymbolsMidPrices, symbolsMidPrices)) {
      setSymbolsMidPrices(newSymbolsMidPrices)
    }
  }

  const getMidPriceProfit = (symbol: string) => {
    const lastPrice = getLastPrice(symbol)
    const balance = symbolsBalances[symbol]
    const midPrice = symbolsMidPrices[symbol]
    return lastPrice * balance - midPrice * balance
  }

  const getOrdersBalances = () => {
    if (hasBalances) {
      const newAccountBalances = accountBalancesFrom(
        symbolsCapitals,
        symbolsProfit
      )
      setAccountBalances(newAccountBalances)
    }
  }

  const getSymbolsProfit = () => {
    const newSymbolsProfit = balancesSymbols.reduce(
      (symbolsProfit: SymbolsProfit, symbol: string) => {
        const symbolProfit =
          symbolsMidPrices[symbol] && symbolsBalances[symbol]
            ? getMidPriceProfit(symbol)
            : 0
        return {
          ...symbolsProfit,
          [symbol]: symbolProfit
        }
      },
      {}
    )

    if (!_.isEqual(newSymbolsProfit, symbolsProfit)) {
      setSymbolsProfit(newSymbolsProfit)
    }
  }

  const getMidPriceProfitPercent = (symbol: string) => {
    const profit = getMidPriceProfit(symbol)
    const capital = symbolsCapitals[symbol]
    return (profit / capital) * 100
  }

  const getSymbolsProfitPercent = () => {
    const newSymbolsProfit = balancesSymbols.reduce(
      (symbolsProfit: SymbolsProfit, symbol: string) => {
        const symbolProfit =
          symbolsMidPrices[symbol] && symbolsBalances[symbol]
            ? getMidPriceProfitPercent(symbol)
            : 0
        return {
          ...symbolsProfit,
          [symbol]: symbolProfit
        }
      },
      {}
    )

    if (!_.isEqual(newSymbolsProfit, symbolsProfitPercent)) {
      setSymbolsProfitPercent(newSymbolsProfit)
    }
  }

  useEffect(() => {
    if (shouldUpdateOrdersBySeconds(10)) {
      getAllOrders()
    }
  }, [balancesSymbols])

  // eslint-disable-next-line no-console
  useEffect(() => {
    if (error) log(`- ops, sorry, we have an order error...`, error)
  }, [error])
  useEffect(() => getFilteredOrders(), [allOrders])
  useEffect(() => getSymbolsCapitals(), [symbolsBalances])
  useEffect(() => getSymbolsMidPrices(), [symbolsCapitals])
  useEffect(() => getSymbolsProfitPercent(), [ticker])
  useEffect(() => getSymbolsProfit(), [ticker])
  useEffect(() => getOrdersBalances(), [balancesSymbols])

  return (
    <OrdersContext.Provider
      value={{
        loading,
        allOrders,
        filledOrders,
        filteredOrders,
        symbolsCapitals,
        symbolsMidPrices,
        symbolsProfit,
        symbolsProfitPercent,
        ordersBalances,
        isNotSold,
        isProfitable,
        ordersBuyPrice,
        getSymbolNewOrders
      }}
    >
      {children}
    </OrdersContext.Provider>
  )
}

export const useOrdersContext = () => useContext(OrdersContext)
