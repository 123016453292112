export const randomIntFromInterval = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const onRefresh = (chart: any) => {
  const _data = chart.data.datasets[0].data
  let t = Date.now()
  let last

  t -= t % 5000
  if (_data.length === 0) {
    _data.push({ x: t - 5000, o: 99, h: 101, l: 98, c: 100 })
  }
  last = _data[_data.length - 1]
  if (t !== last.x) {
    const c = last.c
    last = { x: t, o: c, h: c, l: c, c: c }
    _data.push(last)
  }
  last.c = +(last.c + randomIntFromInterval(-0.5, 0.5)).toFixed(2)
  last.h = +Math.max(last.h, last.c).toFixed(2)
  last.l = +Math.min(last.l, last.c).toFixed(2)
}

export const toTimeSplitted = (date: Date) => {
  return [
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds()
  ]
}
