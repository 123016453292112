import { CandleChartInterval } from 'binance-api-node'
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState
} from 'react'

import { ReactComponentProps } from '@/presentation/types'

import { useSymbolContext } from './Symbol'
import { useWebsocketContext } from './Websocket'

export type Signal_LT =
  | 'WAIT'
  | 'BUY'
  | 'SELL'
  | 'AUTO'
  | 'OFF'
  | 'STOP_LOSS'
  | 'STOP_GAIN'
  | 'TRAILING'
  | 'TRAILING_STOP'
  | 'TRAILING_PROFIT'

export type Signal = {
  symbol: string
  signal: Signal_LT
  interval: CandleChartInterval
}

export type SignalState = {
  [symbol: string]: {
    [interval: string]: Signal
  }
}

export type SignalsContextType = {
  signal: Signal_LT
  signals: SignalState | null
  setSignals: Dispatch<SetStateAction<SignalState | null>>
  setSignal: (signal: Signal) => void
}

export const SignalsContext = createContext<SignalsContextType>(
  {} as SignalsContextType
)

export const SignalsProvider = ({ children }: ReactComponentProps) => {
  const [signals, setSignals] = useState<SignalState | null>(null)
  const { interval } = useWebsocketContext()
  const { symbol } = useSymbolContext()

  const setSignal = (signal: Signal) =>
    setSignals(prev => ({
      ...(prev ?? {}),
      [signal.symbol]: {
        ...((prev ?? {})[signal.symbol] ?? {}),
        [signal.interval]: signal
      }
    }))

  const signal = useMemo(() => {
    if (signals && symbol && interval) {
      // log(signals, symbol, interval)
      return signals[symbol][interval].signal
    }
    return 'AUTO' as Signal_LT
  }, [symbol, interval, signals])

  return (
    <SignalsContext.Provider
      value={{
        signal,
        signals,
        setSignals,
        setSignal
      }}
    >
      {children}
    </SignalsContext.Provider>
  )
}

export const useSignalsContext = () => useContext(SignalsContext)
