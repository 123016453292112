import { AxiosResponse } from 'axios'
import {
  createContext,
  SetStateAction,
  useContext,
  useState,
  useEffect
} from 'react'

import { ApiAuthCredentials } from '@/domain/models'
import { ReactComponentProps } from '@/presentation/types'
import { requestLoadCredentials } from '@/presentation/useCases/loadCredentials'
import { requestSaveCredentials } from '@/presentation/useCases/saveCredentials'

import { useAuthContext } from './Auth'

export type ApiContextType = {
  apiAuthenticated: boolean
  exchange: string
  loading: boolean
  KEY: string
  SECRET: string
  setApiAuthenticated: React.Dispatch<SetStateAction<boolean>>
  setExchange: React.Dispatch<SetStateAction<string>>
  SET_KEY: React.Dispatch<SetStateAction<string>>
  SET_SECRET: React.Dispatch<SetStateAction<string>>
  handleSave: (key: string, secret: string) => void
  handleLoad: () => void
}

export const ApiContext = createContext<ApiContextType>({
  apiAuthenticated: false,
  setApiAuthenticated: () => undefined,
  exchange: ``,
  setExchange: () => undefined,
  loading: false,
  KEY: ``,
  SECRET: ``,
  SET_KEY: () => undefined,
  SET_SECRET: () => undefined,
  handleSave: () => undefined,
  handleLoad: () => undefined
})

export const ApiProvider = ({ children }: ReactComponentProps) => {
  const [apiAuthenticated, setApiAuthenticated] = useState(false)
  const { authenticated } = useAuthContext()
  const [exchange, setExchange] = useState('binance')
  const [KEY, SET_KEY] = useState(``)
  const [SECRET, SET_SECRET] = useState(``)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | false>(false)

  const handleLoad = () => {
    setLoading(true)
    requestLoadCredentials()
      .then((res: AxiosResponse<ApiAuthCredentials>) => {
        if (res.data.authenticated) {
          setApiAuthenticated(res.data.authenticated)
          setExchange(res.data.name)
          SET_KEY(res.data.key)
          SET_SECRET(res.data.secret)
        }
      })
      .catch((err: Error) => setError(err))
      .finally(() => setLoading(false))
  }

  const handleSave = (key: string, secret: string) => {
    setLoading(true)
    requestSaveCredentials({ name: exchange, key, secret })
      .then(res => setTimeout(() => setApiAuthenticated(res.data.saved), 400))
      .catch(err => setError(err))
      .finally(() => setTimeout(() => setLoading(false), 400))
  }

  useEffect(() => {
    if (authenticated && !apiAuthenticated && !loading && !error) handleLoad()
  })

  return (
    <ApiContext.Provider
      value={{
        apiAuthenticated,
        setApiAuthenticated,
        exchange,
        setExchange,
        loading,
        KEY,
        SECRET,
        SET_KEY,
        SET_SECRET,
        handleSave,
        handleLoad
      }}
    >
      {children}
    </ApiContext.Provider>
  )
}

export const useApiContext = () => useContext(ApiContext)
