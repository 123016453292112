export const LOGS = {
  isTimeExpiredOrder: 'LOGS: isTimeExpiredOrder ⏲️',
  shouldStopGain: `LOGS: will take trailing profit 💸💸💸🍸✨`,
  shouldStopLoss: `LOGS: will stop loss 💥💥💥`,
  shouldActivateTrailing: `LOGS: activating trailing... 🤑💲💲`,
  shouldSell: `LOGS: will sell with profit 💵🍸✨`,
  hasNewLastPrice: `LOGS: new lastPrice 📠`,
  NewUpdatedOrders: `DEBUG NewUpdatedOrders`,
  NoOrdersToUpdate: 'DEBUG NoOrdersToUpdate'
}
