import _ from 'lodash'
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react'

import { ReactComponentProps } from '@/presentation/types'

import { Symbols } from '../helpers'

import { useAccountInfoContext } from './AccountInfo'

export type SymbolContextType = {
  symbol: string
  selectedSymbols: string[]
  symbolsForCandles: string[]
  selectSymbol: (symbol: string) => void
  setSelectedSymbols: Dispatch<SetStateAction<string[]>>
  manualSymbol: boolean
  activateManual: () => void
}

export const SymbolContext = createContext<SymbolContextType>({
  symbol: '',
  selectedSymbols: [],
  symbolsForCandles: [],
  selectSymbol: () => undefined,
  setSelectedSymbols: () => undefined,
  manualSymbol: false,
  activateManual: () => undefined
})

export const SymbolProvider = ({ children }: ReactComponentProps) => {
  const [symbol, setSymbol] = useState('BTCUSDT')
  const [selectedSymbols, setSelectedSymbols] = useState<string[]>([])
  const [symbolsForCandles, setSymbolsForCandles] = useState<string[]>([])
  const [manualSymbol, setManualSymbol] = useState(false)
  const { balancesSymbols } = useAccountInfoContext()

  useEffect(() => {
    if (selectedSymbols.includes(symbol)) return
    setSelectedSymbols(prev => Array.from(new Set([...prev, symbol])))
  }, [symbol])

  useEffect(() => {
    const newSymbols = Symbols.sort(
      Symbols.toUnique([
        symbol,
        ...(selectedSymbols ?? []),
        ...(balancesSymbols ?? [])
      ])
    )

    if (!_.isEqual(symbolsForCandles, newSymbols)) {
      setSymbolsForCandles(newSymbols)
    }
  }, [balancesSymbols, selectedSymbols, symbol])

  const activateManual = () => setManualSymbol(true)

  const selectSymbol = (symbol: string) => setSymbol(symbol)

  return (
    <SymbolContext.Provider
      value={{
        symbol,
        selectedSymbols,
        symbolsForCandles,
        selectSymbol,
        setSelectedSymbols,
        manualSymbol,
        activateManual
      }}
    >
      {children}
    </SymbolContext.Provider>
  )
}

export const useSymbolContext = () => useContext(SymbolContext)
