import { AssetBalance } from 'binance-api-node'

import { BinanceAccountInfo } from '@/domain/models'

import { TickerState } from '../ticker/types'

export const getTotalFromBalance = (balance: AssetBalance) => {
  return Number(balance.free) + Number(balance.locked)
}

export const getTotalFromBalances = (
  balances: AssetBalance[],
  asset: string
) => {
  const balance =
    balances.find((balance: AssetBalance) => balance.asset === asset) ??
    ({} as AssetBalance)

  return Number(balance.free) + Number(balance.locked)
}

export const getSymbolUSDTBalances = (
  symbol: string,
  accountInfo: BinanceAccountInfo
) => {
  if (accountInfo?.balances.length) {
    const symbolBalance = accountInfo.balances.find(
      (balance: AssetBalance) => symbol === balance.asset
    )
    if (symbolBalance) {
      return getTotalFromBalance(symbolBalance)
    }
    return 0
  }
  return 0
}

export const getBalanceTotals = (
  balances: AssetBalance[],
  ticker: TickerState | null
) => {
  const balanceTotals = balances.reduce(
    (total: number, balance: AssetBalance) => {
      // log({ balance, total });

      if (balance.asset === `BUSD`) {
        // log(balance.asset, total);
        return total
      }

      if (balance.asset === `USDT`) {
        // log(balance.asset, total);
        return total
      }
      if (balance.asset === `BRL`) {
        // log(balance.asset, total);
        return total
      }
      // if (balance.asset === `ETH`) {
      //   return total + getTotalFromBalance(balance) * 16.5;
      // }
      if (balance.asset === `XYM`) {
        // log(balance.asset, total);
        return total // + getTotalFromBalance(balance) * 0.02205
      }
      // if (balance.asset === `BTTC`) {
      //   return total + getTotalFromBalance(balance) * 0.0000022;
      // }
      if (balance.asset === `BOBA`) {
        // log(balance.asset, total + getTotalFromBalance(balance) * 2.62);
        return total // + getTotalFromBalance(balance) * 0.1229
      }
      if (balance.asset === `NFT`) {
        // log(
        //   balance.asset,
        //   total + getTotalFromBalance(balance) * 0.0000026,
        // );
        return total // + getTotalFromBalance(balance) * 0.00000032
      }

      if (balance.asset === `ETHW`) {
        // log(balance.asset, total + getTotalFromBalance(balance) * 3.97);
        return total // + getTotalFromBalance(balance) * 1.325
      }

      if (ticker) {
        // log(
        //   balance.asset,
        //   `${balance.asset}USDT`,
        //   total +
        //     getTotalFromBalance(balance) *
        //       +ticker[`${balance.asset}USDT`]?.lastPrice ?? 0,
        // );

        const lastPrice =
          +ticker[`${balance.asset}USDT`]?.lastPrice ||
          +ticker[`${balance.asset}BUSD`]?.lastPrice

        if (!lastPrice) {
          return total
        }

        const totalFromBalance = getTotalFromBalance(balance)

        const sumTotal = total + totalFromBalance * lastPrice

        // log({
        //   asset: balance.asset,
        //   lastPrice,
        //   sumTotal,
        // });

        return sumTotal
      }

      // log(balance.asset, total);
      return 0
    },
    0
  )
  // log({ balances, balanceTotals });

  return balanceTotals
}
