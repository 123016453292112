import { QueryOrderResult } from 'binance-api-node'

type SymbolCapital = {
  totalCapital: number
  totalCapitalByYear: Record<number, number>
}

type AnalyzeOptions = {
  lastPrice: number
  symbolBalance: number
  symbolMidPrice: number
  symbolCapital: number
  conversionPrice?: number
}

export class OrdersAnalyzer {
  static getExecutionPrice(order: QueryOrderResult) {
    const { cummulativeQuoteQty, executedQty } = order

    if (cummulativeQuoteQty && executedQty) {
      return Number(cummulativeQuoteQty) / Number(executedQty)
    }
    return 0
  }

  static startFromBuyOrders(orders: QueryOrderResult[]) {
    const firstBuyOrder = orders.find(o => o.side === 'BUY')
    if (!firstBuyOrder) return []

    const fistBuyOrderIndex = orders.indexOf(firstBuyOrder)
    return orders.slice(fistBuyOrderIndex)
  }

  static getOrderBalance(order: QueryOrderResult) {
    return parseFloat(order.executedQty)
  }

  static calculateBalance(orders: QueryOrderResult[], symbolBalance?: number) {
    let totalBalance = 0
    let residual = 0

    for (const order of this.startFromBuyOrders(orders)) {
      const multiplier = order.side === 'BUY' ? 1 : -1
      totalBalance += this.getOrderBalance(order) * multiplier
    }

    if (symbolBalance && symbolBalance > totalBalance) {
      residual = symbolBalance - totalBalance

      totalBalance += residual
    }

    return { balance: totalBalance, residualBalance: residual }
  }

  static getOrderCapital(order: QueryOrderResult) {
    return this.getExecutionPrice(order) * this.getOrderBalance(order)
  }

  static calculateCapital(
    orders: QueryOrderResult[],
    conversionPrice?: number,
    residualCapital?: number
  ): SymbolCapital {
    let totalCapitalByYear = {} as Record<number, number>
    let orderCapital = 0
    let totalCapital = residualCapital ?? 0

    for (const order of this.startFromBuyOrders(orders)) {
      const divider =
        conversionPrice && order.symbol.endsWith('BRL') ? conversionPrice : 1
      const multiplier = order.side === 'BUY' ? 1 : -1
      const capital = this.getOrderCapital(order) * multiplier

      if (order.symbol === 'TWTUSDT') {
        continue
      }

      orderCapital = capital / divider
      totalCapital += orderCapital

      let year = new Date(order.updateTime).getFullYear()
      totalCapitalByYear = Object.assign(totalCapitalByYear, {
        [year]: (totalCapitalByYear?.[year] ?? 0) + orderCapital
      })
    }

    return { totalCapital, totalCapitalByYear }
  }

  static calculateSymbolsCapital(
    orders: QueryOrderResult[],
    conversionPrice?: number
  ): Record<string, SymbolCapital> {
    const ordersBySymbols = this.getSymbolsOrders(orders)
    let symbolsCapital = {} as Record<string, SymbolCapital>

    for (const symbol in ordersBySymbols) {
      const symbolOrders = ordersBySymbols[symbol] ?? []
      const symbolCapital = this.calculateCapital(symbolOrders, conversionPrice)
      symbolsCapital[symbol] = symbolCapital
    }

    return symbolsCapital
  }

  static getResidualCapital(
    residualBalance?: number,
    residualMidPrice?: number
  ) {
    return (residualBalance ?? 0) * (residualMidPrice ?? 0)
  }

  static calculateMidPrice(
    orders: QueryOrderResult[],
    residualBalance?: number,
    residualCapital?: number
  ) {
    let midPriceByYear = {} as Record<number, number>
    let orderMidPrice = 0

    let totalCapital = residualCapital ?? 0
    let totalBalance = residualBalance ?? 0
    let midPrice = totalCapital / (totalBalance || 1)

    for (const order of this.startFromBuyOrders(orders)) {
      const multiplier = order.side === 'BUY' ? 1 : -1
      const orderBalance = this.getOrderBalance(order) * multiplier
      const orderCapital = this.getOrderCapital(order) * multiplier

      totalCapital += orderCapital
      totalBalance += orderBalance
      orderMidPrice = orderCapital / orderBalance
      midPrice = totalCapital / totalBalance

      const year = new Date(order.updateTime).getFullYear()
      midPriceByYear = Object.assign(midPriceByYear, {
        [year]: midPriceByYear?.[year] ?? 0 + orderMidPrice
      })
    }

    return { value: midPrice, midPriceByYear }
  }

  static getOrdersCapital(orders: QueryOrderResult[]) {
    let capital = 0

    for (const order of this.startFromBuyOrders(orders)) {
      const multiplier = order.side === 'BUY' ? 1 : -1
      capital += this.getOrderCapital(order) * multiplier
    }

    return capital
  }

  static analyze(orders: QueryOrderResult[], options: AnalyzeOptions) {
    const {
      lastPrice,
      symbolBalance,
      symbolMidPrice,
      symbolCapital,
      conversionPrice
    } = options
    const { balance, residualBalance } = this.calculateBalance(
      orders,
      symbolBalance
    )

    const residualCapital = this.getResidualCapital(
      residualBalance,
      symbolMidPrice
    )

    const capital =
      OrdersAnalyzer.calculateCapital(orders, conversionPrice, residualCapital)
        .totalCapital ||
      symbolCapital ||
      0

    const { value: midPrice } =
      this.calculateMidPrice(orders, residualBalance, residualCapital) ?? 0

    const totalUSDT = (balance * lastPrice) / (conversionPrice ?? 1)
    const profit = totalUSDT - capital
    const profitP = capital ? profit / capital : 0

    return {
      balance,
      capital,
      midPrice,
      profit,
      profitP,
      totalUSDT
    }
  }

  static getSymbolsOrders(orders: QueryOrderResult[]) {
    let symbolOrders = {} as Record<string, QueryOrderResult[]>

    for (const order of orders) {
      const symbol = order.symbol
      const symbolOrder = symbolOrders[symbol] ?? []
      symbolOrders = Object.assign(symbolOrders, {
        [symbol]: [...symbolOrder, order]
      })
    }

    return symbolOrders
  }

  static getSymbols(orders: QueryOrderResult[]) {
    return Array.from(new Set(orders.map(order => order.symbol)))
  }

  static getSymbolsBalances(orders: QueryOrderResult[]) {
    let balances = {} as Record<string, number>
    const symbols = this.getSymbols(orders)

    for (const symbol of symbols) {
      const symbolOrders = this.getSymbolsOrders(orders)[symbol] ?? []
      const balance = this.calculateBalance(symbolOrders)
      balances = Object.assign(balances, {
        [symbol]: balance
      })
    }

    return balances
  }

  static getSymbolsMidPrices(orders: QueryOrderResult[]) {
    let midPrices = {} as Record<string, number>
    const symbolsOrders = this.getSymbolsOrders(orders)
    const symbols = Object.keys(symbolsOrders)

    for (const symbol of symbols) {
      const orders = symbolsOrders[symbol] ?? []
      const { value: midPrice } = this.calculateMidPrice(orders)

      midPrices = Object.assign(midPrices, {
        [symbol]: midPrice
      })
    }

    return midPrices
  }

  static getSymbolsCapitals(orders: QueryOrderResult[], USDTBRLPrice: number) {
    let capitals = {} as Record<string, number>

    for (const order of this.startFromBuyOrders(orders)) {
      const multiplier = order.side === 'BUY' ? 1 : -1
      let capital = this.getOrderCapital(order) * multiplier
      const symbol = order.symbol

      if (symbol.endsWith('BRL')) {
        capital = capital / USDTBRLPrice
      }

      capitals = Object.assign(capitals, {
        [symbol]: (capitals[symbol] ?? 0) + capital
      })
    }

    return capitals
  }

  static getSymbolsProfit(
    orders: QueryOrderResult[],
    getLastPrice: (symbol: string) => number
  ) {
    let profits = {} as Record<string, number>
    const symbolsMidPrices = this.getSymbolsMidPrices(orders)
    const symbolsBalances = this.getSymbolsBalances(orders)
    const symbols = Object.keys(symbolsBalances)

    for (const symbol of symbols) {
      const balance = symbolsBalances[symbol] ?? 0
      const lastPrice = getLastPrice(symbol)
      const midPrice = symbolsMidPrices[symbol] ?? 0
      const profit = (lastPrice - midPrice) * balance

      profits = Object.assign(profits, {
        [symbol]: profit
      })
    }

    return profits
  }
}
