import axios, { AxiosError, AxiosRequestConfig } from 'axios'

const api = axios.create({
  // timeout: 10000,
  baseURL: '/api',
  headers: {
    'Content-Type': `application/json`,
    'Access-Control-Allow-Origin': `*`,
    'Access-Control-Allow-Headers': `Origin, X-Requested-With, Content-Type, Accept`
  }
})

const authenticationHandler = (request: AxiosRequestConfig) =>
  // TODO add token to request headers
  request

const errorHandler = (error: AxiosError) => Promise.reject(error)

api.interceptors.request.use(
  (request: AxiosRequestConfig) => authenticationHandler(request),
  (error: AxiosError) => errorHandler(error)
)

export default api
