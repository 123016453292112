/* eslint-disable no-console */

import _ from 'lodash'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

import { BinanceKlinesInterval } from '@/domain/models'
import { Streams, StreamsSerializer, error, log } from '@/presentation/helpers'
import { streamToAggTrade } from '@/services/broker/aggTrade'
import { getSymbolsFromTicker } from '@/services/broker/symbols'
import { TickerSerializer } from '@/services/broker/ticker'
import { SymbolTicker, TickerState } from '@/services/broker/ticker/types'
import { intervals as baseIntervals } from '@/services/websockets/intervals'
import { streams as baseStreams } from '@/services/websockets/streams'
import { Connections } from '@/services/websockets/types'

import {
  AggTradeState,
  AggTradeStreamsType,
  StreamKline,
  StreamKlineState,
  SymbolAggTrade
} from '../protocols'

import { useAccountInfoContext } from './AccountInfo'
import { useSymbolContext } from './Symbol'

type PeriodsByInterval = Record<BinanceKlinesInterval, number>

export type AggTradeContextType = {
  interval: BinanceKlinesInterval
  periods: Partial<PeriodsByInterval>
  aggTrade: AggTradeState | null
  streamKline: StreamKline
  candlestick: StreamKline
  candlesticks: StreamKlineState
  firstConnection: boolean
  ticker: TickerState | null
  lastPrice: number
  getLastPrice: (symbol: string) => number
  disconnectSocketStreams: () => void
  symbols: string[]
  USDTBRLPrice: number
  symbolsToKlineStreams: (symbols: string[], interval: string) => void
  getTotalByQuantity: (symbol: string, quantity: number) => number
  handleChangeInterval: (interval: BinanceKlinesInterval) => void
}

const periodsByInterval: Partial<PeriodsByInterval> = {
  '1s': 60 * 15,
  '1m': 60 * 15,
  '3m': 60 * 15,
  '5m': 60 * 15,
  '15m': 120,
  '30m': 60,
  '1h': 24,
  '4h': 21,
  '1d': 21,
  '1w': 21,
  '1M': 12
}

const state = {
  interval: BinanceKlinesInterval.ONE_SECOND,
  periods: periodsByInterval,
  aggTrade: {} as AggTradeState,
  streamKline: {} as StreamKline,
  candlestick: {} as StreamKline,
  candlesticks: {} as StreamKlineState,
  connections: {} as Connections,
  firstConnection: false,
  ticker: {},
  lastPrice: 0,
  getLastPrice: () => 0,
  disconnectSocketStreams: () => undefined,
  symbols: [],
  USDTBRLPrice: 0,
  symbolsToKlineStreams: () => undefined,
  getTotalByQuantity: () => 0,
  handleChangeInterval: () => undefined
}

export const WebsocketContext = createContext<AggTradeContextType>(state)

type Props = {
  children?: React.ReactNode | React.ReactNode[]
}

export const WebsocketProvider = ({ children }: Props) => {
  const { symbol, selectedSymbols: _symbols } = useSymbolContext()
  // const { symbols: btSymbols } = useBacktestsContext()
  const { marketSymbols } = useAccountInfoContext()
  const [interval, setInterval] = useState(state.interval)
  const [periods, setPeriods] = useState(state.periods)
  const [intervals, setIntervals] = useState<string[]>(baseIntervals)
  const [streams, setStreams] = useState<string[]>([])
  const [joinedStreams, setJoinedStreams] = useState<string>('')
  const [connection, setConnection] = useState<string | null>(null)
  const [ticker, setTicker] = useState<TickerState>(state.ticker)
  const [aggTrade, setAggTrade] = useState<AggTradeState>(state.aggTrade)
  const [symbols, setSymbols] = useState<string[]>([])
  const [firstConnection, setFirstConnection] = useState<boolean>(false)
  const [connections, setConnections] = useState<Connections>(state.connections)
  const [USDTBRLPrice, setUSDTBRLPrice] = useState(0)
  const [streamKline, setStreamKline] = useState(state.streamKline)
  const [candlestick, setKline] = useState(state.candlestick)
  const [candlesticks, setKlines] = useState(state.candlesticks)

  const symbolTicker = useMemo(
    () => (ticker && ticker[symbol] ? ticker[symbol] : ({} as SymbolTicker)),
    [ticker, symbol]
  )

  const lastPrice = useMemo(() => {
    if (symbolTicker) return symbolTicker.lastPrice ?? 0
    return 0
  }, [symbolTicker])

  const handleChangeInterval = (interval: BinanceKlinesInterval) =>
    setInterval(interval)

  useEffect(() => {
    if (streamKline.symbol === symbol) {
      setKline(streamKline)
    }
  }, [streamKline])

  useEffect(
    () => setJoinedStreams(Streams.toUnique(streams).join('/')),
    [streams]
  )

  useEffect(() => {
    if (joinedStreams) setConnection(btoa(joinedStreams).slice(0, 15))
  })

  useEffect(() => {
    if (ticker) {
      const USDTBRLPrice = ticker.USDTBRL?.lastPrice ?? 0
      if (USDTBRLPrice) setUSDTBRLPrice(USDTBRLPrice)

      const newSymbols = getSymbolsFromTicker(ticker)

      if (!_.isEqual(symbols, newSymbols)) {
        setSymbols(newSymbols)
      }
    }
  }, [ticker])

  useEffect(() => {
    // log(`ws - checking connections...`);
    if (Object.keys(connections).length === 1) {
      if (!firstConnection) setFirstConnection(true)
    }
  }, [connections])

  const getLastPrice = useCallback(
    (symbol: string) => {
      if (ticker && ticker[symbol]) return ticker[symbol].lastPrice
      return 0
    },
    [ticker]
  )

  const getTotalByQuantity = useCallback(
    (symbol: string, quantity: number) => {
      return getLastPrice(symbol) * quantity
    },
    [getLastPrice]
  )

  const symbolsToKlineStreams = useCallback(() => {
    const newStreams: string[] = Streams.fromSymbols(_symbols, interval)
    const mergedStreams = Streams.toUnique([...baseStreams, ...newStreams])
    const sortedStreams = Streams.sort(streams)
    const sortedMergedStreams = Streams.sort(mergedStreams)

    if (!_.isEqual(sortedStreams, sortedMergedStreams)) {
      log(
        'DEBUG: streams - sorry streams will be disconnected...',
        streams,
        mergedStreams,
        !_.isEqual(streams, mergedStreams)
      )
      disconnectSocketStreams()
      setStreams(Streams.mergeState(mergedStreams))
    }
  }, [_symbols, interval, streams])

  useEffect(() => symbolsToKlineStreams(), [symbolsToKlineStreams])

  const connectSocketStreams = useCallback(() => {
    if (
      connection &&
      streams.length &&
      (!connections[connection] || connections[connection].CLOSED)
    ) {
      setConnections(oldConnections => ({
        // ...oldConnections,
        [connection]: new WebSocket(
          encodeURI(
            `${process.env.WS_STREAM_BASE_URL}?streams=${joinedStreams}`
          )
        )
      }))

      log('DEBUG: streams new connected', connection, joinedStreams)
    }
  }, [connection, connections, joinedStreams, setConnections])

  const disconnectSocketStreams = useCallback(() => {
    if (streams && connections) {
      log('DEBUG: streams all disconnected', streams, connections)
      Object.values(connections ?? {}).forEach(c => c.close())
      setConnections({})
    }
  }, [streams, connections])

  useEffect(() => {
    if (connection) {
      log(`ws - connect with streams: `, streams.join(', '))
      connectSocketStreams()
    }
    return () => {
      log('ws - destroying connections for: ', streams.join(', '))
      disconnectSocketStreams()
    }
  }, [connection])

  useEffect(() => {
    log('DEBUG: streams connections', connections)
  }, [connections])

  useEffect(() => {
    if (connection && connections[connection]) {
      connections[connection].onmessage = evt => {
        const { stream, data } = JSON.parse(evt.data)

        if (stream.endsWith('depth')) {
          // bids = data['bids']
          // asks = data['asks']
          // print('Buy Volume:', sum([float(bid[1]) for bid in bids]))
          // print('Sell Volume:', sum([float(ask[1]) for ask in asks]))
        }

        if (stream.endsWith(`@aggTrade`)) {
          const {
            data
          }: {
            data: AggTradeStreamsType
          } = JSON.parse(evt.data)

          const aggTrade = streamToAggTrade(data)

          const initialAgg = {
            symbol: 'BTCUSDT',
            total: 0
          } as SymbolAggTrade

          setAggTrade(prev => {
            const range = 1000
            const agg = 10
            const price = Math.round(aggTrade?.price ?? 0 / agg) * agg
            const filtered = Object.entries(prev ?? {}).filter(([k, p]) => {
              return p.price <= price + range && p.price >= price - range
            })
            const aggRest = Object.fromEntries(filtered)
            return {
              ...aggRest,
              [price.toString()]: {
                ...aggTrade,
                total: aggTrade.quantity
                  ? getTotalByQuantity(aggTrade.symbol, aggTrade.quantity)
                  : 0
              }
            }
          })
        }

        if (stream.endsWith(`@kline_${interval}`)) {
          if (data.k) {
            const _candlestick = StreamsSerializer.streamToKline(data.k)

            setStreamKline(_candlestick)
            setKlines(StreamsSerializer.toState(_candlestick))
          }
        }

        if (stream === '!bookTicker') {
          log(`!bookTicker`, evt.data)
        }

        // if (stream.endsWith('ticker')) {
        //   setTicker(prev => TickerSerializer.fromStream(prev, data))
        // }

        if (stream === '!ticker@arr') {
          setTicker(prev => TickerSerializer.fromStreams(prev, data))
        }

        if (stream.endsWith(`@ticker`)) {
          console.log('DEBUG: @ticker', data)
        }

        // TODO - HANDLE OTHER STREAMS
      }

      connections[connection].onopen = () => {
        // log(`ws - opening websocket...`);
      }

      connections[connection].onerror = evt => {
        error(evt)
      }

      connections[connection].onclose = () => {
        log(`ws - closed websocket.`)
      }
    }
  }, [connections, connection])

  return (
    <WebsocketContext.Provider
      value={{
        interval,
        periods,
        aggTrade,
        streamKline,
        candlestick,
        candlesticks,
        firstConnection,
        ticker,
        lastPrice,
        getLastPrice,
        disconnectSocketStreams,
        symbols,
        USDTBRLPrice,
        symbolsToKlineStreams,
        getTotalByQuantity,
        handleChangeInterval
      }}
    >
      {children}
    </WebsocketContext.Provider>
  )
}

export const useWebsocketContext = () => useContext(WebsocketContext)
