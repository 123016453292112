import { AxiosResponse } from 'axios'

import { iconUrl } from '@/main/constants/endpoints'
import api from '@/services/api'

type URLData = {
  URL: string
}

export const requestCryptoIcon = async (
  data: Record<string, string>
): Promise<AxiosResponse<URLData>> =>
  api({ method: `POST`, url: iconUrl, data })
