/* eslint-disable no-console */

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

import { BinanceKlinesInterval } from '@/domain/models'
import { Indicators } from '@/presentation/helpers'
import { Kline, KlineState } from '@/presentation/protocols'
import { ReactComponentProps } from '@/presentation/types'

import { useKlines, useIndicators, usePivotsHighLow } from '../hooks'
import { PivotsHighLow, SymbolIndicators } from '../protocols'

import { useSymbolContext } from './Symbol'
import { useWebsocketContext } from './Websocket'

export type KlinesContextType = {
  klines: KlineState
  dailyKlines: KlineState
  weeklyKlines: KlineState
  monthlyKlines: KlineState
  period: number
  pivotsHighLow: PivotsHighLow
  indicators: SymbolIndicators
  getLiquidity: (symbol: string) => number
}

export const KlinesContext = createContext<KlinesContextType>(
  {} as KlinesContextType
)

export const KlineProvider = ({ children }: ReactComponentProps) => {
  const [period, setPeriod] = useState(1000)
  const { symbol, selectedSymbols } = useSymbolContext()
  const { interval, periods } = useWebsocketContext()

  const { klines } = useKlines({ interval: BinanceKlinesInterval.ONE_SECOND })
  const { klines: dailyKlines } = useKlines({
    interval: BinanceKlinesInterval.ONE_DAY,
    lastQueueKey: '1s'
  })
  const { klines: weeklyKlines } = useKlines({
    interval: BinanceKlinesInterval.ONE_WEEK,
    lastQueueKey: '1d'
  })
  const { klines: monthlyKlines } = useKlines({
    interval: BinanceKlinesInterval.ONE_MONTH,
    lastQueueKey: '1w'
  })

  const { pivotsHighLow } = usePivotsHighLow({
    klines,
    interval,
    symbol,
    periods: periods[interval]
  })

  const pivots = useMemo(() => {
    const secondsKlines = dailyKlines[symbol]?.['1d'] ?? {}
    const pivots = {} as Record<
      number,
      {
        value: number
        time: number
        price: number
        high?: boolean
        low?: boolean
      }
    >

    let lastPivot = { high: false, low: false, value: 0, time: 0, index: 0 }

    if (klines) {
      Object.values(secondsKlines).forEach(kline => {
        if (lastPivot.high || lastPivot.index === 0) {
          const newHigh = kline.high > lastPivot.value
          const index = newHigh ? lastPivot.index + 1 : lastPivot.index
          const time = newHigh ? kline.openTime : lastPivot.time
          const value = newHigh ? kline.high : lastPivot.value
          const price = newHigh ? kline.high : lastPivot.value

          pivots[index] = { value, time, price, high: true }

          console.log('DEBUG pivots', kline.high)

          // if (newHigh && !lastPivot.high) {
          lastPivot.high = newHigh
          lastPivot.value = value
          lastPivot.time = time
          lastPivot.index = index
          // }
        }

        if (lastPivot.high || lastPivot.index === 0) {
          const newLow = kline.low < lastPivot.value
          const index = newLow ? lastPivot.index + 1 : lastPivot.index
          const time = newLow ? kline.openTime : lastPivot.time
          const value = newLow ? kline.low : lastPivot.value
          const price = newLow ? kline.low : lastPivot.value

          pivots[index] = { value, time, price, low: true }

          // if (newLow && !lastPivot.low) {
          lastPivot.low = newLow
          lastPivot.value = value
          lastPivot.time = time
          lastPivot.index = index
          // }
        }
      })
    }

    return pivots
  }, [dailyKlines])

  useEffect(() => {
    console.log('DEBUG pivots', symbol, pivots)
  }, [dailyKlines, pivots])

  const symbolsKlines = useMemo(() => {
    return selectedSymbols.reduce((_klines, symbol) => {
      const _kline = klines ? klines[symbol] : {}

      if (_kline && interval) {
        return {
          ..._klines,
          [symbol]: Object.values(_kline[interval] ?? {}).reverse()
        }
      }

      return _klines
    }, {} as { [symbol: string]: Kline[] })
  }, [klines, selectedSymbols])

  const getLiquidity = useCallback(
    (symbol: string) =>
      Indicators.getLiquidityRatio(symbolsKlines[symbol] ?? []),
    [symbolsKlines]
  )

  const { indicators } = useIndicators({ symbol, interval, klines })

  return (
    <KlinesContext.Provider
      value={{
        klines,
        dailyKlines,
        weeklyKlines,
        monthlyKlines,
        period,
        pivotsHighLow,
        indicators,
        getLiquidity
      }}
    >
      {children}
    </KlinesContext.Provider>
  )
}

export const useKlinesContext = () => useContext(KlinesContext)
