import { OrderSide_LT, QueryOrderResult } from 'binance-api-node'

import {
  ViewExchangeOrder,
  ViewExchangeOrdersData
} from '@/presentation/components/contexts/wallet/components/list/protocols'
import {
  Currency,
  NumberFormatter,
  OrdersAnalyzer
} from '@/presentation/helpers'

export class ExchangeOrder {
  static readonly isProfitable = (order: QueryOrderResult) => {
    return order.status === 'FILLED' && order.side === 'BUY'
  }

  static readonly getQuantity = (order: QueryOrderResult) => {
    return Number(order.executedQty) || Number(order.origQty)
  }

  static readonly getBuyPrice = (order: QueryOrderResult) => {
    const { cummulativeQuoteQty, executedQty } = order

    if (cummulativeQuoteQty && executedQty) {
      return Number(cummulativeQuoteQty) / Number(executedQty)
    }
    return 0
  }

  static getSize = (order: QueryOrderResult) => {
    return Number(order.origQty) * Number(order.price)
  }

  static readonly getCapital = (order: QueryOrderResult) => {
    return this.getBuyPrice(order) * this.getQuantity(order)
  }

  static readonly getProfit = (order: QueryOrderResult, close: number) => {
    if (this.isProfitable(order)) {
      const profit = (close - this.getBuyPrice(order)) * this.getQuantity(order)

      return profit
    }

    return null
  }

  static readonly getPosition = (order: QueryOrderResult, close: number) => {
    return close * this.getQuantity(order)
  }

  static readonly getProfitPercent = (
    order: QueryOrderResult,
    close: number
  ) => {
    const profit = this.getProfit(order, close)
    const capital = this.getCapital(order)

    if (profit && this.isProfitable(order)) {
      return Currency.logRound(profit / capital)
    }

    return null
  }

  static readonly getProfitPercentFormatted = (
    order: QueryOrderResult,
    close: number
  ) => {
    if (close && this.isProfitable(order)) {
      return NumberFormatter.toPercentFormatted(
        (this.getProfitPercent(order, close) ?? 0) * 100
      )
    }

    return '0%'
  }

  static readonly isProfitableOrder = (order: QueryOrderResult) => {
    return order.side === 'BUY' && order.status === 'FILLED'
  }

  static readonly toViewTotalCapital = (
    data: QueryOrderResult[],
    close: number
  ) => {
    return data.reduce((total, order) => {
      return total + this.getPosition(order, close)
    }, 0)
  }

  static readonly toViewTotalProfit = (
    data: QueryOrderResult[],
    close: number
  ) => {
    return data.reduce((total, order) => {
      return total + (this.getProfit(order, close) ?? 0)
    }, 0)
  }

  static readonly toViewTotalProfitF = (
    data: QueryOrderResult[],
    close: number
  ) => {
    const totalProfit = this.toViewTotalProfit(data, close)

    return NumberFormatter.toPrice(totalProfit, 2)
  }

  static readonly toViewTotalProfitPercentF = (
    data: QueryOrderResult[],
    close: number
  ) => {
    const totalProfit = this.toViewTotalProfit(data, close)
    const totalCapital = this.toViewTotalCapital(data, close)

    return NumberFormatter.toPercentFormatted(totalProfit / totalCapital, 4)
  }

  static readonly toViewExchangeOrder = (
    data: QueryOrderResult[],
    close: number,
    totalCapitalPerOrder?: Record<string, number>,
    midPricePerOrder?: Record<string, number>,
    field?: keyof QueryOrderResult
  ): ViewExchangeOrder[] => {
    return this.filterOrdersByField(data, field ?? 'time').map(order => {
      return {
        date: `${new Date(order.time).toLocaleDateString()} ${new Date(
          order.time
        ).toLocaleTimeString()}`,
        update: `${new Date(order.updateTime).toLocaleDateString()} ${new Date(
          order.updateTime
        ).toLocaleTimeString()}`,
        symbol: order.symbol,
        price: Currency.logRound(
          order.price ? Number(order.price) : this.getBuyPrice(order)
        ).toString(),
        side: order.side as OrderSide_LT,
        qty: NumberFormatter.toPrice(this.getQuantity(order), 5),
        capital: NumberFormatter.toPrice(this.getCapital(order), 5),
        'buy price': NumberFormatter.toPrice(
          Currency.logRound(this.getBuyPrice(order) ?? 0, 5)
        ),
        'last price': NumberFormatter.toPrice(close ?? 0, 5),
        status: order.status,
        'profit ($)': NumberFormatter.toPrice(
          this.getProfit(order, close) ?? '-',
          2
        ),
        'profit (%)': this.getProfitPercentFormatted(order, close),
        'total ($)': NumberFormatter.toPrice(
          totalCapitalPerOrder?.[order.clientOrderId] ?? 0,
          5
        ),
        'mid price ($)': NumberFormatter.toPrice(
          midPricePerOrder?.[order.clientOrderId] ?? 0
        )
      } as ViewExchangeOrder
    })
  }

  static totalCapitalPerOrder(data: QueryOrderResult[]) {
    let sumTotal = 0
    const total = {} as Record<string, number>

    const filledOrders = data.filter(order => order.status === 'FILLED')

    for (const order of OrdersAnalyzer.startFromBuyOrders(filledOrders)) {
      const multiplier = order.side === 'BUY' ? 1 : -1
      const capital = OrdersAnalyzer.getOrderCapital(order) * multiplier
      total[order.clientOrderId] = sumTotal + capital
      sumTotal += capital
    }

    return total
  }

  static midPricePerOrder(data: QueryOrderResult[]) {
    const total = {} as Record<string, number>
    const filledOrders = data.filter(order => order.status === 'FILLED')
    const orders = OrdersAnalyzer.startFromBuyOrders(filledOrders)

    orders.forEach((order, i) => {
      const key = order.clientOrderId
      const slice = orders.slice(0, i + 1)
      const midPrice = OrdersAnalyzer.calculateMidPrice(slice).value
      if (key) total[key] = midPrice
    })

    return total
  }

  static readonly toExchangeViewOrders = (
    data: QueryOrderResult[],
    close: number,
    field?: keyof QueryOrderResult
  ): ViewExchangeOrdersData => {
    const totalCapitalPerOrder = this.totalCapitalPerOrder(data)
    const midPricePerOrder = this.midPricePerOrder(data)

    return {
      view: this.toViewExchangeOrder(
        data,
        close,
        totalCapitalPerOrder,
        midPricePerOrder,
        field
      ),
      data: data
    }
  }

  static readonly filterOrdersByField = (
    orders: QueryOrderResult[],
    field: keyof QueryOrderResult
  ) => {
    return orders.sort((a, b) =>
      Number(a[field]) > Number(b[field])
        ? 1
        : Number(a[field]) > Number(b[field])
        ? -1
        : 0
    )
  }
}
