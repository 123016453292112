import { faker } from '@faker-js/faker'

import {
  FiboPriceLevels,
  PartialFiboLevels,
  PivotPriceLevels
} from '@/presentation/helpers'

import Order from './order'

export type StrategyStatus_LT = 'IDLE' | 'ON' | 'OFF'

export type StrategyParams = {
  strategy: string
  status: StrategyStatus_LT
  symbol: string
  interval: string
  side: string
  quantityUSDT: number
  leverage: number
  initialProfit: number
  lowStopLoss: number
  priceLevels: PartialFiboLevels | PivotPriceLevels
  orders: Order[]
  userId: string
}

export type StrategyData = StrategyParams & { orders: Order[] }

export type MountPositionsParams = {
  symbol: string
  interval: string
  strategy: string
  side: string
  type: string
  quantityUSDT: number
  leverage: number
  initialProfit: number
  lowStopLoss: number
  priceLevels: PartialFiboLevels | PivotPriceLevels
  userId: string
}

export class Strategy {
  private initTime?: number

  constructor(
    public readonly label: string,
    public status: StrategyStatus_LT,
    public side: string,
    public readonly symbol: string,
    public readonly interval: string,
    public readonly quantityUSDT: number,
    public readonly leverage: number,
    public initialProfit: number,
    public readonly lowStopLoss: number,
    public readonly priceLevels: PartialFiboLevels | PivotPriceLevels,
    public orders: Order[],
    public userId: string
  ) {}

  static mountPositions({
    symbol,
    interval,
    strategy,
    side,
    type,
    quantityUSDT,
    leverage,
    initialProfit,
    lowStopLoss,
    priceLevels,
    userId
  }: MountPositionsParams) {
    const levelsKeys = Object.keys(priceLevels)
    const time = Date.now()

    return levelsKeys.map((level, i) => {
      const price = priceLevels[
        level as keyof Partial<FiboPriceLevels | PivotPriceLevels>
      ] as number
      const quantity = quantityUSDT / price / 16 / (levelsKeys.length - i + 1)

      const order = {
        id: faker.datatype.uuid(),
        time: Date.now(),
        label: `${strategy}_${level.toLowerCase()}`,
        symbol,
        interval,
        side,
        quantity,
        leverage,
        status: 'IDLE',
        type,
        price,
        level,
        initialProfit,
        lowStopLoss,
        strategy,
        strategyId: `${strategy}_${time}`,
        userId,
        buyPrice: price,
        sellPrice: price * (1 + initialProfit),
        stopLossSellPrice: price * (1 - lowStopLoss)
      }

      return new Order(
        order.id,
        order.time,
        order.label,
        order.symbol,
        order.interval,
        order.side,
        order.quantity,
        order.leverage,
        order.status,
        order.type,
        order.price,
        order.level,
        order.initialProfit,
        order.lowStopLoss,
        order.strategy,
        order.strategyId,
        order.userId,
        order.buyPrice,
        order.sellPrice,
        order.stopLossSellPrice
      )
    })
  }

  static create({
    strategy,
    status,
    side,
    symbol,
    interval,
    quantityUSDT,
    leverage,
    initialProfit,
    lowStopLoss,
    priceLevels,
    orders,
    userId
  }: StrategyParams): Strategy {
    const sorted = Object.fromEntries(
      Object.entries(priceLevels).sort(([j, a], [k, b]) => {
        return a < b ? -1 : a > b ? 1 : 0
      })
    )

    return new Strategy(
      strategy,
      status,
      side,
      symbol,
      interval,
      quantityUSDT,
      leverage,
      initialProfit,
      lowStopLoss,
      priceLevels,
      orders,
      userId
    )
  }

  execute() {
    this.status = 'ON'
    this.initTime = Date.now()
    this.orders = this.orders.map(o => o.setNew())
    return this
  }

  setOrders(orders: Order[]) {
    this.orders = orders

    return this
  }

  isIdle() {
    return this.status === 'IDLE'
  }

  isOn() {
    return this.status === 'ON'
  }

  isOff() {
    return this.status === 'OFF'
  }
}
