import { INITIAL_INVESTMENT } from '@/main/constants/env'
import { NumberFormatter } from '@/presentation/helpers'

export type AccountIndicatorsData = {
  investment: number
  investmentF: string
  accountTotals: number
  accountTotalsF: string
  accountProfit: number
  accountProfitF: string
  accountProfitP: number
  accountProfitPF: string
  accountGain?: boolean
}

export class AccountIndicators {
  static get(
    accountTotals: number,
    USDTBRLPrice: number
  ): AccountIndicatorsData {
    const investment = INITIAL_INVESTMENT / USDTBRLPrice
    const accountProfit = accountTotals - investment
    const accountGain = accountTotals ? accountTotals > investment : undefined
    const accountProfitP = accountProfit / investment

    if (!accountTotals || !USDTBRLPrice) {
      return {
        investment: 0,
        investmentF: '-',
        accountTotals: 0,
        accountTotalsF: '-',
        accountProfit: 0,
        accountProfitF: '-',
        accountProfitP: 0,
        accountProfitPF: '-'
      }
    }

    return {
      investment,
      investmentF: NumberFormatter.toPrice(investment),
      accountTotals,
      accountTotalsF: NumberFormatter.toPrice(accountTotals),
      accountProfit,
      accountProfitF: NumberFormatter.toPrice(accountProfit),
      accountProfitP,
      accountProfitPF: NumberFormatter.toPercentFormat(accountProfitP),
      accountGain
    }
  }
}
