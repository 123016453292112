import { bullish, SMA } from 'technicalindicators'
import StockData from 'technicalindicators/declarations/StockData'

import { Currency, NumberFormatter } from '@/presentation/helpers'
import {
  Kline,
  PivotsHighLow,
  SymbolIndicators
} from '@/presentation/protocols'

import { Fibonacci, PivotPoints } from '.'

export class Indicators {
  static getLiquidity(klines: Kline[]) {
    return klines.reduce((v, candle) => {
      const lowLiquidity = [candle.high, candle.low, candle.close].every(
        v => v === candle.open
      )
      if (lowLiquidity) return v
      return v + 1
    }, 0)
  }

  static getLiquidityRatio(klines: Kline[]) {
    const liquidity = this.getLiquidity(klines)
    const period = klines.length
    return liquidity && period ? liquidity / period : 0
  }

  static getPriceRange(klines: Kline[]) {
    return (
      klines.reduce((avgRange, candle) => {
        return avgRange + Math.abs(candle.high / candle.low - 1)
      }, 0) / klines.length
    )
  }

  static getVolumeSMA(
    period: number,
    field: keyof Pick<StockData, 'close' | 'high' | 'low' | 'open'>,
    klines: Kline[]
  ) {
    const values = this.toTAKlines(klines)[field]

    return SMA.calculate({ period, values })
  }

  static toTAKlines(klines: Kline[]) {
    return klines.reduce(
      (data, candle) => {
        return {
          open: [...data.open, candle.open],
          high: [...data.high, candle.high],
          close: [...data.close, candle.close],
          low: [...data.low, candle.low]
        }
      },
      {
        open: [] as number[],
        high: [] as number[],
        close: [] as number[],
        low: [] as number[]
      }
    )
  }

  static isBullish(klines: Kline[]) {
    return bullish(this.toTAKlines(klines))
  }

  static get(
    lastPrice: number,
    symbolKlines: Kline[],
    period: number,
    pivotsHighLow: PivotsHighLow
  ): SymbolIndicators {
    const liquidityRatio = this.getLiquidityRatio(symbolKlines)
    const bullish = this.isBullish(symbolKlines)

    const slicedKlines = symbolKlines.slice(0, period)
    const priceRange = this.getPriceRange(slicedKlines)
    const volumeSMA =
      this.getVolumeSMA(period, 'close', symbolKlines).at(-1) ?? 0

    const fiboPriceLevels = Fibonacci.getFiboPriceLevels(pivotsHighLow)
    const pivotPriceLevels = PivotPoints.getPivotPoints(
      fiboPriceLevels,
      lastPrice ?? 0
    )

    const { priceLevel, priceLevelF } = Fibonacci.getPriceLevel(
      lastPrice,
      fiboPriceLevels
    )

    const pivotHLRange = Number(pivotsHighLow.hh) / Number(pivotsHighLow.ll) - 1

    return {
      lastPrice,
      lastPriceF: Currency.logRound(lastPrice).toString(),
      priceRange,
      priceRangeF: NumberFormatter.toPercentFormatted(priceRange * 100, 4),
      pivotHigh: pivotsHighLow.hh,
      pivotLow: pivotsHighLow.ll,
      pivotHLRange,
      pivotHLRangeF: NumberFormatter.toPercentFormatted(pivotHLRange * 100),
      volume: slicedKlines.pop()?.quoteVolume ?? 0,
      volumeF: NumberFormatter.toPrice(slicedKlines.pop()?.volume ?? 0),
      volumeSMA,
      priceLevel,
      priceLevelF,
      liquidityRatio,
      liquidityRatioF: liquidityRatio
        ? NumberFormatter.toPercentFormatted(liquidityRatio * 100)
        : '-',
      bullish,
      bullishF: bullish ? 'BUY' : 'SELL'
    }
  }
}
