import {
  COUNTRY_ICON_URL_PREFIX_TV,
  SYMBOL_ICON_URL_PREFIX_TV
} from '@/main/constants/symbol'
import { requestCryptoIcon } from '@/presentation/useCases/icon'

export class Icon {
  static async getSymbolIcon(symbol: string) {
    const {
      data: { URL }
    } = await requestCryptoIcon({ symbol })

    return URL
  }

  static generateUrl(symbol: string) {
    if (symbol === `USDTBRL`) {
      return `${SYMBOL_ICON_URL_PREFIX_TV}${symbol.slice(-3, -1)}.svg`
    }

    if (symbol === `ETHBRL`) {
      return `${SYMBOL_ICON_URL_PREFIX_TV}${symbol.slice(0, -3)}.svg`
    }

    if (symbol === `BTTCUSDT`) {
      return `${SYMBOL_ICON_URL_PREFIX_TV}${symbol.slice(0, -5)}.svg`
    }

    return `${SYMBOL_ICON_URL_PREFIX_TV}${symbol.slice(0, -4)}.svg`
  }

  static generateCountryUrl(symbol: string) {
    if (symbol === `USDTBRL`) {
      return `${COUNTRY_ICON_URL_PREFIX_TV}${symbol.slice(-3, -1)}.svg`
    }

    return `${SYMBOL_ICON_URL_PREFIX_TV}${symbol.slice(0, -4)}.svg`
  }
}
