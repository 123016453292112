import { AxiosResponse } from 'axios'
import { NewOrderSpot, Order } from 'binance-api-node'

import { executeOrder } from '@/main/constants/endpoints'
import api from '@/services/api'

export const requestNewOrder = async (
  data: NewOrderSpot
): Promise<AxiosResponse<Order>> =>
  api({ method: `POST`, url: executeOrder, data })
