import Header from '@/presentation/components/structure/Header'
import ThemeProvider from '@/presentation/contexts/Theme'

import { LayoutProps } from './protocols'

export const LandingLayout: React.FC<LayoutProps> = ({
  children
}: LayoutProps) => {
  return (
    <ThemeProvider>
      <Header />
      {children}
    </ThemeProvider>
  )
}
