import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { BinanceKlinesInterval } from '@/domain/models'
import { Kline } from '@/services/klines'

import { KlineSerializer } from '../components/contexts/chart/adapters'
import {
  useAccountInfoContext,
  useKlinesQueueContext,
  useSymbolContext
} from '../contexts'
import { useWebsocketContext } from '../contexts/Websocket'
import { Symbols } from '../helpers'
import { KlineState, SymbolsKlinesParams } from '../protocols'
import { requestKlines } from '../useCases/klines'

import { useSymbol } from './use-symbol'

export const useKlines = ({
  interval,
  limit,
  lookback = 6,
  lastQueueKey
}: {
  interval: BinanceKlinesInterval
  limit?: number
  lookback?: number
  lastQueueKey?: string
}) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [symbols, setSymbols] = useState<string[]>([])
  const [lastUpdate, setLastUpdate] = useState<number | null>(null)
  const [klines, setKlines] = useState<KlineState>({})

  const { periods, streamKline } = useWebsocketContext()
  const { symbol, selectedSymbols } = useSymbolContext()
  const { balancesSymbols } = useAccountInfoContext()
  const { symbolKlines } = useSymbol({ symbol, interval, klines })

  const { updateKlinesQueue, canLoadByLastQueueKey } = useKlinesQueueContext()

  useEffect(() => {
    const newSymbols = Symbols.sort(
      Symbols.toUnique([
        symbol,
        ...(selectedSymbols ?? []),
        ...(balancesSymbols ?? [])
      ])
    )

    if (!_.isEqual(symbols, newSymbols)) {
      setSymbols(newSymbols)
    }
  }, [balancesSymbols, selectedSymbols, symbol])

  const getPassedTime = (time: number | null) => {
    if (!time) return 0

    return Math.ceil(Math.abs(Date.now() - time) / 1000)
  }

  const shouldUpdateBySeconds = (
    lastUpdate: number | null,
    seconds: number
  ) => {
    if (!lastUpdate) return true

    const now = Date.now()
    const passedTime = getPassedTime(lastUpdate)
    return passedTime > seconds
  }

  // TODO: let ws handle every second updates
  const shouldUpdate = useMemo(() => {
    const seconds = 60
    const hasSymbols = symbols.length > 1
    const hasPassedSeconds = !!shouldUpdateBySeconds(lastUpdate, seconds)
    const canLoadByQueueKey = canLoadByLastQueueKey(lastQueueKey)
    const shouldUpdate =
      hasPassedSeconds && hasSymbols && !loading && canLoadByQueueKey // && !hasKlines

    console.log('DEBUG should update klines...', shouldUpdate, {
      hasPassedSeconds,
      hasSymbols,
      notLoading: !loading,
      canLoadByQueueKey
    })

    return shouldUpdate
  }, [klines, symbols, balancesSymbols, lastUpdate, lastQueueKey, loading])

  const getSymbolsKlines = useCallback(
    (params: SymbolsKlinesParams) => {
      setLoading(true)
      requestKlines(params)
        .then(({ data }) => {
          setKlines(KlineSerializer.stateFromData(data))
          setLastUpdate(Date.now())
          updateKlinesQueue(interval)
        })
        .catch(error => setError(error))
        .finally(() => setLoading(false))
    },
    [interval, updateKlinesQueue]
  )

  const startCandle = useMemo(() => symbolKlines.at(-lookback), [symbolKlines])

  const handleGetKlines = useCallback(() => {
    let startTime: number | undefined
    if (startCandle) startTime = startCandle.openTime

    getSymbolsKlines({
      symbols,
      interval,
      limit: limit ?? periods[interval],
      startTime
      // endTime: Date.now()
    })
  }, [symbols, interval, startCandle, getSymbolsKlines])

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (shouldUpdate) {
      timer = setTimeout(() => handleGetKlines(), 10000)
    }
    return () => clearTimeout(timer)
  }, [shouldUpdate])

  const updateCandes = useCallback(() => {
    if (Object.keys(klines).length) {
      setKlines(Kline.streamToKlines(streamKline))
    }
  }, [klines, streamKline])

  useEffect(() => {
    updateCandes()
  }, [streamKline])

  useEffect(() => {
    if (loading) return
    let timer: NodeJS.Timeout
    timer = setTimeout(() => handleGetKlines(), 1000)
    return () => clearTimeout(timer)
  }, [symbols])

  return {
    loading,
    klines,
    error,
    shouldUpdate,
    getSymbolsKlines,
    setKlines
  }
}
