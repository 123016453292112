export class Timer {
  private time: number | null

  constructor() {
    this.time = null
  }

  resetTime() {
    this.time = Date.now()
  }

  hasPassed(seconds: number) {
    if (!this.time) {
      this.resetTime()
      return true
    }

    const now = Date.now()
    const passedTime = Math.abs(now - this.time) / 1000

    const hasPassed = passedTime > seconds

    if (hasPassed) {
      this.resetTime()
    }

    return hasPassed
  }
}
