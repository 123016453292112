import {
  ReactNode,
  useState,
  useCallback,
  useContext,
  createContext
} from 'react'

type KlinesQueue = Record<string, boolean>

export type KlinesQueueContextType = {
  candlesQueue: KlinesQueue
  updateKlinesQueue: (key: string) => void
  canLoadByLastQueueKey: (key?: string) => boolean
}

export const KlinesQueueContext = createContext<KlinesQueueContextType>(
  {} as KlinesQueueContextType
)

export const KlinesQueueProvider = ({ children }: { children: ReactNode }) => {
  const [candlesQueue, setKlinesQueue] = useState<KlinesQueue>({})

  const updateKlinesQueue = useCallback((key: string) => {
    setKlinesQueue(prev => {
      return {
        ...prev,
        [key]: true
      }
    })
  }, [])

  const canLoadByLastQueueKey = useCallback(
    (lastQueueKey?: string) => {
      return (
        lastQueueKey === undefined ||
        (!!lastQueueKey && !!candlesQueue?.[lastQueueKey])
      )
    },
    [candlesQueue]
  )

  return (
    <KlinesQueueContext.Provider
      value={{ candlesQueue, updateKlinesQueue, canLoadByLastQueueKey }}
    >
      {children}
    </KlinesQueueContext.Provider>
  )
}

export const useKlinesQueueContext = () => useContext(KlinesQueueContext)
