import { AxiosResponse } from 'axios'

import { BinanceExchangeInfo } from '@/domain/models'
import { RetrieveBinanceExchangeInfo } from '@/domain/usecases'
import { exchangeInfo } from '@/main/constants/endpoints'
import api from '@/services/api'

export const requestExchangeInfo = async (
  data?: RetrieveBinanceExchangeInfo.Params
): Promise<AxiosResponse<BinanceExchangeInfo>> =>
  api({ method: `GET`, url: exchangeInfo, data })
