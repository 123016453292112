import { useMemo } from 'react'

import { useOrdersContext, useWebsocketContext } from '../contexts'
import { OrdersAnalyzer } from '../helpers/exchange/orders-analyzer'

export const useSymbolsOrders = (symbols: string | string[] = 'USDTBRL') => {
  const { filledOrders } = useOrdersContext()
  const { USDTBRLPrice } = useWebsocketContext()

  const symbolOrders = useMemo(() => {
    return filledOrders
      .filter(order => order.status === 'FILLED')
      .filter(order => [symbols].flat().includes(order.symbol))
  }, [filledOrders])

  const symbolCapital = useMemo(() => {
    return OrdersAnalyzer.calculateCapital(symbolOrders, USDTBRLPrice)
  }, [symbolOrders])

  const symbolMidPrice = useMemo(() => {
    return OrdersAnalyzer.calculateMidPrice(symbolOrders)
  }, [symbolOrders])

  return {
    symbolOrders,
    symbolCapital,
    symbolMidPrice
  }
}
